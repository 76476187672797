import React, {Component} from 'react';
import UserPaginationTable from './UserPaginationTable';
import SearchForm from './SearchForm';
import API from './ApiInterface';
import {_checkStrings} from "./common/sanitizeInputs";
import CircularDeterminate from "./LoadingCircle";

class AllUsers extends Component {
    constructor(props) {
        super(props);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);

        this.state = {
            users: null,
            error: null,
            count: null,
            loading: true,
            searchParams: this.props.location.state.searchParams,
            userInfo: this.props.location.state.userInfo,
            paginationDetails: {
                page: 0,
                rowsPerPage: 10,
                count: 0,
            }
        };
    }

    componentDidMount() {
        this.handleSearchClick(this.state.searchParams.firstName, this.state.searchParams.lastName, this.state.searchParams.email);
    }

    handleChangePage = (event, page) => {
        this.setState({
            paginationDetails: {
                ...this.state.paginationDetails,
                page: page,
                skip: page * this.state.paginationDetails.rowsPerPage,
            }
        }, () => {
            this.handleSearchClick(
                this.state.searchParams.firstName,
                this.state.searchParams.lastName,
                this.state.searchParams.email,
            );
        });
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            paginationDetails: {
                skip: 0,
                rowsPerPage: Number(event.target.value),
                page: 0
            }
        }, () => {
            this.handleSearchClick(
                this.state.searchParams.firstName,
                this.state.searchParams.lastName,
                this.state.searchParams.email
            );
        });
    };

    handleSearchClick (firstName, lastName, email) {
        this.setState({
            loading: true,
        });
        firstName = _checkStrings(firstName);
        lastName = _checkStrings(lastName);
        email = _checkStrings(email);

        API.getSearchUsers(this.state.userInfo.token, firstName, lastName, email, this.state.paginationDetails.rowsPerPage, (this.state.paginationDetails.page * this.state.paginationDetails.rowsPerPage)).then(users => {
            this.setState({
                users: users["data"],
                count: users["count"],
                searchParams: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                },
                loading: false,
                paginationDetails: {
                    ...this.state.paginationDetails,
                    count: users.count,
                }
            });
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    };

    render() {
        return (
            <div>

                <SearchForm
                    handleSearchClick={this.handleSearchClick}
                    firstName={this.state.searchParams.firstName}
                    lastName={this.state.searchParams.lastName}
                    email={this.state.searchParams.email}
                />

                {
                    (this.state.loading === false) && <div>
                        <UserPaginationTable
                            headerNames={["First Name", "Last Name", "Email", "Confirmed"]}
                            rowIndexes={["firstName", "lastName", "email", "isConfirmed"]}
                            rows={this.state.users}
                            searchParams={this.state.searchParams}
                            userInfo={this.state.userInfo}
                            paginationDetails={this.state.paginationDetails}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </div>
                }

                {
                    (this.state.error === true) && (this.state.loading === false) && <div>
                        There was an error.
                        {this.state.error}
                        Please try reloading...
                    </div>
                }

                {
                    (this.state.loading === true) && <div
                        style={{
                            "height": "440px", display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <CircularDeterminate/>
                    </div>
                }
            </div>
        );
    }
}

export default AllUsers;
