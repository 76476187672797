import React, {Component} from 'react';
import API from './ApiInterface';
import {withStyles,} from '@material-ui/core';
import {Bar, Brush, CartesianGrid, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import CircularDeterminate from "./LoadingCircle";
import {_fixDates, _removeTimeFromDate, today,} from "./common/DateResources";
import ComposedChart from "recharts/lib/chart/ComposedChart";
import Area from "recharts/lib/cartesian/Area";
import {BootstrapButton} from "./SubmitButton";
import VelocityPeriodSearch from "./SearchBars/VelocityPeriodSearch";
import MyRadialBarChart from "./RadialBarChart";

const useStyles = theme => ({
    root: {
        height: 180,
    },
    row: {
        maxWidth: 'auto',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',

    },
    grid: {
        gridColumn: 'auto',
        gridRow: 'auto',
    },
    radialLoading: {
        width: 500,
        height: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50%',
    },
    barLoading: {
        width: 150,
        height: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '25%',
    },
    barContainer: {
        width: '100%',
    },
    barClosedContainer: {
        flexShrink: 1,
        width: '100%',
        display: 'inline-block',
        paddingLeft: "15px",
    },
});

class DailyMetrics extends Component {
    constructor(props) {
        super(props);

        this.handleActiveUsersSubmit = this.handleActiveUsersSubmit.bind(this);

        this.state = {
            token: this.props.location.state.token,
            defaultStartDate: '2018-06-20',
            defaultEndDate: today,
            activeUsersPeriod: "month",
            activeUsersVelocityCount: 10,
            velocities: null,
            videos: null,
            accounts: null,
            activeUsers: this._getDefaultData(),
            newAccountsPerPeriod: this._getDefaultData(),
            data: this._getDefaultData(),
            activeUsersLoading: true,
        }
    }

    componentDidMount() {
        this.handleSubmit();
        this.handleActiveUsersSubmit(this.state.activeUsersPeriod, this.state.activeUsersVelocityCount);
    }

    _getDefaultData() {
        return {
            error: false,
            count: 0,
            result: [],
        }
    }

    handleSubmit() {
        this.setState({
            velocities: null,
            videos: null,
            accounts: null,
        });

        API.newHistoriesLastWeek(this.state.token).then(result => {
            if (result["error"] === false) {

                result["result"][0].fill = '#0B6E4F';
                result["result"][1].fill = '#194CB3';
                this.setState({
                    velocities: result,
                });
            } else {
                this.setState({
                    velocities: {
                        error: true,
                    },
                    errorVelocities: {
                        message: "Error: " + result["result"].parent.message,
                        sql: "Query: " + result["result"].parent.sql,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorVelocitiesAPI: error,
            });
        });

        API.newVideosLastWeek(this.state.token).then(result => {
            if (result["error"] === false) {
                result["result"][0].fill = '#0B6E4F';
                result["result"][1].fill = '#194CB3';
                this.setState({
                    videos: result,
                });
            } else {
                this.setState({
                    videos: {
                        error: true,
                    },
                    errorVideos: {
                        message: "Error: " + result["result"].parent.message,
                        sql: "Query: " + result["result"].parent.sql,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorVideosAPI: error,
            });
        });

        API.newAccountsLastWeek(this.state.token).then(result => {
            if (result["error"] === false) {
                result["result"][0].fill = '#0B6E4F';
                result["result"][1].fill = '#194CB3';
                this.setState({
                    accounts: result,
                });
            } else {
                this.setState({
                    accounts: {
                        error: true,
                    },
                    errorAccounts: {
                        message: "Error: " + result["result"].parent.message,
                        sql: "Query: " + result["result"].parent.sql,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorAccountsAPI: error,
            });
        });
    }

    getActiveUsersPerPeriod(period, accountDateStart, accountDateEnd, velocityCount) {
        API.activeUsersPerPeriod(this.state.token, period, accountDateStart, accountDateEnd, velocityCount).then(result => {
            if (result["error"] === false) {

                this.setState({
                    activeUsers: _fixDates(result),
                });
            } else {
                this.setState({
                    activeUsersError: {
                        error: true,
                        count: null,
                    },
                    activeUsersErrorSubmit: {
                        message: "Error: " + result["result"].parent.message,
                        sql: "Query: " + result["result"].parent.sql,
                    },
                });
            }
        }).then(result => {
            this.finalizeData();
        }).catch((error) => {
            this.setState({
                activeUsersErrorAPI: error,
            });
        });
    }

    getNewAccountsPerPeriod(period, accountDateStart, accountDateEnd) {
        API.getNewAccountsPerPeriod(this.state.token, period, accountDateStart, accountDateEnd, "true").then(result => {
            if (result["error"] === false) {
                result = _fixDates(result);

                this.setState({
                    newAccountsPerPeriod: result,
                });
            } else {
                this.setState({
                    newAccountsPerPeriod: {
                        error: true,
                        count: null,
                    },
                    errorNewAccountsPerPeriod: {
                        message: "Error: " + result["result"].parent.message,
                        sql: "Query: " + result["result"].parent.sql,
                    },
                });
            }
        }).then(result => {
            this.finalizeData();
        }).catch((error) => {
            this.setState({
                errorNewAccountsPerPeriodSubmit: error,
            });
        });
    }

    handleActiveUsersSubmit(period, velocityCount) {
        this.setState({
            activeUsers: this._getDefaultData(),
            newAccountsPerPeriod: this._getDefaultData(),
            activeUsersPeriod: period,
            activeUsersVelocityCount: velocityCount,
            activeUsersLoading: true,
        });

        this.getActiveUsersPerPeriod(period, this.state.defaultStartDate, this.state.defaultEndDate, velocityCount);
        this.getNewAccountsPerPeriod(period, this.state.defaultStartDate, this.state.defaultEndDate);
    }

    finalizeData() {
        if (this.state.activeUsers.count > 0 &&
            this.state.newAccountsPerPeriod.count > 0) {
            let activeAndNewUsers = JSON.parse(JSON.stringify(this.state.activeUsers));

            for (let i = 0; i < activeAndNewUsers["result"].length; i++) {
                // TODO: investigate if it is possible to get dates out of sync.
                activeAndNewUsers.result[i].newUsersDate = this.state.newAccountsPerPeriod.result[i].date;
                activeAndNewUsers.result[i].newUsers = this.state.newAccountsPerPeriod.result[i].newUsers;
            }

            activeAndNewUsers= _removeTimeFromDate(activeAndNewUsers);

            this.setState({
                data: activeAndNewUsers,
                activeUsersLoading: false,
            })
        }
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                <div style={{ width:'auto'}}>

                    <BootstrapButton
                        onClick={() => {
                            this.handleSubmit();
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Refresh
                    </BootstrapButton>

                    <div className={classes.row}>
                        {(this.state.velocities) &&
                        <div className={classes.grid}>
                            <h3 align={"center"}>Velocities</h3>
                            <MyRadialBarChart
                                data={this.state.velocities.result}
                            />
                        </div>
                        }

                        {(!this.state.velocities) &&
                        <div className={classes.grid}>
                            <CircularDeterminate />
                        </div>
                        }
                    </div>

                    <div className={classes.row}>
                        {(this.state.accounts) &&
                        <div className={classes.grid}>
                            <h3 align={"center"}>New Accounts</h3>
                            <MyRadialBarChart
                                data={this.state.accounts.result}
                            />
                        </div>
                        }

                        {(!this.state.accounts) &&
                        <div className={classes.grid}>
                            <CircularDeterminate />
                        </div>
                        }

                        {(this.state.videos) &&
                        <div className={classes.grid}>
                            <h3 align={"center"}>Videos</h3>
                            <MyRadialBarChart
                                data={this.state.videos.result}
                            />
                        </div>
                        }

                        {(!this.state.videos) &&
                        <div className={classes.grid}>
                            <CircularDeterminate />
                        </div>
                        }
                    </div>
                </div>

                <br /><br />

                <div className={classes.container}>
                    <h3>The number of users who have at least one velocity in the given period</h3>
                    { (this.state.activeUsersError) &&
                    <div>
                        <p>There was an error with the query. Please email Marguerite the two message below</p>
                        <p>Message: {this.state.activeUsersError.message}</p>
                        <p>SQL: {this.state.activeUsersError.sql}</p>
                    </div>
                    }

                    { (this.state.activeUsersErrorSubmit) &&
                    <div>
                        <p>There was an error with the query. Please email Marguerite the message below along with the paramaters for confirmed, start date and end date</p>
                        <p>Error: {this.state.activeUsersErrorSubmit.message}</p>
                    </div>
                    }

                    { (this.state.activeUsersLoading === false) &&
                    <div>
                        <div>
                            <VelocityPeriodSearch
                                handleSubmit={this.handleActiveUsersSubmit}
                                period={this.state.activeUsersPeriod}
                                velocityCount={this.state.activeUsersVelocityCount}
                            />
                        </div>

                        <ResponsiveContainer
                            width="100%" height={400}>
                            <ComposedChart
                                data={this.state.data.result}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                <ReferenceLine y={0} stroke="#000" />
                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                <Bar dataKey="activeUsers" barSize={20} fill="#413ea0" />
                                <Area type="monotone" dataKey="newUsers" fill="#8884d8" stroke="#8884d8" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                    }
                    { (this.state.activeUsersLoading === true) &&
                    <div className={classes.barLoading}>
                        <CircularDeterminate />
                    </div>
                    }
                </div>

                <br /><br /><br />
            </div>
        )
    }
}

export default withStyles(useStyles)(DailyMetrics);
