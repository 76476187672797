import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {withRouter} from "react-router-dom";
import {styles} from "./styles/TableStyles";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActionsWrapped} from "./TablePaginationActions";

function Row(props) {
    const { row, classes } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow className={classes.tabbedContent}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.sport}</TableCell>
                <TableCell align="right">{row.level}</TableCell>
                <TableCell align="right">{row.hsGradYear}</TableCell>
                <TableCell align="right">{row.orgName}</TableCell>
                <TableCell align="right">{row.teamName}</TableCell>
                <TableCell align="right">{row.createdAt}</TableCell>
            </TableRow>
            <TableRow key='additional-info'>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                component="h6"
                                className={classes.rootHeader}>
                                Additional Details
                            </Typography>
                            <Table size="small" aria-label="additionalDetails">
                                <TableHead>
                                    <TableRow key='additional-info-header'>
                                        <TableCell align="right">Positions</TableCell>
                                        <TableCell align="right">Seasons</TableCell>
                                        <TableCell align="right">Sport Metadata</TableCell>
                                        <TableCell align="right">Biometrics</TableCell>
                                        <TableCell align="right">Handed</TableCell>
                                        <TableCell align="right">Gender</TableCell>
                                        <TableCell align="right">Birth Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.additional.map((additionalDetails) => (
                                        <TableRow key={additionalDetails.positions}>
                                            <TableCell align="right" component="th" scope="row">
                                                {additionalDetails.positions.map((position) => (
                                                        <li key={position}>{position}</li>
                                                ))}
                                            </TableCell>
                                            <TableCell align="right" component="th" scope="row">
                                                {additionalDetails.seasons.map((season) => (
                                                    <li key={season}>{season}</li>
                                                ))}
                                            </TableCell>
                                            <TableCell align="right" component="th" scope="row">
                                                {
                                                    Object.entries(additionalDetails.sportMetaData).map( ([key, value]) => (
                                                        <li key={key}>{key}: {value}</li>
                                                    ))}
                                            </TableCell>
                                            <TableCell align="right">{additionalDetails.biometrics}</TableCell>
                                            <TableCell align="right">{additionalDetails.handed}</TableCell>
                                            <TableCell align="right">{additionalDetails.gender}</TableCell>
                                            <TableCell align="right">{additionalDetails.birthDate}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <Typography
                                variant="h6"
                                component="h6"
                                className={classes.rootHeader}>
                                Contact Information
                            </Typography>
                            <Table size="small" aria-label="contact">
                                <TableHead>
                                    <TableRow key='player-contact-header'>
                                        <TableCell align="right">Phone Number</TableCell>
                                        <TableCell align="right">State</TableCell>
                                        <TableCell align="right">Country</TableCell>
                                        <TableCell align="right">Zip Code</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={row.phoneNumber}>
                                        <TableCell align="right">{row.phoneNumber}</TableCell>
                                        <TableCell align="right">{row.state}</TableCell>
                                        <TableCell align="right">{row.country}</TableCell>
                                        <TableCell align="right">{row.zipCode}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        playerId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        sport: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
        hsGradYear: PropTypes.number.isRequired,
        orgName: PropTypes.string.isRequired,
        teamName: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        zipCode: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        additional: PropTypes.arrayOf(
            PropTypes.shape({
                positions: PropTypes.array.isRequired,
                biometrics: PropTypes.string.isRequired,
                sportMetaData: PropTypes.object,
                seasons: PropTypes.array.isRequired,
                handed: PropTypes.string.isRequired,
                gender: PropTypes.string.isRequired,
                birthDate: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired
}

class PlayerTable extends React.Component {
    static propTypes = {
        paginationDetails: PropTypes.object.isRequired,
        handleChangePage: PropTypes.func.isRequired,
        handleChangeRowsPerPage: PropTypes.func.isRequired,
    };

    render() {
        const { rows, classes, handleChangePage, handleChangeRowsPerPage, paginationDetails } = this.props;

        return (
            <div>
            <Typography
                variant="h6"
                component="h6"
                className={classes.rootHeader}>
                Players
            </Typography>
                <Paper className={classes.tabbedContent}>
                    <div>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow key='player-header'>
                                    <TableCell/>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">Sport</TableCell>
                                    <TableCell align="right">Level</TableCell>
                                    <TableCell align="right">HS Grad Year</TableCell>
                                    <TableCell align="right">Organization</TableCell>
                                    <TableCell align="right">Team</TableCell>
                                    <TableCell align="right">Created</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? rows.map((row) => (
                                    <Row
                                        row={row}
                                        key={row.playerId}
                                        classes={classes}
                                    />
                                )) :
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell component="th" scope="row">
                                            No players
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow className={classes.row} key='player-footer'>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={8}
                                        count={paginationDetails.count}
                                        rowsPerPage={paginationDetails.rowsPerPage}
                                        page={paginationDetails.page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(PlayerTable));
