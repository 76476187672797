import React from 'react';
import { withRouter } from 'react-router-dom';
import API from './ApiInterface';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { ThemeProvider } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import CheckIcon from '@material-ui/icons/Check';
import Theme from "./styles/UpdateEmailTheme";
import ClearIcon from '@material-ui/icons/Clear';

class UpdateEmailButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userInfo: this.props.location.state.userInfo,
            newEmail: '',
            verificationCode: '',
            stage: 1,
            showAlert: false,
            alertMessage: '',
            alertSeverity: 'success',
            isVerificationSuccessful: false,
            isEmailValid: true,
            disableCodeSend: true,
        };
    }

    handleCloseAlert = () => {
        this.setState({ showAlert: false });
    };

    showAlert = (message, severity) => {
        this.setState({
            showAlert: true,
            alertMessage: message,
            alertSeverity: severity
        });
    };

    updateEmail = async () => {
        const { user } = this.props.location.state;
        const { newEmail } = this.state;

        try {
            const response = await API.updateEmail(this.state.userInfo.token, user.email, newEmail);
            if (response.status === 200 || response.status === 201) {
                this.showAlert('Verification code successfully sent to new email.', 'success');
                this.setState({ stage: 2 });
            } else {
                const data = await response.json();
                this.showAlert(data.errorDescription || 'An error occurred.', 'error');
            }
        } catch (error) {
            this.showAlert(error.message || 'An unexpected error occurred.', 'error');
        }
    };

    verifyEmail = async () => {
        const { user } = this.props.location.state;
        const { newEmail, verificationCode } = this.state;

        try {
            const response = await API.updateEmailVerify(this.state.userInfo.token, user.email, newEmail, verificationCode);
            if (response.status === 200 || response.status === 201) {
                this.showAlert('Email changed successfully!', 'success');
                this.setState({ isVerificationSuccessful: true });
                setTimeout(() => {
                    this.props.history.goBack();
                }, 6000);
            } else {
                const data = await response.json();
                this.showAlert(data.errorDescription || 'Verification failed.', 'error');
            }
        } catch (error) {
            this.showAlert(error.message || 'Verification failed.', 'error');
        }
    };

    handleEmailChange = (event) => {
        const newEmail = event.target.value;
        this.setState({
            newEmail: newEmail,
            isEmailValid: this.validateEmail(newEmail),
            disableCodeSend: false,
        });
    };

    validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    clearEmail = () => {
        this.setState({
            newEmail: "",
            isEmailValid: true,
            disableCodeSend: true,
        });
    };

    handleCodeChange = (event) => {
        this.setState({ verificationCode: event.target.value });
    };


    clearCode = () => {
        this.setState({
            verificationCode: "",
        });
    };

    render() {
        const { user } = this.props.location.state;
        const { newEmail, verificationCode, stage, isVerificationSuccessful, isEmailValid, disableCodeSend } = this.state;

        return (
            <div style={{ padding: 20 }}>
                <ThemeProvider theme={Theme}>
                    <Typography variant="h3" style={{ marginBottom: 15 }}>{user.firstName} {user.lastName}</Typography>
                    <Typography color="secondary" variant="h5" style={{ marginBottom: 20 }}>{isVerificationSuccessful ? newEmail : user.email}</Typography>

                    {stage === 1 && (
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                label="New Email"
                                type="email"
                                value={newEmail}
                                onChange={this.handleEmailChange}
                                variant="outlined"
                                size="small"
                                error={!isEmailValid}
                                helperText={!isEmailValid ? "Invalid Email." : ""}
                                InputProps={{endAdornment: <ClearIcon onClick={this.clearEmail}/>}}
                                style={{ width: '100%', maxWidth: '500px', marginRight: 10, marginBottom: 10 }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.updateEmail}
                                disabled={!isEmailValid || disableCodeSend}
                                size="large"
                                startIcon={<SendIcon />}
                            >
                                Send Verification Code
                            </Button>
                        </div>
                    )}

                    {stage === 2 && (
                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                label="Verification Code"
                                type="text"
                                value={verificationCode}
                                onChange={this.handleCodeChange}
                                inputProps={{ maxLength: 4 }}
                                variant="outlined"
                                size="small"
                                InputProps={{endAdornment: <ClearIcon onClick={this.clearCode}/>}}
                                style={{ width: '100%', maxWidth: '500px', marginRight: 10, marginBottom: 10 }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={this.verifyEmail}
                                size="large"
                                disabled={isVerificationSuccessful}
                                startIcon={<CheckIcon />}
                            >
                                Verify Email
                            </Button>
                        </div>
                    )}
                </ThemeProvider>

                <Snackbar open={this.state.showAlert} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert variant="filled" onClose={this.handleCloseAlert} severity={this.state.alertSeverity}>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}

export default withRouter(UpdateEmailButton);
