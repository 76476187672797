import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import {signInstyles} from "./SignIn-SignUp-Code";

SignIn.propTypes = {
    handleEmailChange: PropTypes.func.isRequired,
};

function SignIn(props) {

    const {
        classes,
        handleEmailChange,
        handlePasswordChange,
        handleSubmit
    } = props;

    return (
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }} className={classes.main}>
            <CssBaseline />
            <Paper className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={handleEmailChange}
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onChange={handlePasswordChange}
                    autoComplete="current-password"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign in
                </Button>

                <br/>
                <Grid container>
                    <Grid item xs>
                        <Link href="/create-account" variant="body2">{"Create Account"}</Link>
                    </Grid>
                    <Grid item>
                        <Link href="/reset-password" variant="body2">{"Forgot Password?"}</Link>
                    </Grid>
                </Grid>
            </Paper>
        </form>

    );
}

export default withStyles(signInstyles)(SignIn);
