import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import PlayForWork from '@material-ui/icons/NoteAdd';
import HomeIcon from '@material-ui/icons/Home'
import BarChartIcon from '@material-ui/icons/BarChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EmailIcon from '@material-ui/icons/Email';

export const SideBarMenu = (props) => {
    const {handleHomeClick, handlePeopleClick, handleNewUsersClick,
        handleDailyMetricsClick, handleMiscMetricsClick, handleAdminRolesClick, handleUpdateEmailClick, permissions} = props;

    return <div>
        <ListItem button onClick={handleHomeClick} component={"ul"}>
            <ListItemIcon>
                <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItem>

        <ListItem button onClick={handlePeopleClick} component={"ul"}>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Users"/>
        </ListItem>

        <ListItem button onClick={handleNewUsersClick} component={"ul"}>
            <ListItemIcon>
                <PlayForWork/>
            </ListItemIcon>
            <ListItemText primary="New Users"/>
        </ListItem>

        <ListItem button onClick={handleDailyMetricsClick} component={"ul"}>
            <ListItemIcon>
                <PieChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Daily Metrics"/>
        </ListItem>

        <ListItem button onClick={handleMiscMetricsClick} component={"ul"}>
            <ListItemIcon>
                <BarChartIcon/>
            </ListItemIcon>
            <ListItemText primary="Misc. Metrics"/>
        </ListItem>

        {permissions.includes("MR") &&
            <ListItem button onClick={handleAdminRolesClick} component={"ul"}>
                <ListItemIcon>
                    <AccountTreeIcon/>
                </ListItemIcon>
                <ListItemText primary="Admin Roles"/>
            </ListItem>
        }

        {permissions.includes("MR") &&
            <ListItem button onClick={handleUpdateEmailClick} component={"ul"}>
                <ListItemIcon>
                    <EmailIcon/>
                </ListItemIcon>
                <ListItemText primary="Change Email"/>
            </ListItem>
        }

        <ListItem button onClick={() => { window.open('https://pocketradar.atlassian.net/servicedesk/customer/portals')}} component={"ul"}>
            <ListItemIcon>
                <RoomServiceIcon/>
            </ListItemIcon>
            <ListItemText primary="Service Desk"/>
        </ListItem>
    </div>
};
