import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import Code from "./Code";
import {signInstyles} from "./SignIn-SignUp-Code";
import * as PropTypes from "prop-types";
import Password from "./Password";

class ResetPassword extends Component {
    static propTypes = {
        handleEmailSubmit: PropTypes.func.isRequired,
        handleConfirmCodeSubmit: PropTypes.func.isRequired,
        handleConfirmAccountCodeSubmit: PropTypes.func.isRequired,
        changeEmail: PropTypes.func.isRequired,
        emailError: PropTypes.bool.isRequired,
        changePassword: PropTypes.func.isRequired,
        changeCode: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired,
        codeError: PropTypes.bool.isRequired,
        helperCodeText: PropTypes.string.isRequired,
        readyForCode: PropTypes.bool.isRequired,
        readyForAccountCode: PropTypes.bool.isRequired,
        readyForPassword: PropTypes.bool.isRequired,
        handleNewPasswordSubmit: PropTypes.func.isRequired,
        helperPasswordText: PropTypes.string.isRequired,
        passwordError: PropTypes.bool.isRequired,
    };

    render() {
        const {
            classes,
            handleEmailSubmit,
            changeEmail,
            emailError,
            readyForCode,
            readyForAccountCode,
            readyForPassword,
        } = this.props;

        return (
            <div>
                {(!readyForCode) && (!readyForAccountCode) && (!readyForPassword) &&
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>

                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    onChange={changeEmail}
                                    error={emailError}
                                    autoFocus
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                handleEmailSubmit();
                            }}
                        >
                            Reset Password
                        </Button>

                        <br/>
                        <Grid container justify="flex-start">
                            <Grid item>
                                <Link href="/signin" variant="body2">{"Sign In"}</Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </main>
                }

                {(readyForCode) && <div>
                    <Code
                        submit={this.props.handleConfirmCodeSubmit}
                        codeError={this.props.codeError}
                        changeCode={this.props.changeCode}
                        helperCodeText={this.props.helperCodeText}
                        classes={classes}
                    />
                </div>
                }

                {(readyForAccountCode) && <div>
                    <Code
                        submit={this.props.handleConfirmAccountCodeSubmit}
                        codeError={this.props.codeError}
                        changeCode={this.props.changeCode}
                        helperCodeText={this.props.helperCodeText}
                        classes={classes}
                    />
                </div>
                }

                {(readyForPassword) && <div>
                    <Password
                        submit={this.props.handleNewPasswordSubmit}
                        passwordError={this.props.passwordError}
                        changePassword={this.props.changePassword}
                        helperPasswordText={this.props.helperPasswordText}
                        classes={classes}
                    />
                </div>
                }
            </div>

        );
    }
}

export default withStyles(signInstyles)(ResetPassword);
