
// Gently corrects our data since the DB allows empty strings and null
export function _softFixBadDB (result) {
    for (let i = 0; i < result.result.length; i++) {
        if (result.result[i].name === "") {
            if (result.result[i].value === 0) {
                // If the value is 0, just remove it
                result.result.splice(i, 1);
            } else {
                result.result[i].name = "No Data";
            }
        }
        if (result.result[i].name === null) {
            if (result.result[i].value === 0) {
                // If the value is 0, just remove it
                result.result.splice(i, 1);
            } else {
                result.result[i].name = "Null";
            }
        }
    }

    return result;
}

// Removes data that has an empty or null tag
export function _hardFixBadDB(result) {
    for (let i = 0; i < result.length; i++) {
        if (result[i].name === null || result[i].name === "") {
            result.splice(i, 1);
            i--;
        }
    }

    return result;
}

// Combines small data points into one category - 'Other' so they can actually be seen and represented
export function _simplifyDataForCharting(result) {
    let total = 0;
    for (let i = 0; i < result.result.length; i++) {
        total += result.result[i].value
    }

    let cutoff = total * .007;
    let other = {name: 'Other', value: 0};

    // combine the value of any category that had an original value less than .7% of the total
    for (let i = 0; i < result.result.length; i++) {
        if (result.result[i].value <= cutoff) {
            other.value += result.result[i].value;
            result.result.splice(i, 1);
            i--;
        }
    }
    result.result.push(other);

    return result;
}

export function _numberWithCommas(x) {
    const parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
}
