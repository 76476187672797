
export const useStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    menu: {
        width: 200,
    },
    submit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    form: {
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
    }
});
