let moment = require('moment-timezone');

export function formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

const date = new Date();
date.setHours(0,0,0,0);
export const today = date;

// Take a dateTime that looks like: 2021-12-22T17:39:08.174Z
// Return a dateTime that looks like: "12/22/21 9:39:08 PST"
// Removes confusion of mm/dd/yyyy or dd/mm/yyyy and shows the date in PST/PDT which is where most office employees are
export function _formatDate(date) {
    let fixDate = "No data";
    if (date !== undefined) {
        fixDate = moment(date).tz("America/Los_Angeles").format('M/DD/YY h:mm A z')
    }
    return fixDate;
}

export function _formatDateToSecond(date) {
    let fixDate = "No data";
    if (date !== undefined) {
        fixDate = moment(date).tz("America/Los_Angeles").format('M/DD/YY h:mm:ss A z')
    }
    return fixDate;
}

// Checks to see if the date provided is a date object or string. If it is a date object this function
// will change the date to a string in the format of yyyy-mm-dd
export function _checkDates(date) {
    if (typeof date !== "string") {
        return formatDate(date);
    }
    return date;
}

// Change the date to a string in the format of yyyy-mm-dd
export function _fixDates(result) {
    for (let i = 0; i < result["result"].length; i++) {
        // Shorten the date to just yyyy-mm-dd hh-mm
        result["result"][i].date = _formatDate(result["result"][i].date);

        // If the date is in the future, remove it from the list
        // This accounts for people setting their date to the future, most likely for pokemonGo reasons
        if (result["result"][i].date > today) {
            result["result"].splice(i, 1);
            i--;
        }
    }
    return result;
}

export function _addWeekNameAndIndex(result) {
    for (let i = 0; i < result["result"].length; i++) {
        result["result"][i].index = 1;
        result["result"][i].day = _getWeekName(result["result"][i]["name"]);
    }
    return result;
}

export function _getWeekName(dayNumber) {
    let day;
    if(dayNumber === 1)
        day = 'Sunday';
    else if(dayNumber === 2)
        day = 'Monday';
    else if(dayNumber === 3)
        day = 'Tuesday';
    else if(dayNumber === 4)
        day = 'Wednesday';
    else if(dayNumber === 5)
        day ='Thursday';
    else if(dayNumber === 6)
        day = 'Friday';
    else if(dayNumber === 7)
        day ='Saturday';

    return day;
}

// Remove time from date so it's just yyy-mm-dd
export function _removeTimeFromDate(result) {
    for (let i = 0; i < result["result"].length; i++) {
        result["result"][i].date = result["result"][i].date.slice(0, 11);
    }
    return result;
}

export function _get30DaysAgo(fromDate) {
    if (!(fromDate instanceof Date))
        throw new TypeError('fromDate must be a Date');

    var offset = (24*60*60*1000) * 30;
    var newDate = new Date();
    newDate.setTime(fromDate.getTime() - offset);

    return newDate;
}