import 'date-fns';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import {BootstrapButton} from "../SubmitButton";
import {
    changeNumberVelocities,
    handleEndDateChange,
    handleLevelChange,
    handleRoleChange,
    handleSportChange,
    handleStartDateChange,
} from "../SearchResources";
import {useStyles} from "../SearchStyles";

class DateAndVelocitySearch extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        selectedStartDate: PropTypes.string.isRequired,
        selectedEndDate: PropTypes.string.isRequired,
        velocityCount: PropTypes.number.isRequired,
        sport: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        level: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleStartDateChange = handleStartDateChange.bind(this);
        this.handleEndDateChange = handleEndDateChange.bind(this);
        this.changeNumberVelocities = changeNumberVelocities.bind(this);
        this.handleSportChange = handleSportChange.bind(this);
        this.handleRoleChange = handleRoleChange.bind(this);
        this.handleLevelChange = handleLevelChange.bind(this);

        this.state = {
            selectedStartDate: props.selectedStartDate,
            selectedEndDate: props.selectedEndDate,
            velocityCount: props.velocityCount,
            sport: props.sport,
            role: props.role,
            level: props.level,
        };
    }

    render() {
        let { classes } = this.props;
        return (
            <main className={classes.main}>
                <form className={classes.container} noValidate autoComplete="off">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="start-date-picker-dialog"
                            label="Select A Start Date"
                            helperText="Account Creation"
                            format="MM/dd/yyyy"
                            value={this.state.selectedStartDate}
                            onChange={this.handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change start date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="end-date-picker-dialog"
                            label="Select An End Date"
                            helperText="Account Creation"
                            format="MM/dd/yyyy"
                            value={this.state.selectedEndDate}
                            onChange={this.handleEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change end date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <TextField
                        id="velocities"
                        className={classes.textField}
                        label="Select the # Velocities"
                        margin="normal"
                        type="number"
                        value={this.state.velocityCount}
                        onChange={this.changeNumberVelocities}
                    />
                    <br />

                    <TextField
                        id="sport"
                        className={classes.textField}
                        label="Sport"
                        helperText="Leave empty for any"
                        margin="normal"
                        type="text"
                        value={this.state.sport}
                        onChange={this.handleSportChange}
                    />

                    <TextField
                        id="role"
                        className={classes.textField}
                        label="Role"
                        helperText="Leave empty for any"
                        margin="normal"
                        type="text"
                        value={this.state.role}
                        onChange={this.handleRoleChange}
                    />

                    <TextField
                        id="level"
                        className={classes.textField}
                        label="Level"
                        helperText="Leave empty for any"
                        margin="normal"
                        type="text"
                        value={this.state.level}
                        onChange={this.handleLevelChange}
                    />

                    <BootstrapButton
                        onClick={() => {
                            this.props.handleSubmit(
                                this.state.velocityCount,
                                this.state.selectedStartDate,
                                this.state.selectedEndDate,
                                this.state.sport,
                                this.state.role,
                                this.state.level,
                            );
                        }}
                        variant="contained"
                        color="primary"
                        disableRipple
                        className={classes.submit}>
                        Submit
                    </BootstrapButton>
                </form>
            </main>
        );
    }
}

export default withStyles(useStyles)(DateAndVelocitySearch);
