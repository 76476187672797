/*
* CSS for metrics
*/

export const MetricStyles = theme => ({
    root: {
        height: 'auto',
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        width: '98%',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 'auto',
    },
    barLoading: {
        width: 150,
        height: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '25%',
    },
    barContainer: {
        width: '100%',
    },
    barClosedContainer: {
        flexShrink: 1,
        width: '100%',
        display: 'inline-block',
        paddingLeft: "15px",
    },
    pieContainer: {
        display: 'flex',
    },
    pieClosedContainer:{
        display: 'inline-block',
        flexShrink: 1,
        paddingLeft: "15px",
    },
    paper: {
        margin: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(2),
    },
    fab: {
        margin: theme.spacing(1),
    },
    grid: {
        gridColumn: 850,
        gridRow: 'auto',
    },
    pieLoading: {
        width: 450,
        height: 450,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '50%',
    },
    errorOutline: {
        border: '2px solid red'
    }
});
