import React from "react";
import * as PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class Code extends React.Component {

    static propTypes = {
        submit: PropTypes.func.isRequired,
        helperCodeText: PropTypes.string.isRequired,
        changeCode: PropTypes.func.isRequired,
        codeError: PropTypes.bool.isRequired,
    };

    render() {

        const {
            classes,
            submit,
            helperCodeText,
            changeCode,
            codeError,
        } = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <AccountBoxIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Verify Your Email
                    </Typography>
                    <p>Enter the code from the email we just sent you</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="required"
                                fullWidth
                                required
                                label="Code"
                                helperText={helperCodeText}
                                type="number"
                                maxLength="4"
                                error={codeError}
                                onChange={changeCode}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            submit();
                        }}
                    >
                        Verify Code
                    </Button>
                </Paper>
            </main>
        );
    }
}

export default (Code);
