let appURL = "http://localhost:8008/api/";
let identityURL = "http://localhost:8010/identity/";
let oauthURL = "http://localhost:8010/oauth/";
let measurementURL = "http://localhost:8009/measurement/";
let sportsURL = "http://localhost:8011/sports/";

if (process.env.REACT_APP_ENV === "staging") {
    appURL = "https://app.api.staging.pocketradar-services.com/api/";
    identityURL = "https://app.api.staging.pocketradar-services.com/identity/";
    oauthURL = "https://app.api.staging.pocketradar-services.com/oauth/";
    measurementURL = "https://app.api.staging.pocketradar-services.com/measurement/";
    sportsURL = "https://app.api.staging.pocketradar-services.com/sports/";
} else if (process.env.REACT_APP_ENV === "production") {
    appURL = "https://api.pocketradar.com/api/";
    identityURL = "https://api.pocketradar.com/identity/";
    oauthURL = "https://api.pocketradar.com/oauth/";
    measurementURL = "https://api.pocketradar.com/measurement/";
    sportsURL = "https://api.pocketradar.com/sports/";
}

class ApiInterface {
    static async login(email, password) {
        return fetch(oauthURL + "token", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'username': email,
                'password': password,
                'grant_type': 'password'
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async refreshToken(refreshToken) {
        return fetch(oauthURL + "token", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                'refresh_token': refreshToken,
                'grant_type': 'refresh_token'
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async signUp(firstName, lastName, email, password) {
        return fetch(identityURL + "v2/signup", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'firstName': firstName,
                'lastName': lastName,
                'email': email,
                'password': password,
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async confirmAccount(email, code) {
        return fetch(identityURL + "v2/signup/confirm", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': email,
                'code': code,
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async forgotPassword(email) {
        return fetch(identityURL + "v2/password/forgot", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': email,
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async forgotPasswordVerify(email, code) {
        return fetch(identityURL + "v2/password/forgot/verify", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': email,
                'code': code,
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async resetPassword(email, code, password) {
        return fetch(identityURL + "v2/password/forgot/reset", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'email': email,
                'code': code,
                'password': password,
            })
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSearchUsers(token, firstName, lastName, email, limit, skip) {
        return fetch(
            identityURL + "v2/users/search?" +
            "email=" + email +
            "&firstName=" + firstName +
            "&lastName=" + lastName +
            "&limit=" + limit +
            "&skip=" + skip, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUserProfile(token, id) {
        return fetch(identityURL + "v2/users/" + id, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUserTotals(token, id) {
        return fetch(measurementURL + "v2/user/" + id + "/totals", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getHistoryForUser(token, id) {
        return fetch(measurementURL + "v2/user/" + id + "/measurements", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getLevelsWithVelCountByDate(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(appURL + "v1/metrics/getLevelsWithVelCountByDate?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error.toString();
        });
    }

    static async getRolesWithVelCountByDate(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getRolesWithVelCountByDate?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getGendersWithVelCountByDate(token, velocityCount, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getGendersWithVelCountByDate?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getHandedWithVelCountByDate(token, velocityCount, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getHandedWithVelCountByDate?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSportsWithVelCountByDate(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getSportsWithVelCountByDate?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSportTagMetrics(token, velocityCount, startDate, endDate,) {
        return fetch(
            appURL + "v1/metrics/getSportTagMetrics?" +
            "velocityDateStart=" + startDate +
            "&velocityDateEnd=" + endDate +
            "&velocityCount=" + velocityCount, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSportsTagUserCount(token, velocityCount, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getSportsTagUserCount?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&velocityCount=" + velocityCount, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfUsersByAgeGroups(token, velocityCount, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getNumberOfUsersByAgeGroups?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&velocityCount=" + velocityCount, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfUsersByHSGradYears(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getNumberOfUsersByHSGradYears?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&velocityCount=" + velocityCount +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUsersByDeviceOS(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getUsersByDeviceOS?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUsersByAppleDevice(token, velocityCount, startDate, endDate, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getUsersByAppleDevice?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getVelocitiesAndVideos(token, period) {
        return fetch(
            appURL + "v1/metrics/getVelocitiesAndVideosByPeriod?" +
            "period=" + period, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfAccounts(token, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getNumberOfAccounts?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfConfirmedEmails(token) {
        return fetch(appURL + "v1/metrics/getNumberOfConfirmedEmails", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfUnconfirmedEmails(token) {
        return fetch(appURL + "v1/metrics/getNumberOfUnconfirmedEmails", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUserCountWithNoCustomSports(token, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getUserCountWithNoCustomSports?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUserCountWithNoCustomActivities(token, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getUserCountWithNoCustomActivities?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNewAccountsPerPeriod(token, period, isConfirmed) {
        return fetch(
            appURL + "v1/metrics/getNewAccountsPerPeriod?" +
            "period=" + period +
            "&isConfirmed=" + isConfirmed, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUsersWithVelocitiesCount(token, startDate, endDate, velocityCount) {
        return fetch(
            appURL + "v1/metrics/getUsersWithVelocitiesCount?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getUsersWithVideosCount(token, startDate, endDate, velocityCount) {
        return fetch(
            appURL + "v1/metrics/getUsersWithVideosCount?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getAccountUpdatesPerPeriod(token, period) {
        return fetch(
            appURL + "v1/metrics/getAccountUpdatesPerPeriod?" +
            "period=" + period, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getAverageVelocityCount(token, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getAverageVelocityCount?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getAverageAccountAge(token, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getAverageAccountAge?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNumberOfPhoneNumbers(token, velocityCount, startDate, endDate) {
        return fetch(
            appURL + "v1/metrics/getNumberOfPhoneNumbers?" +
            "velocityCount=" + velocityCount +
            "&accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getTotalAccountsCreatedByDayOfTheWeek(token) {
        return fetch(appURL + "v1/metrics/getTotalAccountsCreatedByDayOfTheWeek", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getTotalVelocitiesCreatedByDayOfTheWeek(token) {
        return fetch(appURL + "v1/metrics/getTotalVelocitiesCreatedByDayOfTheWeek", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getTotalVideosCreatedByDayOfTheWeek(token) {
        return fetch(appURL + "v1/metrics/getTotalVideosCreatedByDayOfTheWeek", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getNewUsers(token, startDate, endDate, speeds, sport, role, level) {
        return fetch(
            appURL + "v1/metrics/newUsers/active?" +
            "accountDateStart=" + startDate +
            "&accountDateEnd=" + endDate +
            "&velocityCount=" + speeds +
            "&sport=" + sport +
            "&role=" + role +
            "&level=" + level, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async newHistoriesLastWeek(token) {
        return fetch(appURL + "v1/metrics/newHistoriesLastWeek?", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async newVideosLastWeek(token) {
        return fetch(appURL + "v1/metrics/newVideosLastWeek", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async newAccountsLastWeek(token) {
        return fetch(appURL + "v1/metrics/newAccountsLastWeek", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async activeUsersPerPeriod(token, period, dateStart, endDate, velocityCount) {
        return fetch(
            appURL + "v1/metrics/activeUsersPerPeriod?" +
            "period=" + period +
            "&velocityCount=" + velocityCount +
            "&dateStart=" + dateStart +
            "&endDate=" + endDate, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static getAllMessages() {
        return [
            {id: 0, title: "Maintenance", message: "app will be down for maintenance", active: 1, extraDetails: ""},
            {id: 1, title: "Updates", message: "New updates on the profile page", active: 1, extraDetails: "Check out the phone number field"},
            {id: 2, title: "Welcome", message: "Thanks for trying the app", active: 0, extraDetails: ""},
        ];
    }

    static getMessage(id) {
        const messages = ApiInterface.getAllMessages();
        return messages.find(m => m.id === id);
    }

    static async getAdminRoles(token) {
        return fetch(
        identityURL + "v2/roles", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getAdminRole(token, roleId) {
        return fetch(
        identityURL + "v2/roles/" + roleId, {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async createNewRole(token, roleName, roleDescription) {
        return fetch(
        identityURL + "v2/roles/" + roleName,{
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'description': roleDescription,
            }),
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async deleteAdminRole(token, roleId) {
        return fetch(
            identityURL + "v2/roles/" + roleId,{
                method: 'DELETE',
                headers: {
                    'Authorization': "Bearer " + token
                },
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getPermissions(token) {
        return fetch(
        identityURL + "v2/permissions", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async createPermission(token, permissionName, permissionDescription) {
        return fetch(
        identityURL + "v2/permissions",{
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'permission': permissionName,
                'permissionDesc': permissionDescription,
            }),
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async deletePermission(token, permissionId) {
        return fetch(
        identityURL + "v2/permissions/" + permissionId,{
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async addPermissionToRole(token, roleId, permissionId) {
        return fetch(
        identityURL + "v2/roles/" + roleId + "/addPermission/" + permissionId,{
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async removePermissionFromRole(token, roleId, permissionId) {
        return fetch(
        identityURL + "v2/roles/" + roleId + "/removePermission/" + permissionId,{
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async addUserToRole(token, roleId, userId) {
        return fetch(
        identityURL + "v2/roles/" + roleId + "/addUser/" + userId,{
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async removeUserFromRole(token, roleId, userId) {
        return fetch(
        identityURL + "v2/roles/" + roleId + "/removeUser/" + userId,{
            method: 'PUT',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getPocketRadarEmployees(token) {
        return fetch(
        identityURL + "v2/users/employees", {
            headers: {
                'Authorization': "Bearer " + token
            }
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async histories(token) {
        return fetch(appURL + "getHistory", {
            method: 'GET',
            headers: {
                'Authorization': "Bearer " + token,
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async softDelete(token, email, userID) {
        return fetch(identityURL + "v2/users/" + userID + "?email=" + email, {
            method: 'DELETE',
            headers: {
                'Authorization': "Bearer " + token
            },
        }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getPlayersForUser(token, userID, limit, skip) {
        return fetch(
            identityURL + "v2/players?" +
            "userId=" + userID +
            "&limit=" + limit +
            "&skip=" + skip, {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSessionsForUser(token, userID, limit, skip) {
        return fetch(
            measurementURL + "v2/sessions?" +
            "userId=" + userID +
            "&limit=" + limit +
            "&skip=" + skip, {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getMeasurementsForSession(token, sessionId) {
        return fetch(
            measurementURL + "v2/sessions/" + sessionId + "/measurements", {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getDevicesForUser(token, userID) {
        return fetch(
            identityURL + "v2/devices?userId=" + userID, {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getActivitiesForUser(token, userId) {
        return fetch(
            sportsURL + "v2/activities?userId=" + userId + "&deleted=true", {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async getSportsForUser(token, userId) {
        return fetch(
            sportsURL + "v2/sports?userId=" + userId + "&deleted=true", {
                headers: {
                    'Authorization': "Bearer " + token
                }
            }).then(
            response => response.json()
        ).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async updateEmail(token, oldEmail, newEmail) {
        const requestBody = JSON.stringify({
            'oldEmail': oldEmail,
            'newEmail': newEmail,
        });
        return fetch(identityURL + "v2/email/update", {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
            },
            body: requestBody
        }).catch((error) => {
            console.error(error);
            return error;
        });
    }

    static async updateEmailVerify(token, oldEmail, newEmail, code) {
        return fetch(identityURL + "v2/email/update/verify", {
            method: 'POST',
            headers: {
                'Authorization': "Bearer " + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'oldEmail': oldEmail,
                'newEmail': newEmail,
                'code': code,
            })
        }).catch((error) => {
            console.error(error);
            return error;
        });
    }
}

export default ApiInterface
