/*
* CSS for search form
*/

export const SearchFormStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 15,
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    submit: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    form: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    typography: {
        padding: theme.spacing(2),
    },
    fab: {
        margin: theme.spacing(1),
    },
});