import 'date-fns';
import React from 'react';
import {withStyles,} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {BootstrapButton} from "./SubmitButton";
import {handlePeriodChange, periods,} from "./SearchResources";
import {useStyles} from "./SearchStyles";

class PeriodSearch extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        period: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.handlePeriodChange = handlePeriodChange.bind(this);

        this.state = {
            period: props.period,
        };
    }

    render() {
        let { classes } = this.props;
        return (
            <main className={classes.main}>
                <form className={classes.container} noValidate autoComplete="off">

                    <TextField
                        id="outlined-select-period"
                        className={classes.textField}
                        select
                        label="Period"
                        value={this.state.period}
                        onChange={this.handlePeriodChange}
                        helperText="Please select a period"
                        variant="outlined"
                    >
                        {periods.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <BootstrapButton
                        onClick={() => {
                            this.props.handleSubmit(
                                this.state.period,
                            );
                        }}
                        variant="contained"
                        color="primary"
                        disableRipple
                        className={classes.submit}>
                        Submit
                    </BootstrapButton>
                </form>
            </main>
        );
    }
}

export default withStyles(useStyles)(PeriodSearch);
