import React, {Component} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {signInstyles} from "./SignIn-SignUp-Code";
import * as PropTypes from "prop-types";

class Profile extends Component {
    static propTypes = {
        user: PropTypes.array.isRequired,
        velocities: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {

            series: [
                {
                    name: 'Metric1',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric2',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric3',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric4',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric5',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric6',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric7',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric8',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric9',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric10',
                    data: this.generateData(17, {
                        min: 0,
                        max: 90
                    })
                },
                {
                    name: 'Metric11',
                    data: this.generateData(18, {
                        min: 0,
                        max: 90
                    })
                },
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'heatmap',
                },
                dataLabels: {
                    enabled: false
                },
                colors: ["#008FFB"],
                title: {
                    text: 'HeatMap Chart (Single color)'
                },
            },


        };
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    generateData(number, minMaxObject) {
        let data = [];
        for (let i = 0; i < number; i++) {
            data.push(this.getRandomInt(minMaxObject.max));
        }
        return data;
    }

    render() {
        const { user } = this.props;

        return (
            <div>
                hi {user.user.firstName} {user.user.lastName}


                {/*<div id="chart">*/}
                {/*    <Chart options={this.state.options} series={this.state.series} type="heatmap" height={350} />*/}
                {/*</div>*/}

                {/*{(velocities.length !== 0) && <div>*/}
                {/*    {velocities[0].recordedSpeed}*/}
                {/*    <ResponsiveContainer*/}
                {/*        width="100%" height={400}>*/}
                {/*        <BarChart*/}
                {/*            data={velocities}*/}
                {/*            margin={{*/}
                {/*                top: 5, right: 30, left: 20, bottom: 5,*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <CartesianGrid strokeDasharray="3 3"/>*/}
                {/*            <XAxis dataKey="deviceCreatedAt"/>*/}
                {/*            <YAxis/>*/}
                {/*            <Tooltip/>*/}
                {/*            <Legend verticalAlign="top" wrapperStyle={{lineHeight: '40px'}}/>*/}
                {/*            <ReferenceLine y={0} stroke="#000"/>*/}
                {/*            <Brush dataKey="deviceCreatedAt" height={30} stroke="#8884d8"/>*/}
                {/*            <Bar dataKey="recordedSpeed" fill="#8884d8"/>*/}
                {/*        </BarChart>*/}
                {/*    </ResponsiveContainer>*/}
                {/*</div>*/}
                {/*}*/}
            </div>
        );
    }
}

export default withStyles(signInstyles)(Profile);
