
export function _getDefaultSessionsData() {
    return {
        sessionId: "No Data",
        startDate: "No Data",
        title: "No Data",
        sport: "No Data",
        activity: "No Data",
        notes: "No Data",
        createdAt: "No Data",
        updatedAt: "No Data",
        measurements: []
    };
}

export function _getDefaultMeasurementData() {
    return {
        measurementId: "No Data",
        measurement: "No Data",
        playerId: "No Data",
        radarAddress: "No Data",
        deviceCreatedAt: "No Data",
        createdAt: "No Data",
        updatedAt: "No Data",
        deletedAt: "No Data",
    };
}

export function _getDefaultHistoryData() {
    return {
        measurementId: "No Data",
        velocity: "No Data",
        sport: "No Data",
        activity: "No Data",
        videoPath: "No Data",
        deviceCreatedAt: "No Data",
        updatedAt: "No Data",
        deletedAt: "No Data",
        macAddr: "No Data",
    }
}

export function _getDefaultDevicesData() {
    return {
        deviceId: "No Data",
        model: "No Data",
        osAndVersion: "No Data",
        appVersion: "No Data",
        createdAt: "No Data",
        updatedAt: "No Data",
        settings: [_getDefaultDeviceSettingsData()]
    };
}

export function _getDefaultDeviceSettingsData() {
    return {
        context: "No Data",
        autoEdit: "No Data",
        autoPause: "No Data",
        speedAnnouncements: "No Data",
        speedRange: "No Data",
        units: "No Data",
        createdAt: "No Data",
        updatedAt: "No Data",
    };
}

export function _getDefaultPlayerData() {
    return {
        playerId: "No Data",
        name: "No Data",
        sport: "No Data",
        hsGradYear: 0,
        level: "No Data",
        orgName: "No Data",
        teamName: "No Data",
        phoneNumber: "No Data",
        state: "No Data",
        country: "No Data",
        zipCode: "No Data",
        createdAt: "No Data",
        additional: [{
            sportMetaData: {unknown: "No Data"},
            positions: ["No Data"],
            seasons: ["No Data"],
            biometrics: "No Data",
            handed: "No Data",
            gender: "No Data",
            birthDate: "No Data",
        }],
    }
}