import React from "react";
import * as PropTypes from "prop-types";
import {renderCustomizedLabel} from "./customizedLabel";
import {Cell, Pie, PieChart, Tooltip,} from 'recharts';

class MyPieChart extends React.Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
        colors: PropTypes.array.isRequired,
    };

    render() {
        return (
            <PieChart width={750} height={750}>
                <Pie
                    isAnimationActive={false}
                    data={this.props.data}
                    cx={400}
                    cy={400}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={300}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {
                        this.props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={this.props.colors[index % this.props.colors.length]} />)
                    }
                </Pie>
                <Tooltip />
            </PieChart>
        );
    }
}

export default (MyPieChart);
