import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import {SearchFormStyles} from "./styles/SearchFormStyles";
import SearchIcon from '@material-ui/icons/Search';
import Theme from "./styles/UpdateEmailTheme";
import { ThemeProvider } from '@material-ui/core/styles';

class UpdateEmailSearchForm extends React.Component {
    static propTypes = {
        handleSearchClick: PropTypes.func,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.changeFirstName = this.changeFirstName.bind(this);
        this.changeLastName = this.changeLastName.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this._search = this._search.bind(this);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            status: '',
            labelWidth: 0,
            view: "Search",
            setOpen: false,
        };
    }

    onSubmit = event => {
        event.preventDefault();

        let { status } = this.state;

        this.props.handleSearchClick({
            status: status,
            view: "All Users",
            history: '/dashboard/users',
        });
    };

    changeFirstName(event) {
        this.setState({firstName: event.target.value});
    }

    changeLastName(event) {
        this.setState({lastName: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }

    clearEmail = () => {
        this.setState({
            email: "",
        });
    };

    clearFirstName = () => {
        this.setState({
            firstName: "",
        });
    };

    clearLastName = () => {
        this.setState({
            lastName: "",
        });
    };

    onKeyUp = event => {
        if (event.charCode === 13) {
            this._search()
        }
    };

    _search() {
        this.props.handleSearchClick(
            this.state.firstName,
            this.state.lastName,
            this.state.email
        );
    }

    render() {

        let { classes } = this.props;

        return (
            <main className={classes.main} onSubmit={this.onSubmit}>
                <ThemeProvider theme={Theme}>
                    <form id="form" className={classes.form}>
                        <TextField
                            id="filled-name"
                            label="First Name"
                            className={classes.textField}
                            value={this.state.firstName}
                            onChange={this.changeFirstName}
                            onKeyPress={this.onKeyUp}
                            size="small"
                            variant="outlined"
                            InputProps={{endAdornment: <ClearIcon onClick={this.clearFirstName}/>}}
                            autoComplete="given-name"
                            style={{ width: '100%', maxWidth: '340px' }}
                        />

                        <TextField
                            id="filled-name"
                            label="Last Name"
                            className={classes.textField}
                            value={this.state.lastName}
                            onChange={this.changeLastName}
                            onKeyPress={this.onKeyUp}
                            variant="outlined"
                            size="small"
                            InputProps={{endAdornment: <ClearIcon onClick={this.clearLastName}/>}}
                            autoComplete="family-name"
                            style={{ width: '100%', maxWidth: '340px' }}
                        />

                        <TextField
                            id="filled-name"
                            label="E-Mail"
                            className={classes.textField}
                            value={this.state.email}
                            onChange={this.changeEmail}
                            onKeyPress={this.onKeyUp}
                            variant="outlined"
                            size="small"
                            InputProps={{endAdornment: <ClearIcon onClick={this.clearEmail}/>}}
                            autoComplete="email"
                            style={{ width: '100%', maxWidth: '340px' }}
                        />

                        <Button
                            onClick={() => this._search()}
                            variant="outlined"
                            color="primary"
                            disableRipple
                            className={classes.textField}
                            startIcon={<SearchIcon />}
                            size="large">
                            Search Users
                        </Button>
                    </form>
                </ThemeProvider>
            </main>
        );
    }
}

export default withStyles(SearchFormStyles)(UpdateEmailSearchForm);
