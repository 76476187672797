import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/Help';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import {BootstrapButton} from "./SubmitButton";
import ClearIcon from '@material-ui/icons/Clear';
import {SearchFormStyles} from "./styles/SearchFormStyles";

class SearchForm extends React.Component {
    static propTypes = {
        handleSearchClick: PropTypes.func,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.changeFirstName = this.changeFirstName.bind(this);
        this.changeLastName = this.changeLastName.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this._search = this._search.bind(this);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            status: '',
            labelWidth: 0,
            view: "Search",
            setOpen: false,
        };
    }


    /************************************ PEOPLE ************************************************/

    onSubmit = event => {
        event.preventDefault();

        let { status } = this.state;

        this.props.handleSearchClick({
            status: status,
            view: "All Users",
            history: '/dashboard/users',
        });
    };

    changeFirstName(event) {
        this.setState({firstName: event.target.value});
    }

    changeLastName(event) {
        this.setState({lastName: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleClickOpen = () => {
        this.setState({ setOpen: true });
    };

    handleClose = () => {
        this.setState({ setOpen: false });
    };

    clearEmail = () => {
        this.setState({
            email: "",
        });
    };

    clearFirstName = () => {
        this.setState({
            firstName: "",
        });
    };

    clearLastName = () => {
        this.setState({
            lastName: "",
        });
    };

    onKeyUp = event => {
        if (event.charCode === 13) {
            this._search()
        }
    };

    _search() {
        this.props.handleSearchClick(
            this.state.firstName,
            this.state.lastName,
            this.state.email
        );
    }

    render() {

        let { classes } = this.props;

        return (
            <main className={classes.main} onSubmit={this.onSubmit}>
                <form id="form" className={classes.form}>
                    <Fab className={classes.fab}>
                        <HelpIcon
                            fontSize="large"
                            style={{ color: '#007bff' }}
                            onClick={this.handleClickOpen}
                        />
                    </Fab>

                    <TextField
                        id="filled-name"
                        label="First Name"
                        className={classes.textField}
                        value={this.state.firstName}
                        onChange={this.changeFirstName}
                        onKeyPress={this.onKeyUp}
                        margin="normal"
                        variant="filled"
                        InputProps={{endAdornment: <ClearIcon onClick={this.clearFirstName}/>}}
                        autoComplete="given-name"
                    />

                    <TextField
                        id="filled-name"
                        label="Last Name"
                        className={classes.textField}
                        value={this.state.lastName}
                        onChange={this.changeLastName}
                        onKeyPress={this.onKeyUp}
                        margin="normal"
                        variant="filled"
                        InputProps={{endAdornment: <ClearIcon onClick={this.clearLastName}/>}}
                        autoComplete="family-name"
                    />

                    <TextField
                        id="filled-name"
                        label="E-Mail"
                        className={classes.textField}
                        value={this.state.email}
                        onChange={this.changeEmail}
                        onKeyPress={this.onKeyUp}
                        margin="normal"
                        variant="filled"
                        InputProps={{endAdornment: <ClearIcon onClick={this.clearEmail}/>}}
                        autoComplete="email"
                    />

                    <BootstrapButton
                        onClick={() => this._search()}
                        variant="contained"
                        color="primary"
                        disableRipple
                        className={classes.submit}>
                        Submit
                    </BootstrapButton>
                </form>

                <Dialog
                    open={this.state.setOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">{"Wild Card"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The wildcard is %
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </main>
        );
    }
}

export default withStyles(SearchFormStyles)(SearchForm);
