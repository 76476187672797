/*
* CSS for single user view
*/

export const SingleUserStyles = theme => ({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tabbedArea: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: '#FAF9F6',
        marginTop: theme.spacing(4),
    },
    tabbedContent: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableHeader: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
    },
    errorOutline: {
        marginTop: theme.spacing(4),
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: '2px solid red'
    }
});
