import React, {PureComponent} from 'react';
import Paper from '@material-ui/core/Paper';
import {Typography, withStyles} from '@material-ui/core';
import API from "./ApiInterface";
import SingleUserTable from "./SingleUserTable";
/* eslint-disable react/no-multi-comp */
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {_formatDate} from "./common/DateResources";

const styles = theme => ({
    root: {
        height: '80vh',
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        width: '90%',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 'auto',
    },
});

const data = [
    { name: "1", cost: 4.11, impression: "hi" },
    { name: "2", cost: 2.39, impression: "sdaf" },
    { name: "3", cost: 1.37, impression: "adf" },
    { name: "4", cost: 1.16, impression: "adf" },
    { name: "5", cost: 2.29, impression: "asdf20adsf0" },
    { name: "6", cost: 3, impression: "asdfdsgadf" },
    { name: "7", cost: 0.53, impression: "adf" },
    { name: "8", cost: 2.52, impression: "1sf0asdf0" },
    { name: "9", cost: 1.79, impression: "adf" },
    { name: "10", cost: 2.94, impression: "asf" },
    { name: "11", cost: 4.3, impression: "gfh" },
    { name: "12", cost: 4.41, impression: "ert" },
    { name: "13", cost: 2.1, impression: "dfh" },
    { name: "14", cost: 8, impression: "jfg" },
    { name: "15", cost: 2, impression: "ujty" },
    { name: "16", cost: 9, impression: "m,hj" },
    { name: "17", cost: 3, impression: "tj" },
    { name: "18", cost: 2, impression: "hgm" },
    { name: "19", cost: 3, impression: "ghk" },
    { name: "20", cost: 7, impression: "vbn" },
    { name: "21", cost: 8, impression: "vfbn" },
    { name: "22", cost: 9, impression: "vbgn" },
];


const getAxisYDomain = (from, to, ref, offset) => {
    const refData = data.slice(from - 1, to);
    let [bottom, top] = [refData[0][ref], refData[0][ref]];
    refData.forEach((d) => {
        if (d[ref] > top) top = d[ref];
        if (d[ref] < bottom) bottom = d[ref];
    });

    return [(bottom | 0) - offset, (top | 0) + offset];
};

class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, payload,
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
            </g>
        );
    }
}

class CustomizedLabel extends PureComponent {
    render() {
        const {
            x, y, stroke, value,
        } = this.props;

        return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{value}</text>;
    }
}

class PlaceHolder extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            // user: this.props.userData,
            activeVelocities: null,
            data: data,
            left: 'dataMin',
            right: 'dataMax',
            refAreaLeft: '',
            refAreaRight: '',
            top: 'dataMax+1',
            bottom: 'dataMin-1',
            top2: 'dataMax+20',
            bottom2: 'dataMin-20',
            animation: true,
        }
    }

    zoom() {
        let { refAreaLeft, refAreaRight, data } = this.state;

        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            this.setState(() => ({
                refAreaLeft: '',
                refAreaRight: '',
            }));
            return;
        }

        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        // yAxis domain
        const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'cost', 1);
        const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);

        this.setState(() => ({
            refAreaLeft: '',
            refAreaRight: '',
            data: data.slice(),
            left: refAreaLeft,
            right: refAreaRight,
            bottom,
            top,
            bottom2,
            top2,
        }));
    }

    // zoomOut() {
    //     const { data } = this.state;
    //     this.setState(() => ({
    //         data: data.slice(),
    //         refAreaLeft: '',
    //         refAreaRight: '',
    //         left: 'dataMin',
    //         right: 'dataMax',
    //         top: 'dataMax+1',
    //         bottom: 'dataMin',
    //         top2: 'dataMax+50',
    //         bottom2: 'dataMin+50',
    //     }));
    // }

    componentDidMount() {
        API.getActiveHistoriesForUser(parseInt(this.props.match.params.userID)).then(result => {
            if (result["error"] === false) {
                if (result["result"].length > 0) {
                    for (let i = 0; i < result["result"].length; i++) {
                        result["result"][i].sport = this._fixSport(result["result"][i]);
                        result["result"][i].activity = this._fixActivity(result["result"][i]);

                        if (result["result"][i].deletedAt === null || result["result"][i].deletedAt === "" || result["result"][i].deletedAt === 0) {
                            result["result"][i].deletedAt = "False";
                        } else {
                            result["result"][i].deletedAt = "True";
                        }

                        if (result["result"][i].video === null || result["result"][i].video === "") {
                            result["result"][i].video = "False";
                        } else {
                            result["result"][i].video = "True";
                        }

                        result["result"][i].updatedAt = _formatDate(result["result"][i].updatedAt);
                        result["result"][i].deviceCreatedAt = _formatDate(result["result"][i].deviceCreatedAt);
                    }

                    this.setState({
                        activeVelocities: result["result"],
                    });
                } else {
                    this.setState({
                        activeVelocities: [{
                            recordedSpeed: "No Data",
                            sport: "No Data",
                            activity: "No Data",
                            video: "No Data",
                            deviceCreatedAt: "No Data",
                            updatedAt: "No Data",
                            deletedAt: "No Data",
                        }]
                    });
                }
            } else {
                this.setState({
                    errorActiveVelocities: true,
                });
            }
        }).catch((error) => {
            this.setState({
                errorActiveVelocitiesSubmit: {
                    error: true,
                    message: error,
                },
            });
        });
    }

    _fixDate(date) {
        let fixDate = "No data";
        if (date !== undefined) {
            // slice the date so it is only YYYY-MM-DD
            const index = date.indexOf("T");
            fixDate = date.slice(0, index);
        }
        return fixDate;
    }

    _fixSport(result) {
        let fixSport = result.sport;
        if (result.sport === null || result.sport === "" || result.sport === undefined) {
            fixSport = result["sportOther"];
        }
        return fixSport;
    }

    _fixActivity(result) {
        let fixActivity = result.activity;
        if (fixActivity === undefined || fixActivity === null || fixActivity === "") {
            fixActivity = result.activityOther;
        }
        return fixActivity;
    }

    render() {
        if (this.state["activeVelocities"]) {

            // const {
            //     data, barIndex, left, right, refAreaLeft, refAreaRight, top, bottom, top2, bottom2,
            // } = this.state;

            return (

                <Paper>
                    <Typography variant="h5" component="h3">
                        Place Holder
                    </Typography>

                    <img src={ require('./main_speed_display.jpeg') } width={600} height={300} alt={"something"}/>

                    {/*<ResponsiveContainer*/}
                    {/*    width="100%" height={400}>*/}
                    {/*    <div className="highlight-bar-charts" style={{ userSelect: 'none' }}>*/}
                    {/*        <button*/}
                    {/*            // href="javascript: void(0);"*/}
                    {/*            className="btn update"*/}
                    {/*            onClick={this.zoomOut.bind(this)}*/}
                    {/*        >*/}
                    {/*            Zoom Out*/}

                    {/*        </button>*/}

                    {/*        <LineChart*/}
                    {/*            width="100%"*/}
                    {/*            height={400}*/}
                    {/*            data={this.state["activeVelocities"]}*/}
                    {/*            onMouseDown={e => this.setState({ refAreaLeft: e.activeLabel })}*/}
                    {/*            onMouseMove={e => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}*/}
                    {/*            onMouseUp={this.zoom.bind(this)}*/}
                    {/*        >*/}
                    {/*            <CartesianGrid strokeDasharray="3 3" />*/}
                    {/*            <XAxis*/}
                    {/*                allowDataOverflow*/}
                    {/*                dataKey="deviceCreatedAt"*/}
                    {/*                domain={[left, right]}*/}
                    {/*                type="number"*/}
                    {/*            />*/}
                    {/*            <YAxis*/}
                    {/*                allowDataOverflow*/}
                    {/*                domain={[bottom, top]}*/}
                    {/*                type="number"*/}
                    {/*                yAxisId="1"*/}
                    {/*            />*/}
                    {/*            <YAxis*/}
                    {/*                orientation="right"*/}
                    {/*                allowDataOverflow*/}
                    {/*                domain={[bottom2, top2]}*/}
                    {/*                type="number"*/}
                    {/*                yAxisId="2"*/}
                    {/*            />*/}
                    {/*            <Tooltip />*/}
                    {/*            <Line yAxisId="1" type="natural" dataKey="recordedSpeed" stroke="#8884d8" animationDuration={300} />*/}
                    {/*            <Line yAxisId="2" type="natural" dataKey="sport" stroke="#82ca9d" animationDuration={300} />*/}

                    {/*            {*/}
                    {/*                (refAreaLeft && refAreaRight) ? (*/}
                    {/*                    <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />) : null*/}
                    {/*            }*/}
                    {/*        </LineChart>*/}
                    {/*    </div>*/}
                    {/*</ResponsiveContainer>*/}


                    <ResponsiveContainer
                        width="100%" height={400}>
                        <LineChart
                            width={500}
                            height={300}
                            data={this.state["activeVelocities"]}
                            margin={{
                                top: 20, right: 30, left: 20, bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="deviceCreatedAt" height={60} tick={<CustomizedAxisTick />} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="recordedSpeed" stroke="#8884d8" label={<CustomizedLabel />} />
                            <Line type="monotone" dataKey="sport" stroke="#82ca9d" />
                            <Line type="monotone" dataKey="activity" stroke="#468189" />
                        </LineChart>
                    </ResponsiveContainer>

                    {/*<ResponsiveContainer*/}
                    {/*    width="100%" height={400}>*/}
                    {/*    <div className="highlight-bar-charts" style={{ userSelect: 'none' }}>*/}
                    {/*        <button*/}
                    {/*            // href="javascript: void(0);"*/}
                    {/*            className="btn update"*/}
                    {/*            onClick={this.zoomOut.bind(this)}*/}
                    {/*        >*/}
                    {/*            Zoom Out*/}

                    {/*        </button>*/}

                    {/*        <LineChart*/}
                    {/*            width={800}*/}
                    {/*            height={400}*/}
                    {/*            data={data}*/}
                    {/*            onMouseDown={e => this.setState({ refAreaLeft: e.activeLabel })}*/}
                    {/*            onMouseMove={e => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}*/}
                    {/*            onMouseUp={this.zoom.bind(this)}*/}
                    {/*        >*/}
                    {/*            <CartesianGrid strokeDasharray="3 3" />*/}
                    {/*            <XAxis*/}
                    {/*                allowDataOverflow*/}
                    {/*                dataKey="name"*/}
                    {/*                domain={[left, right]}*/}
                    {/*                type="string"*/}
                    {/*            />*/}
                    {/*            <YAxis*/}
                    {/*                allowDataOverflow*/}
                    {/*                domain={[bottom, top]}*/}
                    {/*                type="number"*/}
                    {/*                yAxisId="1"*/}
                    {/*            />*/}
                    {/*            <YAxis*/}
                    {/*                orientation="right"*/}
                    {/*                allowDataOverflow*/}
                    {/*                domain={[bottom2, top2]}*/}
                    {/*                type="string"*/}
                    {/*                yAxisId="2"*/}
                    {/*            />*/}
                    {/*            <Tooltip />*/}
                    {/*            <Line yAxisId="1" type="natural" dataKey="cost" stroke="#8884d8" animationDuration={300} />*/}
                    {/*            <Line yAxisId="2" type="natural" dataKey="impression" stroke="#82ca9d" animationDuration={300} />*/}

                    {/*            {*/}
                    {/*                (refAreaLeft && refAreaRight) ? (*/}
                    {/*                    <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />) : null*/}
                    {/*            }*/}
                    {/*        </LineChart>*/}

                    {/*    </div>*/}
                    {/*</ResponsiveContainer>*/}


                    <SingleUserTable
                        headerNames={["Recorded Speed", "Sport", "Activity", "Video", "Created", "Updated"]}
                        rowIndexes={["recordedSpeed", "sport", "activity", "video", "deviceCreatedAt", "updatedAt"]}
                        rows={this.state["activeVelocities"]}
                        title={"Last 50 (non-deleted) Velocities"}
                    />
                </Paper>
            )
        } else {
            return <div>
                Loading data...
            </div>
        }
    }
}

export default (withStyles(styles)(PlaceHolder));
