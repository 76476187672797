import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import {Typography} from '@material-ui/core';
import {styles} from "./styles/TableStyles";

class SingleUserTable extends React.Component {
    render() {
        const { rows, classes, headerNames, rowIndexes, title } = this.props;

        if (this.props) {
            return (
                <div>
                    <Typography
                        variant="h6"
                        component="h6"
                        className={classes.rootHeader}>
                        {title}
                    </Typography>
                    <Paper className={classes.root}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow className={classes.row}>
                                        {headerNames.map((value, idx) => (
                                            idx === 0 ?
                                                <TableCell
                                                    key={idx}>
                                                    {value}
                                                </TableCell> :
                                                <TableCell
                                                    key={idx}>
                                                    {value}
                                                </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row, idx1) => (

                                        <TableRow
                                            className={classes.row}
                                            key={idx1}>
                                            {rowIndexes.map((v, idx2) => (
                                                idx2 === 0 ?
                                                    <TableCell
                                                        key={idx2}
                                                        component="th"
                                                        scope="row">
                                                        {row[v]}
                                                    </TableCell> :
                                                    <TableCell
                                                        key={idx2}>
                                                        {row[v]}
                                                    </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </div>
            );
        } else {
            return <div>
                Loading profile...
            </div>
        }
    }
}

export default withStyles(styles)(SingleUserTable);
