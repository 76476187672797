
export function changeFirstName(event) {
    this.setState({firstName: event.target.value});
}

export function changeLastName(event) {
    this.setState({lastName: event.target.value});
}

export function changeEmail(event) {
    this.setState({
        email: event.target.value,
        emailError: false,
    });
}

export function changeCode(event) {
    this.setState({code: event.target.value});
}

export function changeGradYear(event) {
    let year = parseInt(event.target.value);
    this.setState({gradYear: event.target.value});
    if (year < 2050) {
        if (year > 1920) {
            this.setState({
                helperYearText: '',
                yearError: false,
            });
        } else {
            this.setState({
                helperYearText: 'You are over a hundred years old and still playing baseball? What is your secret?',
                yearError: true,
            });
        }
    } else {
        this.setState({
            helperYearText: 'You are too young to be playing baseball',
            yearError: true,
        });
    }
}

export function changePassword(event) {
    let password = event.target.value;
    this.setState({password: event.target.value});

    if (/[A-Z]/.test(password)) {
        if (/\d/.test(password)) {
            if (password.length > 7) {
                this.setState({
                    helperPasswordText: '',
                    passwordError: false,
                });
            } else {
                this.setState({
                    helperPasswordText: '>= 8 Characters',
                    passwordError: true,
                });
            }
        } else {
            this.setState({
                helperPasswordText: 'Must contain number',
                passwordError: true,
            });
        }
    } else {
        this.setState({
            helperPasswordText: 'Must have uppercase letter',
            passwordError: true,
        });
    }
}
