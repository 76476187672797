const paleCornflowerBlue = '#AEC9F3';
const jordyBlue2 = '#86AEEE';
const unitedNationsBlue = '#5E94E8';
const royalBlue = '#3679E2';
const denim = '#1F63CC';
const yaleBlue = '#194FA3';
const darkCerulean = '#133B7B';

export const newAccountColors = [
    paleCornflowerBlue,
    jordyBlue2,
    unitedNationsBlue,
    royalBlue,
    denim,
    yaleBlue,
    darkCerulean,
];

const pastelBlue = '#AECEBE';
const cambridgeBlue = '#97C0AC';
const greenSheen = '#81B29A';
const aurometalsaurus = '#6A927F';
const stormClud = '#537263';
const arsenic = '#3B5146';
const kombuGreen = '#2F4138';

export const velocityColors = [
    pastelBlue,
    cambridgeBlue,
    greenSheen,
    aurometalsaurus,
    stormClud,
    arsenic,
    kombuGreen,
];

const paleCornflower =  '#ACCAF5';
const babyBlueEyes =  '#A4C5F5';
const jordyBlue = '#86B1F1';
const cornFlowerBlue = '#689EEE';
const celestialBlue = '#5188D8';
const blueYonder = '#416DAD';
const bdazzledBlue = '#315282';

export const videoColors = [
    paleCornflower,
    babyBlueEyes,
    jordyBlue,
    cornFlowerBlue,
    celestialBlue,
    blueYonder,
    bdazzledBlue,
];

const blueShade1 = "#56B5D5";
const blueShade2 = "#2EA4CC";
const blueShade3 = "#179AC6";
const blueShade4 = "#158CB4";
const blueShade5 = "#137FA3";
const blueShade6 = "#0B465A";

const greenShade1 = "#7AABA9";
const greenShade2 = "#599694";
const greenShade3 = "#43807E";
const greenShade4 = "#366665";
const greenShade5 = "#284D4C";

const lightSteelBlue ="#ABCCD9";
const darkSkyBlue ="#93BDCE";
const moonstoneBlue ="#7CAFC4";
const desaturatedCyan ="#6690A1";
const paynesGrey ="#4F707D";
const darkSlateGray ="#39505A";

export const theySayItsGreen = [
    lightSteelBlue,
    darkSkyBlue,
    moonstoneBlue,
    desaturatedCyan,
    paynesGrey,
    darkSlateGray,
    greenShade5,
];

const lavenderGrey = "#C8BEC9";
const pastelPurple = "#B6A8B8";
const roseQuartz = "#A493A6";
const mountbattonPink = "#917D94";
const oldLavender = "#7F6882";
const dimGrey = "#6D5271";
const pineapple = "#5B3D5F";
const purpleTaupe = "#49274D";
const darkPurple = "#37123C";
const darkPurpleTwo = "#321137";
const darkPurpleThree = "#2E0F32";

export const sportTagColors = [
    lavenderGrey,
    pastelPurple,
    roseQuartz,
    mountbattonPink,
    oldLavender,
    dimGrey,
    pineapple,
    purpleTaupe,
    darkPurple,
    darkPurpleTwo,
    darkPurpleThree,
];

const pastelPurpleTwo = "#B998B9";
const glossyGrape = "#B08BB1";
const operaMauve = "#A77EA8";
const montbattenPink = "#9F72A0";
const pompAndPower = "#916892";
const chineseViolet = "#835E83";
const oldLavenderTwo = "#745375";
const graniteGray = "#664966";
const pineappleTwo = "#573F58";

export const sportTagUserColors = [
    pastelPurpleTwo,
    glossyGrape,
    operaMauve,
    montbattenPink,
    pompAndPower,
    chineseViolet,
    oldLavenderTwo,
    graniteGray,
    pineappleTwo,
];

const spanishPink ="#E1BBBF";
const pastelPink ="#D5A0A5";
const puce ="#C9858C";
const roseGold ="#BD6B73";

export const genderColors = [
    spanishPink,
    pastelPink,
    puce,
    roseGold,
];

const persianPlum = "#AE1E33";
const antiqueRuby = "#8C1829";

export const handColors = [
    persianPlum,
    antiqueRuby,
];

const tomato = "#F06449";
const salmon = "#F2806A";

export const phoneColors = [
    tomato,
    salmon,
];

const orangePeel = "#F29602";
const brightYellowCrayola = "#FDA821";
const chinesseYellow = "#F7B32B";

export const appleDeviceTypeColor = [
    orangePeel,
    brightYellowCrayola,
    chinesseYellow,
];

const paleLavender ="#DCD9FF";
const periwinkle ="#C5C0FF";
const maximumBluePurple ="#AEA7FF";
const paleViolet ="#978EFF";
const mediumSlateBlue = "#8B81FF";
const mediumSlateBlue2 ="#8075FF";
const slateBlue ="#6960D1";
const mediumPurple = "#9B71F4";
const navyPurple = "#8D5CF2";
const plumpPurple ="#5E56BA";
const liberty ="#524BA3";

export const sportColors = [
    paleLavender,
    periwinkle,
    maximumBluePurple,
    paleViolet,
    mediumSlateBlue,
    mediumSlateBlue2,
    slateBlue,
    mediumPurple,
    navyPurple,
    plumpPurple,
    liberty,
];

export const shadesOfBlueAndGreen = [
    blueShade1,
    blueShade2,
    blueShade3,
    blueShade4,
    blueShade5,
    blueShade6,
    greenShade1,
    greenShade2,
    greenShade3,
    greenShade4,
    greenShade5,
];