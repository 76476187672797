/*
* CSS for sign in, sign up, enter email, enter code snippets
*/

export const AdminRoleStyles = theme => ({
    root: {
        height: 'auto',
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        width: '98%',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 'auto',
    },
    paper: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    newRole: {
        margin: 10,
        minWidth: 500,
        maxWidth: 500,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
    },
    card: {
        display: 'grid',
        margin: 10,
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '50%',
        marginRight: '50%',
    },
    permissionCard: {
        margin: 10,
        paddingBottom: 20,
        marginLeft: '50%',
        marginRight: '50%',
    },
    spaceCardDiv: {
        minWidth: 500,
        maxWidth: 500,
        backgroundColor: "#F5F5F5",
    }
});
