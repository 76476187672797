import React from "react";
import * as PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class Password extends React.Component {

    static propTypes = {
        submit: PropTypes.func.isRequired,
        changePassword: PropTypes.func.isRequired,
        passwordError: PropTypes.bool.isRequired,
        helperPasswordText: PropTypes.string.isRequired,
    };

    render() {

        const {
            classes,
            submit,
            passwordError,
            helperPasswordText,
            changePassword,
        } = this.props;

        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Password
                    </Typography>
                    <p>Choose a new password</p>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                id="password"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type='password'
                                error={passwordError}
                                helperText={helperPasswordText}
                                onChange={changePassword}
                            />
                        </Grid>
                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            submit();
                        }}
                    >
                        Set New Password
                    </Button>
                </Paper>
            </main>
        );
    }
}

export default (Password);
