import React from 'react';
import * as PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from "@material-ui/core/TableHead";
import {withRouter} from 'react-router-dom';
import {TablePaginationActionsWrapped} from './TablePaginationActions'
import {Typography} from "@material-ui/core";
import {styles} from "./styles/TableStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import HelpIcon from "@material-ui/icons/Help";
import IconButton from "@material-ui/core/IconButton";

class VelocityPaginationTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            rowsPerPage: 5,
            setOpen: false,
        };
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({ setOpen: true });
    };

    handleClose = () => {
        this.setState({ setOpen: false });
    };

    render() {
        const { rows, classes, headerNames, rowIndexes, title } = this.props;
        const { rowsPerPage, page } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

        return (
            <div>
                <Typography
                    variant="h6"
                    component="h6"
                    className={classes.rootHeader}>

                    {title}
                    <IconButton
                        onClick={this.handleClickOpen}
                    >
                        <HelpIcon
                            fontSize="small"
                            style={{ color: '#007bff' }}
                        />
                    </IconButton>
                </Typography>
                <Paper className={classes.tabbedContent}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.row}>
                                    {headerNames.map((value, idx) => (
                                        idx === 0 ?
                                            <TableCell key={idx}>{value}</TableCell> :
                                            <TableCell key={idx}>{value}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, idx1) => (
                                    <TableRow className={classes.row} key={idx1}>
                                        {rowIndexes.map((v, idx2) => (
                                            idx2 === 0 ?
                                                <TableCell key={idx2} component="th" scope="row">{row[v]}</TableCell> :
                                                <TableCell key={idx2}>{row[v].toString()}</TableCell>
                                        ))}
                                    </TableRow>

                                ))}
                                {emptyRows > 0 && (
                                    <TableRow className={classes.row} style={{ height: 48 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow className={classes.row}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={7}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
                <Dialog
                    open={this.state.setOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">{"More Details"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div>
                                <p>If there is no sport or activity shown this measurement is considered a 'back door / SDK' velocity which might mean this user has not visited the history screen in a while</p>
                                <p>If there is a timestamp in the deleted column that velocity is not visible to the user</p>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

VelocityPaginationTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(VelocityPaginationTable));
