import React from "react";
import * as PropTypes from "prop-types";
import {Legend, RadialBar, RadialBarChart,} from 'recharts';

const style = {
    top: 0,
    left: 350,
    lineHeight: '24px',
};

class MyRadialBarChart extends React.Component {

    static propTypes = {
        data: PropTypes.array.isRequired,
    };

    render() {
        return (
            <RadialBarChart width={500} height={300} cx={150} cy={150} innerRadius={45} outerRadius={140} barSize={20} data={this.props.data}>
                <RadialBar minAngle={15} label={{ position: 'insideStart', fill: '#fff' }} background clockWise dataKey="value" />
                <Legend iconSize={10} width={100} height={140} layout="vertical" verticalAlign="middle" wrapperStyle={style} />
            </RadialBarChart>
        );
    }
}

export default (MyRadialBarChart);
