import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People';
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";

export const UserSideBarMenu = (props) => {
    const { handleHomeClick, handlePlaceHolderClick, handleMessageClick } = props;

    return <div>
        <ListItem button onClick={ handleHomeClick } component={"ul"}>
            <ListItemIcon>
                <HomeIcon/>
            </ListItemIcon>
            <ListItemText primary="Dashboard"/>
        </ListItem>

        <ListItem button onClick={ handlePlaceHolderClick } component={"ul"}>
            <ListItemIcon>
                <PeopleIcon/>
            </ListItemIcon>
            <ListItemText primary="Placeholder"/>
        </ListItem>

        <ListItem button onClick={handleMessageClick} component={"ul"}>
            <ListItemIcon>
                <SpeakerNotesIcon/>
            </ListItemIcon>
            <ListItemText primary="Message"/>
        </ListItem>
    </div>
};
