import API from './ApiInterface';
import React, {Component} from 'react';
import {_checkDates, _get30DaysAgo, today,} from './common/DateResources';
import DateVelocitySportRoleLevelSearch from "./SearchBars/DateVelocitySportRoleLevelSearch";
import MaterialTable from 'material-table';
import {tableIcons} from "./TableIcons";
import CircularDeterminate from "./LoadingCircle";

const columns = [
    { title: 'User Id', field: 'userId', export: false, type: 'numeric'},
    { title: 'Email', field: 'email' },
    { title: 'Velocities', field: 'numSideDoorSpeeds', export: false, type: 'numeric'},
    { title: 'Account Created', field: 'createdAt', export: false},
];

class NewUsers extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            token: this.props.location.state.token,
            users: {
                error: false,
                count: 1,
                result: [],
            },
            selectedStartDate: _checkDates(_get30DaysAgo(today)),
            selectedEndDate: _checkDates(today),
            velocityCount: 1,
            sport: "",
            role: "",
            level: "",
            error: false,
            loading: false,
        };
    }

    handleSubmit (numSpeed, selectedStartDate, selectedEndDate, sport, role, level) {
        this.clearData();
        selectedStartDate = _checkDates(selectedStartDate);
        selectedEndDate = _checkDates(selectedEndDate);
        this.setState({
            selectedStartDate: selectedStartDate,
            selectedEndDate: selectedEndDate,
            sport: sport,
            role: role,
            level: level,
            loading: true,
        });

        API.getNewUsers(this.state.token, selectedStartDate, selectedEndDate, numSpeed, sport, role, level).then(result => {
            if (result["error"] === false) {
                this.setState({
                    users: result,
                    loading: false,
                });
            } else {
                this.setState({
                    error: "Error: " + result["parent"].message + "Query: " + result["parent"].sql,
                });
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    };

    clearData () {
        this.setState({
            loading: true,
            users: {
                error: false,
                count: 0,
                result: [],
            },

        });
    }

    render() {
        return (
            <div>
                <div>
                    <br />
                    <DateVelocitySportRoleLevelSearch
                        selectedStartDate={this.state.selectedStartDate}
                        selectedEndDate={this.state.selectedEndDate}
                        velocityCount={this.state.velocityCount}
                        sport={this.state.sport}
                        role={this.state.role}
                        level={this.state.level}
                        handleSubmit={this.handleSubmit}
                    />
                </div>
                {
                    (this.state.loading) && <div>
                        <div style={{"height": "440px",   display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',}}>
                            <CircularDeterminate />
                        </div>
                    </div>
                }
                {
                    (!this.state.loading) && <div>
                        <br />
                        <MaterialTable
                            title="New Users"
                            columns={columns}
                            data={this.state.users.result}
                            icons={tableIcons}
                            options={{
                                exportButton: true,
                                exportAllData: true,
                            }}
                        />
                    </div>
                }

                {
                    (this.state.error === true) && <div>
                        <p>There was an error with the query. Please email Marguerite with the start date, end date, and number of velocities and the message below</p>
                        <p>{(this.state.error).toString()}</p>
                    </div>
                }
                <br /><br /><br />
            </div>
        );
    }
}

export default NewUsers;
