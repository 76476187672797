import React, {Component} from 'react';
import API from './ApiInterface';
import CircularDeterminate from "./LoadingCircle";
import OutlinedCard from "./AdminRoleCard";
import PermissionsCard from "./PermissionCard";
import Paper from "@material-ui/core/Paper";
import {ResponsiveContainer} from "recharts";
import {withStyles} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import Button from "@material-ui/core/Button";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import NewRoleDialog from "./NewRoleDialog";
import {AdminRoleStyles} from "./styles/AdminRoleStyles";

class AdminRoles extends Component {
    constructor(props) {
        super(props);
        this.handleCreateNewRoleClick = this.handleCreateNewRoleClick.bind(this);
        this.handleDeleteRoleClick = this.handleDeleteRoleClick.bind(this);
        this.handleCreatePermissionClick = this.handleCreatePermissionClick.bind(this);
        this.handleDeletePermissionClick = this.handleDeletePermissionClick.bind(this);
        this.handleRemoveUserFromRoleClick = this.handleRemoveUserFromRoleClick.bind(this);
        this.handleAddPermissionToRoleClick = this.handleAddPermissionToRoleClick.bind(this);
        this.handleRemovePermissionFromRoleClick  = this.handleRemovePermissionFromRoleClick.bind(this);
        this.getAdminRoles = this.getAdminRoles.bind(this);
        this.handleAddUserToRoleClick = this.handleAddUserToRoleClick.bind(this);
        this.handleRoleNameChange = this.handleRoleNameChange.bind(this);
        this.handleRoleDescriptionChange = this.handleRoleDescriptionChange.bind(this);

        this.state = {
            token: this.props.location.state.token,
            adminRoles: null,
            permissions: null,
            getAdminRoleError: {
                error: false,
                message: null,
            },
            loading: true,
            isSnackBarOpen: false,
            alertMessage: null,
            employees: null,
            addNewRoleDialogOpen: false,
            newRoleName: "",
            newRoleDescription: "",
        };
    }

    componentDidMount() {
        this.getAdminRoles();
        this.getPermissions();
        this.getPocketRadarEmployees();
    }

    handleCreateNewRoleClickOpen = () => {
        this.setState({
            addNewRoleDialogOpen: true,
        });
    };

    handleCreateNewRoleClickClose = () => {
        this.setState({
            addNewRoleDialogOpen: false,
            newRoleName: "",
            newRoleDescription: "",
        });
    };

    handleRoleNameChange(event) {
        this.setState({newRoleName: event.target.value});
    }

    handleRoleDescriptionChange(event) {
        this.setState({newRoleDescription: event.target.value});
    }

    handleCreateNewRoleClick() {
        this.setState({
            addNewRoleDialogOpen: false,
            newRoleName: "",
            newRoleDescription: "",
        });
        API.createNewRole(this.state.token, this.state.newRoleName, this.state.newRoleDescription).then(result => {
            if (!result.errorCode) {
                this.setState({
                    alertMessage: "Success " + this.state.newRoleName + " created",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure " + this.state.newRoleName + " not added -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                alertMessage: "Failure " + this.state.newRoleName + " not added -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };

    handleDeleteRoleClick(roleId) {
        API.deleteAdminRole(this.state.token, roleId).then(result => {
            if (!result.errorCode) {
                this.setState({
                    alertMessage: "Success role deleted",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: Role not deleted -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                deleteAdminRoleError: {
                    error: true,
                    message: error,
                },
            });
        });
    };

    getAdminRoles() {
        API.getAdminRoles(this.state.token).then(adminRoleResults => {
            this.setState({
                loading: false,
                adminRoles: adminRoleResults,
            });
        }).catch((error) => {
            this.setState({
                getAdminRoleError: {
                    error: true,
                    message: error,
                },
            });
        });
    }

    getPermissions() {
        API.getPermissions(this.state.token).then(result => {
            this.setState({
                loading: false,
                permissions: result.result
            });
        }).catch((error) => {
            this.setState({
                getPermissionsError: {
                    error: true,
                    message: error,
                },
            });
        });
    };

    handleRemoveUserFromRoleClick(roleId, userId) {
        API.removeUserFromRole(this.state.token, roleId, userId).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Success user removed from role",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: Role not deleted -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                getAdminRoleError: {
                    error: true,
                    message: error,
                },
            });
        });
    };

    handleAddUserToRoleClick(roleId, userId) {
        API.addUserToRole(this.state.token, roleId, userId).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Successfully addded user",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: user not added to role -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                alertMessage: "Did not add user -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };

    handleRemovePermissionFromRoleClick(roleId, permissionName) {

        let permissionId;
        this.state.permissions.forEach(permission => {
            if (permission.name === permissionName) {
                permissionId = permission._id;
            }
        });

        API.removePermissionFromRole(this.state.token, roleId, permissionId).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Successfully removed permission from role",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: permission not removed from role -- error: " + result.errorDescription,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                alertMessage: "Failure: permission not removed from role -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };

    handleAddPermissionToRoleClick(roleId, permissionId) {
        API.addPermissionToRole(this.state.token, roleId, permissionId).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Successfully added permission to role",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: permission not added to role -- error: " + result.errorDescription,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getAdminRoles();
        }).catch((error) => {
            this.setState({
                alertMessage: "Failure: permission not added to role -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };

    handleDeletePermissionClick(permissionId) {
        API.deletePermission(this.state.token, permissionId).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Successfully deleted permission",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure: permission not deleted -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getPermissions();
        }).catch((error) => {
            this.setState({
                alertMessage: "Failure: permission not deleted -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };


    handleCreatePermissionClick(permissionName, permissionDescription) {
        API.createPermission(this.state.token, permissionName, permissionDescription).then(result => {
            if (typeof(result.errorCode) === 'undefined') {
                this.setState({
                    alertMessage: "Success " + permissionName + " created",
                    isSnackBarOpen: true,
                    severity: "success",
                });
            } else {
                this.setState({
                    alertMessage: "Failure " + permissionName + " not added -- error: " + result.message,
                    isSnackBarOpen: true,
                    severity: "error",
                });
            }
        }).then(() => {
            this.getPermissions();
        }).catch((error) => {
            this.setState({
                alertMessage: "Failure " + permissionName + " not added -- error: " + error,
                isSnackBarOpen: true,
                severity: "error",
            });
        });
    };

    getPocketRadarEmployees() {
        API.getPocketRadarEmployees(this.state.token).then(result => {
            this.setState({
                loading: false,
                employees: result
            });
        }).catch((error) => {
            this.setState({
                getAdminRoleError: {
                    error: false,
                    message: error,
                },
            });
        });
    }

    handleSnackBarClose = () => {
        this.setState({
            isSnackBarOpen: false,
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <ResponsiveContainer>
                <Paper className={classes.root} >

                    { (!this.state.adminRoles) &&
                    <div>
                        <CircularDeterminate />
                    </div>
                    }

                    { (this.state.adminRoles) && (this.state.permissions) && (this.state.employees) && <div className={classes.container}>

                        <div className={classes.permissionCard}>
                            <PermissionsCard
                                permissions={this.state.permissions}
                                handleCreatePermissionClick={this.handleCreatePermissionClick}
                                handleDeletePermissionClick={this.handleDeletePermissionClick}
                            />
                        </div>

                        <div className={classes.newRole}>
                            <Button variant="outlined" color="primary" onClick={this.handleCreateNewRoleClickOpen}>
                                <AddCircleIcon className={classes.addButton}/>  Create New Role
                            </Button>
                        </div>

                        {
                            this.state.adminRoles.map((role) =>

                                <div className={classes.card} key={"roleId" + role.id}>
                                    <OutlinedCard
                                        handleRemoveUserFromRoleClick={this.handleRemoveUserFromRoleClick}
                                        handleDeleteRoleClick={this.handleDeleteRoleClick}
                                        handleAddUserToRoleClick={this.handleAddUserToRoleClick}
                                        handleRemovePermissionFromRoleClick={this.handleRemovePermissionFromRoleClick}
                                        handleAddPermissionToRoleClick={this.handleAddPermissionToRoleClick}
                                        roleId={role.id}
                                        title={role.role}
                                        description={role.description}
                                        users={role.users}
                                        permissions={role.permissions}
                                        employees={this.state.employees}
                                        allPermissions={this.state.permissions}
                                    />
                                </div>
                            )
                        }
                    </div> }

                    <Snackbar open={this.state.isSnackBarOpen} autoHideDuration={2000} onClose={this.handleSnackBarClose}>
                        <Alert onClose={this.handleSnackBarClose} severity={this.state.severity}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>

                    <NewRoleDialog
                        handleCreateNewRoleClick={this.handleCreateNewRoleClick}
                        handleCreateNewRoleClickClose={this.handleCreateNewRoleClickClose}
                        handleRoleDescriptionChange={this.handleRoleDescriptionChange}
                        handleRoleNameChange={this.handleRoleNameChange}
                        newRoleDescription={this.state.newRoleDescription}
                        newRoleName={this.state.newRoleName}
                        open={this.state.addNewRoleDialogOpen}

                    />
                    <br /><br /><br /><br />
                </Paper>
            </ResponsiveContainer>
        );
    }
}

export default (withStyles(AdminRoleStyles)(AdminRoles));
