import React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Route, Switch, withRouter} from 'react-router-dom';
import Base from './Base';
import NewUsers from './NewUsers';
import AllUsers from './AllUsers';
import UpdateEmail from './UpdateEmail';
import {SideBarMenu} from './SideBarMenu';
import SingleUser from './SingleUser';
import DailyMetrics from './DailyMetrics';
import MiscMetrics from './Metrics';
import API from './ApiInterface';
import SignInPage from './SignIn';
import Profile from './Profile';
import {UserSideBarMenu} from './UserSideBarMenu';
import PlaceHolder from './PlaceHolder';
import ResetPassword from "./ResetPassword";
import CreateAccount from "./CreateAccount";
import AdminRoles from "./AdminRoles";
import {changeCode, changeEmail, changeFirstName, changeLastName, changePassword} from "./common/AccountCreationResources";
import Password from "./Password";
import {DashboardStyles} from "./styles/DashboardStyles";
import jwt_decode from "jwt-decode";
import UpdateEmailButton from "./UpdateEmailButton";

class Dashboard extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            type: null, // Can be office or user
            user: null,
            loggedIn: false,
            // type: "Office",
            // loggedIn: true,
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            userInfo: null,
            open: false,
            view: "Dashboard",
            searchParams: {
                firstName: "aa",
                lastName: "",
                email: "",
            },
            helperCodeText: "",
            codeError: false,
            code: "",
            readyForCode: false,
            readyForAccountCode: false,
            sport: "Baseball",
            role: "Fan",
            level: "Recreation",
            showPassword: false,
            helperPasswordText: "8 characters long with one number and one uppercase",
            emailError: false,
            passwordError: false,
            readyForPassword: false,
            timer: 0, // boolean timer running or not
            secondsUntilRefreshTokenNeeded: 0,
        };

        this.handleNewUsersClick.bind(this);
        this.handlePeopleClick.bind(this);
        this.handleDailyMetricsClick.bind(this);
        this.handleMiscMetricsClick.bind(this);
        this.handlePlaceHolderClick.bind(this);
        this.handleAdminRolesClick.bind(this);
        this.handleUpdateEmailClick = this.handleUpdateEmailClick.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCreateAccountSubmit = this.handleCreateAccountSubmit.bind(this);
        this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
        this.handleConfirmCodeSubmit = this.handleConfirmCodeSubmit.bind(this);
        this.handleConfirmCodeResetSubmit = this.handleConfirmCodeResetSubmit.bind(this);
        this.handleNewPasswordSubmit = this.handleNewPasswordSubmit.bind(this);
        this.changeCode = changeCode.bind(this);
        this.changeFirstName = changeFirstName.bind(this);
        this.changeLastName = changeLastName.bind(this);
        this.changeEmail = changeEmail.bind(this);
        this.changePassword = changePassword.bind(this);
        this.handleProfileClick = this.handleProfileClick.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
    }

    /************************************ BUTTON CLICKS ************************************************/

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    handleNewUsersClick = () => {
        this.props.history.push({
            pathname: '/newusers',
            state: {
                token: this.state.userInfo.token,
            }
        });
        this.setState({ open: false, view: "New Users Query" });
    };

    handleDailyMetricsClick = () => {
        this.props.history.push({
            pathname: '/daily',
            state: {
                token: this.state.userInfo.token,
            }
        });
        this.setState({ open: false, view: "Daily Metrics" });
    };

    handleMiscMetricsClick = () => {
        this.props.history.push({
            pathname: '/metrics',
            state: {
                token: this.state.userInfo.token,
            }
        });
        this.setState({ open: false, view: "Misc. Metrics" });
    };

    handlePeopleClick = () => {
       this.props.history.push({
            pathname: '/users',
            state: {
                searchParams: this.state.searchParams,
                userInfo: this.state.userInfo,
            }
        });
        this.setState({ open: false, view: "All Users" });
    };

    handleUpdateEmailClick = () => {
        this.props.history.push({
            pathname: '/update-emails',
            state: {
                searchParams: this.state.searchParams,
                userInfo: this.state.userInfo,
            }
        });
        this.setState({ open: false, view: "Change Customer Email" });
    };

    // handleProfileClick = () => {
    //     this.props.history.push('/index');
    //     this.setState({ open: false, view: "Profile" });
    // };

    handlePlaceHolderClick = () => {
        this.props.history.push('/placeholder');
        // this.props.history.push('/placeholder/' + this.state.userInfo.user.userId.toString());
        this.setState({ open: false, view: "Place Holder" });
    };

    handleAdminRolesClick = () => {
        this.props.history.push({
            pathname: '/admin-roles',
            state: {
                token: this.state.userInfo.token,
            }
        });
        this.setState({ open: false, view: "Admin Role Management" });
    };

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        });
    };

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        });
    };

    handleHomeClick = () => {
        this.props.history.push('/index');
        this.setState({ open: false, view: "Dashboard" });
    };

    handleProfileClick = () => {
        API.histories(this.state.userInfo.token).then(result => {
            this.setState({
                velocities: result,
            });
        }).then(result => {
            this.props.history.push('/profile');
            this.getProfile();
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    };

    handleCreateAccountSubmit() {
        if (this.state.passwordError === false && this.state) {
            API.signUp(this.state.firstName, this.state.lastName, this.state.email, this.state.password).then(result => {
                if (result["error"] === false) {
                    this.setState({
                        readyForCode: true,
                    });
                } else {
                    // that email might already be registered
                    this.setState({
                        error: "Error: " + result["message"].text,
                    });
                    alert("Error: " + result["message"]);
                }
            }).catch((error) => {
                this.setState({
                    error: error,
                });
            });
        }
    }

    handleEmailSubmit () {
        API.forgotPassword(this.state.email).then(result => {
            if (result["error"] === false) {
                this.setState({
                    readyForCode: true,
                });
            } else {
                // that email is already registered

                if (result.confirmed === 0) {
                    // if the account was never confirmed, send them to code verification
                    this.setState({
                        readyForAccountCode: true,
                    });
                } else {
                    // Otherwise there was some other error, display the message
                    this.setState({
                        emailError: true,
                    });
                    alert("Error: " + result["message"].text);
                }
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    }

    handleConfirmCodeSubmit() {
        API.confirmAccount(this.state.email, this.state.code).then(result => {
            if (result["error"] === false) {

                let domain = this.state.email.slice(this.state.email.indexOf("@"));
                if (domain === "@pocketradar.com" ) {
                    this.setState({
                        loggedIn: true,
                        type: "Office",
                        userInfo: result,
                        readyForCode: false,
                        email: "",
                        code: "",
                    });
                    this.getDashboard();
                } else {
                    // uncomment the following lines if we want to expose the user side of the dashboard
                    this.setState({
                        codeError: true,
                        // loggedIn: true,
                        // type: "User",
                        // userInfo: result,
                        // readyForCode: false,
                        // email: "",
                        // code: "",
                    });
                    // this.getDashboard();
                }
            } else {
                this.setState({
                    error: "Error: " + result["message"].text,
                });
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    }

    handleConfirmCodeResetSubmit() {
        API.forgotPasswordVerify(this.state.email, this.state.code).then(result => {
            if (result["error"] === false) {
                this.setState({
                    readyForCode: false,
                    readyForPassword: true,
                });
                this.getPassword();
            } else {
                this.setState({
                    codeError: true,
                });
                alert("Error: Please provide a valid code");
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    }

    handleNewPasswordSubmit() {
        if(this.state.passwordError === false) {
            API.resetPassword(this.state.email, this.state.code, this.state.password).then(result => {
                if (result["error"] === false) {
                    let domain = this.state.email.slice(this.state.email.indexOf("@"));
                    if (domain === "@pocketradar.com" ) {

                        this.setState({
                            loggedIn: true,
                            userInfo: result,
                            readyForCode: false,
                            readyForPassword: false,
                            code: "",
                            email: "",
                            password: "",
                        });
                        this.getDashboard();
                    } else {
                        // uncomment the following lines if we want to expose the user side of the dashboard
                        this.setState({
                            loggedIn: false,
                            // loggedIn: true,
                            // type: "User",
                            // userInfo: result,
                            // readyForCode: false,
                            // email: "",
                            // code: "",
                            // password: "",
                        });
                        // this.getDashboard();
                    }
                } else {
                    this.setState({
                        error: "Error: " + result["message"].text,
                    });
                }
            }).catch((error) => {
                this.setState({
                    error: error,
                });
            });
        }
    }

    handleSubmit() {
        API.login(this.state.email, this.state.password).then(result => {
            if (result.access_token !== undefined) {
                const decodedToken = jwt_decode(result.access_token);

                this.setState({
                    userInfo: {
                        token: result.access_token,
                        refreshToken: result.refresh_token,
                        expiresIn: result.expires_in,
                        userId: result.userId,
                        permissions: decodedToken.permissions,
                    },
                });

                if (decodedToken.permissions.includes("DA")) {
                    API.getUserProfile(this.state.userInfo.token, this.state.userInfo.userId).then(result => {
                        this.setState({
                            userInfo: {
                                ...this.state.userInfo,
                                user: result,
                            },
                            type: "Office",
                            loggedIn: true,
                        });
                        this.startTimer(this.state.userInfo.expiresIn/1.5);
                        this.getDashboard();

                        this.handlePeopleClick();
                    }).catch((error) => {
                        this.setState({
                            error: error,
                        });
                        alert("Error: " + error);
                    });
                } else {
                    alert("Error: You are not authorized to access the dashbaord, please contact one of the administrators for access");
                }
            } else {
                alert("Error: Please provide a valid email and/or password");
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
            alert("Error" + error);
        });
    };

    getDashboard = () => {
        return <div>
            <Base
                userInfo={this.state.userInfo}
            />
        </div>
    };

    /************************************ Timer ************************************************/

    startTimer(timeOut) {
        this.setState({
            secondsUntilRefreshTokenNeeded: timeOut,
            timer: setTimeout(this.refreshToken, timeOut * 1000),
        });
    }

    refreshToken() {
        API.refreshToken(this.state.userInfo.refreshToken).then(result => {
            if (result.access_token !== undefined) {
                const decodedToken = jwt_decode(result.access_token);

                if (decodedToken.permissions.includes("DA")) {
                    this.setState({
                        userInfo: {
                            ...this.state.userInfo,
                            token: result.access_token,
                            refreshToken: result.refresh_token,
                            expiresIn: result.expires_in,
                            userId: result.userId,
                            permissions: decodedToken.permissions,
                        }
                    });

                    this.startTimer(this.state.userInfo.expiresIn / 1.5);
                    this.getDashboard();
                } else {
                    this.props.history.push('/signin');
                    this.setState({
                        loggedIn: false,
                        type: null,
                    });
                }
            } else {
                this.props.history.push('/signin');
                this.setState({
                    loggedIn: false,
                    type: null,
                });
            }
        }).catch((error) => {
            this.setState({
                error: error,
            });
            alert("Error: " + error);
        });
    }

    /************************************ Profile ************************************************/

    getProfile = () => {
        return<div>
            <Profile
                user={this.state.userInfo}
                velocities={this.state.velocities}
            />
        </div>
    };

    /************************************ Placeholder ************************************************/

    getPlaceHolder = () => {
        return<div>
            <PlaceHolder
                // userData={this.state.userData}
            />
        </div>
    };

    /************************************ Sign In ************************************************/

    getSignIn = () => {
        return<div>
            <SignInPage
                handleEmailChange={this.handleEmailChange}
                handlePasswordChange={this.handlePasswordChange}
                handleSubmit={this.handleSubmit}
                handleCreateAccountSubmit={this.handleCreateAccountSubmit}
                handleConfirmCodeSubmit={this.handleConfirmCodeSubmit}
            />
        </div>
    };

    /************************************ Create Account ************************************************/

    getCreateAccount = () => {
        return<div>
            <CreateAccount
                handleCreateAccountSubmit={this.handleCreateAccountSubmit}
                handleConfirmCodeSubmit={this.handleConfirmCodeSubmit}
                changeFirstName={this.changeFirstName}
                changeLastName={this.changeLastName}
                changeEmail={this.changeEmail}
                changeCode={this.changeCode}
                changePassword={this.changePassword}
                firstName={this.state.firstName}
                lastName={this.state.lastName}
                email={this.state.email}
                password={this.state.password}
                code={this.state.code}
                codeError={this.state.codeError}
                helperCodeText={this.state.helperCodeText}
                readyForCode={this.state.readyForCode}
                passwordError={this.state.passwordError}
                helperPasswordText={this.state.helperPasswordText}
            />
        </div>
    };

    /************************************ Reset Password ************************************************/

    getResetPassword = () => {
        return<div>
            <ResetPassword
                changeEmail={this.changeEmail}
                changePassword={this.changePassword}
                handleEmailSubmit={this.handleEmailSubmit}
                handleConfirmCodeSubmit={this.handleConfirmCodeResetSubmit}
                handleConfirmAccountCodeSubmit={this.handleConfirmCodeSubmit}
                handleNewPasswordSubmit={this.handleNewPasswordSubmit}
                changeCode={this.changeCode}
                code={this.state.code}
                codeError={this.state.codeError}
                helperCodeText={this.state.helperCodeText}
                readyForPassword={this.state.readyForPassword}
                readyForCode={this.state.readyForCode}
                readyForAccountCode={this.state.readyForAccountCode}
                helperPasswordText={this.state.helperPasswordText}
                passwordError={this.state.passwordError}
                emailError={this.state.emailError}
            />
        </div>
    };

    /************************************ New Password ************************************************/

    getPassword = () => {
        return<div>
            <Password
                changePassword={this.changePassword}
                handleConfirmCodeResetSubmit={this.handleConfirmCodeResetSubmit}
                passwordError={this.state.passwordError}
                helperPasswordText={this.state.helperPasswordText}
            />
        </div>
    };

    /************************************ RENDER ************************************************/

    render() {
        const {classes} = this.props;

        if (this.state.loggedIn === false) {
            return (
                <Switch>
                    <Route exact path="/signin" component={ this.getSignIn }/>
                    <Route path="/create-account" component={ this.getCreateAccount }/>
                    <Route path="/reset-password" component={ this.getResetPassword }/>
                </Switch>
            );
        } else if (this.state.type === "Office") {
            return (
                <div className={classes.root}>
                    <CssBaseline/>

                    {/******************************  TOP BAR  ******************************************/}

                    <AppBar
                        position="absolute"
                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>

                            {
                                // Three Bar Side Menu Button
                            }
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden,
                                )}
                            >
                                <MenuIcon/>
                            </IconButton>

                            {
                                // Top Menu Bar Title
                            }
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                {this.state.view}
                            </Typography>

                            <IconButton color="inherit" onClick={this.handleProfileClick}>
                                <AccountCircleIcon/>
                            </IconButton>
                            <Typography onClick={() => {
                                this.props.history.push('/signin');
                                this.setState({
                                    loggedIn: false,
                                    type: null,
                                });
                            }}>
                                Sign Out ({this.state.type})
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {/******************************  SIDE BAR  *****************************************/}

                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        {
                            // Close Drawer Button
                        }
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider component={"hr"}/>

                        {
                            // Side Bar Button List Items
                        }
                        <List component="nav">
                            <SideBarMenu
                                handleHomeClick={this.handleHomeClick}
                                handleNewUsersClick={this.handleNewUsersClick}
                                handlePeopleClick={this.handlePeopleClick}
                                handleDailyMetricsClick={this.handleDailyMetricsClick}
                                handleMiscMetricsClick={this.handleMiscMetricsClick}
                                handleAdminRolesClick={this.handleAdminRolesClick}
                                handleUpdateEmailClick={this.handleUpdateEmailClick}
                                permissions={this.state.userInfo.permissions}
                            />
                        </List>
                    </Drawer>

                    {/******************************  CONTENT  ******************************************/}

                    <main className={classes.content}>

                        <div className={classes.appBarSpacer}/>

                        <Switch>
                            <Route exact path="/index" component={this.getDashboard}/>
                            <Route path="/users" component={AllUsers}/>
                            <Route path="/user/:userID" component={SingleUser}/>
                            <Route path="/newusers" component={NewUsers}/>
                            <Route path="/daily" component={DailyMetrics}/>
                            <Route path="/metrics" component={MiscMetrics}/>
                            <Route path="/admin-roles" component={AdminRoles}/>
                            <Route path="/update-emails" component={UpdateEmail}/>
                            <Route path="/update-email/:userID" component={UpdateEmailButton}/>
                            <Route path="/profile" component={this.getProfile}/>
                        </Switch>
                    </main>
                </div>
            );
        } else {
            // regular user
            return (
                <div className={classes.root}>
                    <CssBaseline/>

                    {/******************************  TOP BAR  ******************************************/}

                    <AppBar
                        position="absolute"
                        className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    >
                        <Toolbar disableGutters={!this.state.open} className={classes.userToolbar}>

                            {/*Three Bar Side Menu Button*/}
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(
                                    classes.menuButton,
                                    this.state.open && classes.menuButtonHidden,
                                )}>

                                <MenuIcon/>
                            </IconButton>

                            {/*Top Menu Bar Title*/}
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}
                            >
                                {this.state.view}
                            </Typography>

                            {/*Notification Bell Button*/}
                            <IconButton color="inherit">
                                <AccountCircleIcon onClick={this.handleProfileClick}/>
                            </IconButton>
                            <Typography onClick={() => {
                                this.props.history.push('/signin');
                                this.setState({
                                    loggedIn: false,
                                    type: null,
                                });
                            }}>
                                Sign Out ({this.state.type})
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    {/******************************  USER SIDE BAR  *****************************************/}

                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        {/*********** User Close Drawer Button **********/}
                        <div className={classes.toolbarIcon}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                        <Divider component={"hr"}/>

                        {/******* User Side Bar Button List Items **********/}

                        <List component="nav">
                            <UserSideBarMenu
                                handleHomeClick={this.handleHomeClick}
                                handlePlaceHolderClick={this.handlePlaceHolderClick}
                            />
                        </List>
                    </Drawer>

                    {/******************************  CONTENT  ******************************************/}

                    <main className={classes.content}>
                        <div className={classes.appBarSpacer}/>

                        <Switch>
                            <Route exact path="/index" component={ this.getDashboard }/>
                            <Route path="/placeholder" component={ this.getPlaceHolder }/>
                        </Switch>
                    </main>
                </div>
            );
        }
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(DashboardStyles)(Dashboard));
