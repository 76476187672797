import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Code from "./Code";
import * as PropTypes from "prop-types";
import {signInstyles} from "./SignIn-SignUp-Code";

let showPassword = false;

class CreateAccount extends Component {

    static propTypes = {
        handleCreateAccountSubmit: PropTypes.func.isRequired,
        handleConfirmCodeSubmit: PropTypes.func.isRequired,
        changeFirstName: PropTypes.func.isRequired,
        changeLastName: PropTypes.func.isRequired,
        changeEmail: PropTypes.func.isRequired,
        changeCode: PropTypes.func.isRequired,
        changePassword: PropTypes.func.isRequired,
        code: PropTypes.string.isRequired,
        codeError: PropTypes.bool.isRequired,
        helperCodeText: PropTypes.string.isRequired,
        readyForCode: PropTypes.bool.isRequired,
        passwordError: PropTypes.bool.isRequired,
        helperPasswordText: PropTypes.string.isRequired,
    };

    handleClickShowPassword = () => {
        showPassword = !showPassword;
    };

    render() {
        const {
            classes,
            handleCreateAccountSubmit,
            changeFirstName,
            changeLastName,
            changeEmail,
            changePassword,
            readyForCode,
            lastName,
            passwordError,
            helperPasswordText,
        } = this.props;

        return (
            <div>
                {(!readyForCode) &&
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <AccountBoxIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Create An Account
                        </Typography>
                        <p>Sport will be set to 'Baseball'. Role will be set to 'Fan'. Level will be set to
                            'Other' </p>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="filled-name"
                                    label="First Name"
                                    required
                                    className={classes.textField}
                                    onChange={changeFirstName}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="filled-name"
                                    label="Last Name"
                                    required
                                    className={classes.textField}
                                    value={lastName}
                                    onChange={changeLastName}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    onChange={changeEmail}
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="password"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={passwordError}
                                    helperText={helperPasswordText}
                                    onChange={changePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    autoComplete='new-password'
                                />
                            </Grid>
                        </Grid>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                handleCreateAccountSubmit();
                            }}
                        >
                            Sign in
                        </Button>

                        <br/>
                        <Grid container justify="flex-start">
                            <Grid item>
                                <Link href="/signin" variant="body2">{"Already Have an Account? Sign In"}</Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </main>
                }
                {(readyForCode) && <div>
                    <Code
                        submit={this.props.handleConfirmCodeSubmit}
                        codeError={this.props.codeError}
                        changeCode={this.props.changeCode}
                        helperCodeText={this.props.helperCodeText}
                        classes={classes}
                    />
                </div>
                }
            </div>
        );
    }
}

export default withStyles(signInstyles)(CreateAccount);
