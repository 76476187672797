import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core';
import {
    Area,
    AreaChart,
    Bar,
    BarChart,
    Brush,
    CartesianGrid,
    Cell,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import './styles/flexgrid.css';
import API from './ApiInterface';
import CircularDeterminate from "./LoadingCircle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fade from "@material-ui/core/Fade";
import LabelList from "recharts/lib/component/LabelList";
import {_addWeekNameAndIndex, _checkDates, _fixDates, _removeTimeFromDate, today,} from './common/DateResources';
import DateVelConfirmedSearch from "./SearchBars/DateVelConfirmedSearch";
import DateAndVelocitySearch from "./SearchBars/DateAndVelocitySearch";
import SingleUserTable from "./SingleUserTable";
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';
import {
    appleDeviceTypeColor,
    genderColors,
    handColors,
    phoneColors,
    shadesOfBlueAndGreen,
    sportColors,
    sportTagColors,
    sportTagUserColors,
    theySayItsGreen,
    newAccountColors,
    velocityColors,
    videoColors,
} from './styles/Colors';
import MyPieChart from "./Pie";
import {_hardFixBadDB, _numberWithCommas, _simplifyDataForCharting, _softFixBadDB} from "./common/sanitizeData";
import {BootstrapButton} from "./SubmitButton";
import PeriodSearch from "./PeriodSearch";
import PeriodConfirmedSearch from "./SearchBars/PeriodConfirmedSearch";
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ScrollTop from './ScrollTop';
import {MetricStyles} from "./styles/MetricStyles";

const CustomTooltip = props => {
    if (props.payload[0] != null) {
       const newPayload = [
            {
                name: props.payload[0].payload.title,
                value: props.payload[0].payload.displayValue,
            },
        ];

        return <DefaultTooltipContent {...props} payload={newPayload} />;
    }

    return <DefaultTooltipContent {...props} />;
};

class Metrics extends Component {
    constructor(props) {
        super(props);

        this.handleVelocitiesAndVideosSubmit = this.handleVelocitiesAndVideosSubmit.bind(this);
        this.handleNewAccountsPerPeriodSubmit = this.handleNewAccountsPerPeriodSubmit.bind(this);
        this.handleUsersByAgeGroupsSubmit = this.handleUsersByAgeGroupsSubmit.bind(this);
        this.handleUsersByHSGradYearsSubmit = this.handleUsersByHSGradYearsSubmit.bind(this);
        this.handleAccountUpdatesPerPeriodSubmit = this.handleAccountUpdatesPerPeriodSubmit.bind(this);
        this.getGeneralTotals = this.getGeneralTotals.bind(this);
        this.handleLevelSubmit = this.handleLevelSubmit.bind(this);
        this.handleRoleSubmit = this.handleRoleSubmit.bind(this);
        this.handleSportSubmit = this.handleSportSubmit.bind(this);
        this.handleGenderSubmit = this.handleGenderSubmit.bind(this);
        this.handleHandedSubmit = this.handleHandedSubmit.bind(this);
        this.handleSportTagsSubmit = this.handleSportTagsSubmit.bind(this);
        this.handleSportTagUsersSubmit = this.handleSportTagUsersSubmit.bind(this);
        this.handleDeviceSubmit = this.handleDeviceSubmit.bind(this);
        this.handleAppleDeviceTypeSubmit = this.handleAppleDeviceTypeSubmit.bind(this);
        this.closeAll = this.closeAll.bind(this);

        this.state = {
            token: this.props.location.state.token,
            defaultSelectedStartDate: '2018-06-20',
            defaultSelectedEndDate: today,
            startDateVelocitiesAndVideosPerPeriod: '2018-06-20',
            endDateVelocitiesAndVideosPerPeriod: today,
            startDateAccountUpdatesPerPeriod: '2018-06-20',
            endDateAccountUpdatesPerPeriod: today,
            startDateRoles: '2018-06-20',
            endDateRoles: today,
            startDateSports: '2018-06-20',
            endDateSports: today,
            startDateGender: '2018-06-20',
            endDateGender: today,
            startDateHanded: '2018-06-20',
            endDateHanded: today,
            startDateLevel: '2018-06-20',
            endDateLevel: today,
            startDateDevices: '2018-06-20',
            endDateDevices: today,
            startDateAppleDevice: '2018-06-20',
            endDateAppleDevice: today,
            startDateSportTagUsers: '2018-06-20',
            endDateSportTagUsers: today,
            startDateSportTag: '2018-06-20',
            endDateSportTag: today,
            startDateGeneralTotals: '2018-06-20',
            endDateGeneralTotals: today,
            startDateUsersByAgeGroups: '2018-06-20',
            endDateUsersByAgeGroups: today,
            startDateHSGradYears: '2018-06-20',
            endDateHSGradYears: today,
            singleDataPoints: [{
                avgerageVelocitiesPerUser: 0,
                numberOfPhoneNumbers: 0,
                averageAge: 0,
            }],
            generalTotals: [
                {
                    name: "numberOfTotalEmails",
                    title: "Total Accounts",
                    shortTitle: "Total Accounts",
                    displayValue: "0",
                    value: 0,
                    color: "#503D42",
                },
                {
                    name: "numberOfConfirmedEmails",
                    title: "Confirmed Accounts",
                    shortTitle: "Confirmed",
                    displayValue: "0",
                    value: 0,
                    color: "#4B6858",
                },
                {
                    name: "numberOfUsersWithVelocities",
                    title: "Number of Users Who Have 1+ Velocity",
                    shortTitle: "> 1 Velocity",
                    displayValue: "0",
                    value: 0,
                    color: "#46ACC2",
                },
                {
                    name: "numberOfUsersWithVideos",
                    title: "Number of Users Who Have 1+ Video",
                    shortTitle: "> 1 Video",
                    displayValue: "0",
                    value: 0,
                    color: "#B1740F",
                },
                {
                    name: "numberOfUsersWithNoCustomSports",
                    title: "Number of Users with No Custom Sports",
                    shortTitle: "No Custom Sports",
                    displayValue: "0",
                    value: 0,
                    color: "#471323",
                },
                {
                    name: "numberOfUsersWithNoCustomActvities",
                    title: "Number of Users with No Custom Activities",
                    shortTitle: "No Custom Activities",
                    displayValue: "0",
                    value: 0,
                    color: "#6369D1",
                },
            ],
            usersByAgeGroups: this._getDefaultData(),
            usersByHSGradYears: this._getDefaultData(),
            velocitiesAndVideosPerPeriod: this._getDefaultData(),
            newAccountsPerPeriod: this._getDefaultData(),
            accountUpdatesPerPeriod: this._getDefaultData(),
            newAccountsByDOW: null,
            errorNewAccountDOW: null,
            velocitiesByDOW: null,
            errorVelocitiesDOW: null,
            videosByDOW: null,
            errorVideosDOW: null,
            levels: this._getDefaultData(),
            errorLevels: null,
            roles: this._getDefaultData(),
            errorRoles: null,
            genders: this._getDefaultData(),
            errorGenders: null,
            handed: this._getDefaultData(),
            errorHanded: null,
            sports: this._getDefaultData(),
            errorSports: null,
            sportTags: this._getDefaultData(),
            errorSportTags: null,
            sportTagUsers: this._getDefaultData(),
            errorSportTagUsers: null,
            devices: this._getDefaultData(),
            errorDevices: null,
            appleDeviceType: this._getDefaultData(),
            originalLevels: null,
            originalRoles: null,
            originalGender: null,
            originalHanded: null,
            originalSports: null,
            originalSportTags: null,
            originalSportTagUsers: null,
            sportsTagChecked: false,
            sportsTagUsersChecked: false,
            deviceChecked: false,
            generalTotalsChecked: false,
            accountUpdatesPerPeriodChecked: false,
            velocitiesAndVideosPerPeriodChecked: false,
            usersByHSGradYearsChecked: false,
            newAccountsPerPeriodChecked: false,
            levelsChecked: false,
            rolesChecked: false,
            sportsChecked: false,
            handedChecked: false,
            genderChecked: false,
            appleDeviceTypeChecked: false,
            usersByAgeGroupsChecked: false,
            newAccountsByDOWChecked: false,
            velocitiesByDOWChecked: false,
            videosByDOWChecked: false,
            periodVelocitiesAndVideosPerPeriod: "month",
            periodAccountUpdatesPerPeriod: "month",
            periodNewAccountsPerPeriod: "month",
            isConfirmedNewAccountsPerPeriod: false,
            isConfirmedRoles: false,
            isConfirmedSports: false,
            isConfirmedLevel: false,
            isConfirmedCountHSGradYears: false,
            velocityCountRoles: 0,
            velocityCountSports: 0,
            velocityCountGender: 0,
            velocityCountHanded: 0,
            velocityCountLevel: 0,
            velocityCountDevices: 0,
            velocityCountAppleDevice: 0,
            velocityCountSportTagUsers: 1,
            velocityCountSportTag: 1,
            velocityCountGeneralTotals: 0,
            velocityCountUsersByAgeGroups: 0,
            velocityCountHSGradYears: 0,
            errorGeneralTotals: false,
        }
    }

    _getDefaultData() {
        return {
            error: false,
            count: 0,
            result: [],
        }
    }

    closeAll() {
        this.setState({
            sportsTagChecked: false,
            sportsTagUsersChecked: false,
            deviceChecked: false,
            generalTotalsChecked: false,
            accountUpdatesPerPeriodChecked: false,
            velocitiesAndVideosPerPeriodChecked: false,
            usersByHSGradYearsChecked: false,
            newAccountsPerPeriodChecked: false,
            levelsChecked: false,
            rolesChecked: false,
            sportsChecked: false,
            handedChecked: false,
            genderChecked: false,
            appleDeviceTypeChecked: false,
            usersByAgeGroupsChecked: false,
            newAccountsByDOWChecked: false,
            velocitiesByDOWChecked: false,
            videosByDOWChecked: false,
        });
    }

    getAverageVelocityCount(accountDateStart, accountDateEnd) {
        API.getAverageVelocityCount(this.state.token, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {

                let singleDataPoints = JSON.parse(JSON.stringify(this.state.singleDataPoints));
                singleDataPoints[0].avgerageVelocitiesPerUser =  _numberWithCommas(result.result.averageVelocitiesPerUser);

                this.setState({
                    singleDataPoints: singleDataPoints,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getAverageVelocityCount",
                }
            });
        });
    }

    getNumberOfPhoneNumbers(accountDateStart, accountDateEnd) {
        API.getNumberOfPhoneNumbers(this.state.token, 0, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {

                let singleDataPoints = JSON.parse(JSON.stringify(this.state.singleDataPoints));
                singleDataPoints[0].numberOfPhoneNumbers = _numberWithCommas(result.result.value);

                this.setState({
                    singleDataPoints: singleDataPoints,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getNumberOfPhoneNumbers",
                }
            });
        });
    }

    getAverageAccountAge(accountDateStart, accountDateEnd) {
      API.getAverageAccountAge(this.state.token, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {

                let singleDataPoints = JSON.parse(JSON.stringify(this.state.singleDataPoints));
                singleDataPoints[0].averageAge =  _numberWithCommas(result.result[0].averageAccountAge);

                this.setState({
                    singleDataPoints: singleDataPoints,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getAverageAccountAge",
                }
            });
        });
    }

    getNumberOfAccounts(accountDateStart, accountDateEnd) {
      API.getNumberOfAccounts(this.state.token, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                let generalTotals = JSON.parse(JSON.stringify(this.state.generalTotals));

                generalTotals.find(item => item.name === 'numberOfTotalEmails').value = result.result.totalAccounts;
                generalTotals.find(item => item.name === 'numberOfTotalEmails').displayValue = _numberWithCommas(result.result.totalAccounts);
                generalTotals.find(item => item.name === 'numberOfConfirmedEmails').value = result.result.confirmedAccounts;
                generalTotals.find(item => item.name === 'numberOfConfirmedEmails').displayValue = _numberWithCommas(result.result.confirmedAccounts);

                this.setState({
                    generalTotals: generalTotals,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getNumberOfAccounts",
                }
            });
        });
    }

    getUsersWithVelocitiesCount(accountDateStart, accountDateEnd, velocityCount) {
        API.getUsersWithVelocitiesCount(this.state.token, accountDateStart, accountDateEnd, velocityCount).then(result => {
            if (result.error === false) {

                let generalTotals = JSON.parse(JSON.stringify(this.state.generalTotals));
                generalTotals.find(item => item.name === 'numberOfUsersWithVelocities').value = result.result.value;
                generalTotals.find(item => item.name === 'numberOfUsersWithVelocities').displayValue = _numberWithCommas(result.result.value);

                this.setState({
                    generalTotals: generalTotals,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getUsersWithVelocitiesCount",
                }
            });
        });
    }

    getUsersWithVideosCount(accountDateStart, accountDateEnd, velocityCount) {
       API.getUsersWithVideosCount(this.state.token, accountDateStart, accountDateEnd, velocityCount).then(result => {
            if (result.error === false) {

                let generalTotals = JSON.parse(JSON.stringify(this.state.generalTotals));
                generalTotals.find(item => item.name === 'numberOfUsersWithVideos').value = result.result.value;
                generalTotals.find(item => item.name === 'numberOfUsersWithVideos').displayValue = _numberWithCommas(result.result.value);

                // generalTotals.numberOfUsersWithVideos.value = _numberWithCommas(result.result.value);

                this.setState({
                    generalTotals: generalTotals,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getUsersWithVideosCount",
                }
            });
        });
    }

    getUserCountWithNoCustomSports(accountDateStart, accountDateEnd) {
        API.getUserCountWithNoCustomSports(this.state.token, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {

                let generalTotals = JSON.parse(JSON.stringify(this.state.generalTotals));
                generalTotals.find(item => item.name === 'numberOfUsersWithNoCustomSports').value = result.result.usersWithNoCustomSports;
                generalTotals.find(item => item.name === 'numberOfUsersWithNoCustomSports').displayValue = _numberWithCommas(result.result.usersWithNoCustomSports);

                this.setState({
                    generalTotals: generalTotals,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getUserCountWithNoCustomSports",
                }
            });
        });
    }

    getUserCountWithNoCustomActivities(accountDateStart, accountDateEnd) {
       API.getUserCountWithNoCustomActivities(this.state.token, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {

                let generalTotals = JSON.parse(JSON.stringify(this.state.generalTotals));
                generalTotals.find(item => item.name === 'numberOfUsersWithNoCustomActvities').value = result.result.usersWithNoCustomActivities;
                generalTotals.find(item => item.name === 'numberOfUsersWithNoCustomActvities').displayValue = _numberWithCommas(result.result.usersWithNoCustomActivities);

                this.setState({
                    generalTotals: generalTotals,
                });
            } else {
                this.setState({
                    errorGeneralTotals: {
                        error: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGeneralTotalsSumbit: {
                    error: error,
                    function: "getUserCountWithNoCustomActivities",
                }
            });
        });
    }

    getGeneralTotals(startDate, endDate, velocityCount) {
        startDate = _checkDates(startDate);
        endDate = _checkDates(endDate);

        this.setState({
            errorGeneralTotals: false,
            errorGeneralTotalsSumbit: false,
            startDateGeneralTotals: startDate,
            endDateGeneralTotals: endDate,
            velocityCountGeneralTotals: velocityCount,
        }, () => {
            this.getAverageVelocityCount(startDate, endDate);
            this.getNumberOfPhoneNumbers(startDate, endDate);
            this.getAverageAccountAge(startDate, endDate);
            this.getNumberOfAccounts(startDate, endDate);
            this.getUsersWithVelocitiesCount(startDate, endDate, velocityCount);
            this.getUsersWithVideosCount(startDate, endDate, velocityCount);
            this.getUserCountWithNoCustomSports(startDate, endDate);
            this.getUserCountWithNoCustomActivities(startDate, endDate);
        });
    }

    handleUsersByAgeGroupsSubmit(accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            usersByAgeGroups: this._getDefaultData(),
            startDateUsersByAgeGroups: accountDateStart,
            endDateUsersByAgeGroups: accountDateEnd,
            velocityCountUsersByAgeGroups: velocityCount,
        });

        API.getNumberOfUsersByAgeGroups(this.state.token, velocityCount, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                result.result = _hardFixBadDB(result.result);
                result.result.sort((a, b) => (parseInt(a.name) < parseInt(b.name)) ? -1 : 1);
                const sum = result.result.reduce((a, {value}) => a + value, 0);
                this.setState({
                    usersByAgeGroups: result,
                    totalAgeAccounts: sum,
                });
            } else {
                this.setState({
                    usersByAgeGroups: {
                        error: true,
                        count: null,
                    },
                    errorUsersByAgeGroups: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorUsersByAgeGroups: {
                    message: error,
                },
            });
        });

        API.getNumberOfAccounts(this.state.token, accountDateStart, accountDateEnd).then(result => {
            this.setState({
                totalAccountsByAgeSearch: result.result.confirmedAccounts,
            });
        }).catch((error) => {
            this.setState({
                error: error,
            });
        });
    }

    handleUsersByHSGradYearsSubmit(velocityCount, accountDateStart, accountDateEnd, isConfirmed) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            usersByHSGradYears: this._getDefaultData(),
            startDateHSGradYears: accountDateStart,
            endDateHSGradYears: accountDateEnd,
            velocityCountHSGradYears: velocityCount,
            isConfirmedCountHSGradYears: isConfirmed,
        });

        API.getNumberOfUsersByHSGradYears(this.state.token, velocityCount, accountDateStart, accountDateEnd, isConfirmed).then(result => {
            if (result.error === false) {
                this.setState({
                    usersByHSGradYears: result,
                });
            } else {
                this.setState({
                    usersByHSGradYears: {
                        error: true,
                        count: null,
                    },
                    errorUsersByHSGradYears: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorUsersByHSGradYears: {
                    message: error,
                },
            });
        });
    }

    handleVelocitiesAndVideosSubmit(period) {
        this.setState({
            velocitiesAndVideosPerPeriod: this._getDefaultData(),
            periodVelocitiesAndVideosPerPeriod: period,
            errorVelocitiesAndVideosPerPeriod: null,
        });

        API.getVelocitiesAndVideos(this.state.token, period).then(result => {
            if (result.error === false) {
                result = _fixDates(result);

                let runningVelVidTotals = JSON.parse(JSON.stringify(result.result));

                for (let i = 1; i < result.result.length; i++) {
                    runningVelVidTotals[i].totalVelocities = runningVelVidTotals[i].totalVelocities + runningVelVidTotals[i - 1].totalVelocities;
                    runningVelVidTotals[i].totalVideos = runningVelVidTotals[i].totalVideos + runningVelVidTotals[i - 1].totalVideos;
                }

                this.setState({
                    velocitiesAndVideosPerPeriod: result,
                    cumulativeVelocityAndVideoTotals: runningVelVidTotals,
                });
            } else {
                this.setState({
                    velocitiesAndVideosPerPeriod: {
                        error: true,
                        count: null,
                    },
                    errorVelocitiesAndVideosPerPeriod: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorVelocitiesAndVideosPerPeriod: {
                    message: error,
                },
            });
        });
    }

    handleNewAccountsPerPeriodSubmit(period, isConfirmed) {

        this.setState({
            newAccountsPerPeriod: this._getDefaultData(),
            periodNewAccountsPerPeriod: period,
            isConfirmedNewAccountsPerPeriod: isConfirmed,
            errorNewAccountsPerPeriod: null,
        });

        API.getNewAccountsPerPeriod(this.state.token, period, isConfirmed).then(result => {
            if (result.error === false) {
                result = _removeTimeFromDate(result);

                let runningAccTotals = JSON.parse(JSON.stringify(result.result));

                for (let i = 1; i < result.result.length; i++) {
                    runningAccTotals[i].newUsers = runningAccTotals[i].newUsers + runningAccTotals[i - 1].newUsers;
                }

                this.setState({
                    newAccountsPerPeriod: result,
                    cumulativeNewAccountTotals: runningAccTotals,
                });
            } else {
                this.setState({
                    newAccountsPerPeriod: {
                        error: true,
                        count: null,
                    },
                    errorNewAccountsPerPeriod: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorNewAccountsPerPeriod: {
                    message: error,
                },
            });
        });
    }

    handleAccountUpdatesPerPeriodSubmit(period) {
        this.setState({
            accountUpdatesPerPeriod: this._getDefaultData(),
            periodAccountUpdatesPerPeriod: period,
            errorAccountUpdatesPerPeriod: null,
        });

        API.getAccountUpdatesPerPeriod(this.state.token, period).then(result => {
            if (result.error === false) {
                result = _removeTimeFromDate(result);

                this.setState({
                    accountUpdatesPerPeriod: result,
                });
            } else {
                this.setState({
                    accountUpdatesPerPeriod: {
                        error: true,
                        count: null,
                    },
                    errorAccountUpdatesPerPeriod: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorAccountUpdatesPerPeriod: {
                    message: error,
                },
            });
        });
    }

    // ROLES WITH VELOCITY AND ACCOUNT AGE
    handleRoleSubmit (velocityCount, accountDateStart, accountDateEnd, isConfirmed) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            roles: this._getDefaultData(),
            startDateRoles: accountDateStart,
            endDateRoles: accountDateEnd,
            isConfirmedRoles: isConfirmed,
            velocityCountRoles: velocityCount,
            errorRoles: null,
        });

        API.getRolesWithVelCountByDate(this.state.token, velocityCount, accountDateStart, accountDateEnd, isConfirmed).then(result => {
            if (result.error === false) {
                result = _softFixBadDB(result);
                this.setState({
                    originalRoles: JSON.parse(JSON.stringify(result.result)),
                });

                result = _simplifyDataForCharting(result);

                this.setState({
                    roles: result,
                });
            } else {
                this.setState({
                    roles: {
                        error: true,
                        count: null,
                    },
                    errorRoles: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorRoles: {
                    message: error,
                },
            });
        });
    }

    // SPORTS WITH VELOCITY AND ACCOUNT AGE
    handleSportSubmit (velocityCount, accountDateStart, accountDateEnd, isConfirmed) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            sports: this._getDefaultData(),
            startDateSports: accountDateStart,
            endDateSports: accountDateEnd,
            isConfirmedSports: isConfirmed,
            velocityCountSports: velocityCount,
            errorSports: null,
        });

        API.getSportsWithVelCountByDate(this.state.token, velocityCount, accountDateStart, accountDateEnd, isConfirmed).then(result => {
            if (result.error === false) {
                result = _softFixBadDB(result);

                this.setState({
                    originalSports: JSON.parse(JSON.stringify(result.result)),
                });

                result = _simplifyDataForCharting(result);

                this.setState({
                    sports: result,
                });
            } else {
                this.setState({
                    sports: {
                        error: true,
                        count: null,
                    },
                    errorSports: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorSports: {
                    message: error,
                },
            });
        });
    }

    handleGenderSubmit (accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            genders: this._getDefaultData(),
            startDateGender: accountDateStart,
            endDateGender: accountDateEnd,
            velocityCountGender: velocityCount,
            errorGenders: null,
        });

        API.getGendersWithVelCountByDate(this.state.token, velocityCount, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                result.result = _hardFixBadDB(result.result);

                this.setState({
                    originalGenders: JSON.parse(JSON.stringify(result.result)),
                });

                this.setState({
                    genders: result,
                });
            } else {
                this.setState({
                    genders: {
                        error: true,
                        count: null,
                    },
                    errorGenders: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorGenders: {
                    message: error,
                },
            });
        });
    }

    handleHandedSubmit (accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            handed: this._getDefaultData(),
            startDateHanded: accountDateStart,
            endDateHanded: accountDateEnd,
            velocityCountHanded: velocityCount,
            errorHanded: null,
        });

        API.getHandedWithVelCountByDate(this.state.token, velocityCount, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                result.result = _hardFixBadDB(result.result);

                this.setState({
                    handed: result,
                    originalHanded: JSON.parse(JSON.stringify(result.result)),
                });
            } else {
                this.setState({
                    handed: {
                        error: true,
                        count: null,
                    },
                    errorHanded: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorHanded: {
                    message: error,
                }
            });
        });
    }

    // LEVELS WITH VELOCITY AND ACCOUNT AGE
    handleLevelSubmit (velocityCount, accountDateStart, accountDateEnd, isConfirmed) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            levels: this._getDefaultData(),
            startDateLevel: accountDateStart,
            endDateLevel: accountDateEnd,
            isConfirmedLevel: isConfirmed,
            velocityCountLevel: velocityCount,
            errorLevels: null,
        });

        API.getLevelsWithVelCountByDate(this.state.token, velocityCount, accountDateStart, accountDateEnd, isConfirmed).then(result => {
            if (result.error === false) {
                result = _softFixBadDB(result);

                let originalLevels = JSON.parse(JSON.stringify(result.result));
                this.setState({
                    originalLevels: originalLevels,
                });
                result = _simplifyDataForCharting(result);

                this.setState({
                    levels: result,
                    originalLevels: originalLevels,
                });
            } else {
                this.setState({
                    levels: {
                        error: true,
                        count: null,
                    },
                    errorLevels: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorLevels: {
                    message: error,
                }
            });
        });
    }

    // CELLULAR DEVICES
    handleDeviceSubmit (accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            devices: this._getDefaultData(),
            startDateDevices: accountDateStart,
            endDateDevices: accountDateEnd,
            velocityCountDevices: velocityCount,
            errorDevices: null,
        });

        API.getUsersByDeviceOS(this.state.token, velocityCount, accountDateStart, accountDateEnd, true).then(result => {
            if (result.error === false) {
                this.setState({
                    devices: result,
                });
            } else {
                this.setState({
                    devices: {
                        error: true,
                        count: null,
                    },
                    errorDevices: {
                        message: "Error: " + result.message,
                    },

                });
            }
        }).catch((error) => {
            this.setState({
                errorDevices: {
                    message: error
                },
            });
        });
    }

    // iOS DEVICE TYPES
    handleAppleDeviceTypeSubmit (accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            appleDeviceType: this._getDefaultData(),
            startDateAppleDevice: accountDateStart,
            endDateAppleDevice: accountDateEnd,
            velocityCountAppleDevice: velocityCount,
            errorAppleDeviceType: null,
        });

        API.getUsersByAppleDevice(this.state.token, velocityCount, accountDateStart, accountDateEnd, true).then(result => {
            if (result.error === false) {
                this.setState({
                    appleDeviceType: result,
                });
            } else {
                this.setState({
                    appleDeviceType: {
                        error: true,
                        count: null,
                    },
                    errorAppleDeviceType: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorAppleDeviceType: {
                    message: error
                }
            });
        });
    }

    // USERS WITH SPORT TAGS
    handleSportTagUsersSubmit(accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            sportTagUsers: this._getDefaultData(),
            startDateSportTagUsers: accountDateStart,
            endDateSportTagUsers: accountDateEnd,
            velocityCountSportTagUsers: velocityCount,
            errorSportTagUsers: null,
        });

        API.getSportsTagUserCount(this.state.token, velocityCount, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                result = _softFixBadDB(result);

                this.setState({
                    originalSportTagUsers: JSON.parse(JSON.stringify(result.result)),
                });

                result = _simplifyDataForCharting(result);

                this.setState({
                    sportTagUsers: result,
                });
            } else {
                this.setState({
                    sportTagUsers: {
                        error: true,
                        count: null,
                    },
                    errorSportTagUsers: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorSportTagUsers: {
                    message: error
                }
            });
        });
    }

    // SPORT TAGS ASSOCIATED WITH VELOCITIES
    handleSportTagsSubmit(accountDateStart, accountDateEnd, velocityCount) {
        accountDateStart = _checkDates(accountDateStart);
        accountDateEnd = _checkDates(accountDateEnd);

        this.setState({
            sportTags: this._getDefaultData(),
            startDateSportTag: accountDateStart,
            endDateSportTag: accountDateEnd,
            velocityCountSportTag: velocityCount,
            errorSportTags: null,
        });

        API.getSportTagMetrics(this.state.token, velocityCount, accountDateStart, accountDateEnd).then(result => {
            if (result.error === false) {
                result = _softFixBadDB(result);

                this.setState({
                    originalSportTags: JSON.parse(JSON.stringify(result.result)),
                });

                result = _simplifyDataForCharting(result);

                this.setState({
                    sportTags: result,
                });
            } else {
                this.setState({
                    sportTags: {
                        error: true,
                        count: null,
                    },
                    errorSportTags: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorSportTags: {
                    message: error
                }
            });
        });
    }

    handleAccountsByDOW() {
        API.getTotalAccountsCreatedByDayOfTheWeek(this.state.token).then(result => {
            if (result.error === false) {

                result = _addWeekNameAndIndex(result);

                this.setState({
                    newAccountsByDOW: result.result,
                });
            } else {
                this.setState({
                    errorNewAccountDOW: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorNewAccountDOW: {
                    message: error
                }
            });
        });
    }

    handleVelocitiesByDOW() {
        API.getTotalVelocitiesCreatedByDayOfTheWeek(this.state.token).then(result => {
            if (result.error === false) {
                result = _addWeekNameAndIndex(result);
                this.setState({
                    velocitiesByDOW: result.result,
                });
            } else {
                this.setState({
                    errorVelocitiesDOW: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorVelocitiesDOW: {
                    message: error
                }
            });
        });
    }

    handleVideosByDOW() {
        API.getTotalVideosCreatedByDayOfTheWeek(this.state.token).then(result => {
            if (result.error === false) {

                result = _addWeekNameAndIndex(result);
                this.setState({
                    videosByDOW: result.result,
                });
            } else {
                this.setState({
                    errorVideosDOW: {
                        message: "Error: " + result.message,
                    },
                });
            }
        }).catch((error) => {
            this.setState({
                errorVideosDOW: {
                    message: error
                }
            });
        });
    }

    handleGeneralTotalsChange = () => {
        this.setState({generalTotalsChecked: this.state.generalTotalsChecked !== true});
        if (this.state.generalTotals[0].value === 0) {
            this.getGeneralTotals(this.state.defaultSelectedStartDate, this.state.defaultSelectedEndDate, this.state.velocityCountGeneralTotals);
        }
    };

    handleAccountUpdatesPerPeriodChange = () => {
        this.setState({accountUpdatesPerPeriodChecked: this.state.accountUpdatesPerPeriodChecked !== true});
        if (this.state.accountUpdatesPerPeriod.count === 0) {
            this.handleAccountUpdatesPerPeriodSubmit(this.state.periodAccountUpdatesPerPeriod);
        }
    };

    handleNewAccountsPerPeriodChange = () => {
        this.setState({newAccountsPerPeriodChecked: this.state.newAccountsPerPeriodChecked !== true});
        if (this.state.newAccountsPerPeriod.count === 0) {
            this.handleNewAccountsPerPeriodSubmit(this.state.periodAccountUpdatesPerPeriod, this.state.isConfirmedNewAccountsPerPeriod);
        }
    };

    handleVelocitiesAndVideosPerPeriodChange = () => {
        this.setState({velocitiesAndVideosPerPeriodChecked: this.state.velocitiesAndVideosPerPeriodChecked !== true});
        if (this.state.velocitiesAndVideosPerPeriod.count === 0) {
            this.handleVelocitiesAndVideosSubmit(this.state.periodVelocitiesAndVideosPerPeriod);
        }
    };

    handleUsersByHSGradYearsChange = () => {
        this.setState({usersByHSGradYearsChecked: this.state.usersByHSGradYearsChecked !== true});
        if (this.state.usersByHSGradYears.count === 0) {
            this.handleUsersByHSGradYearsSubmit(this.state.velocityCountHSGradYears, this.state.defaultSelectedStartDate, this.state.defaultSelectedEndDate, this.state.isConfirmedCountHSGradYears);
        }
    };

    handleUsersByAgeGroupsChange = () => {
        this.setState({usersByAgeGroupsChecked: this.state.usersByAgeGroupsChecked !== true});
        if (this.state.usersByAgeGroups.count === 0) {
            this.handleUsersByAgeGroupsSubmit(this.state.startDateUsersByAgeGroups, this.state.endDateUsersByAgeGroups, this.state.velocityCountUsersByAgeGroups);
        }
    };

    handleLevelChange = () => {
        this.setState({levelsChecked: this.state.levelsChecked !== true});
        if (this.state.levels.count === 0) {
            this.handleLevelSubmit(this.state.velocityCountLevel, this.state.defaultSelectedStartDate, this.state.defaultSelectedEndDate, this.state.isConfirmedLevel)
        }
    };

    handleRoleChange = () => {
        this.setState({rolesChecked: this.state.rolesChecked !== true});
        if (this.state.roles.count === 0) {
            this.handleRoleSubmit(this.state.velocityCountRoles, this.state.defaultSelectedStartDate, this.state.defaultSelectedEndDate, this.state.isConfirmedRoles)
        }
    };

    handleSportChange = () => {
        this.setState({sportsChecked: this.state.sportsChecked !== true});
        if (this.state.sports.count === 0) {
            this.handleSportSubmit(this.state.velocityCountSports, this.state.startDateSports, this.state.endDateSports, this.state.isConfirmedSports)
        }
    };

    handleSportTagChange = () => {
        this.setState({sportsTagChecked: this.state.sportsTagChecked !== true});
        if (this.state.sportTags.count === 0) {
            this.handleSportTagsSubmit(this.state.startDateSportTag, this.state.endDateSportTag, this.state.velocityCountSportTag);
        }
    };

    handleSportTagUsersChange = () => {
        this.setState({sportsTagUsersChecked: this.state.sportsTagUsersChecked !== true});
        if (this.state.sportTagUsers.count === 0) {
            this.handleSportTagUsersSubmit(this.state.startDateSportTagUsers, this.state.endDateSportTagUsers, this.state.velocityCountSportTagUsers);
        }
    };

    handleHandedChange = () => {
        this.setState({handedChecked: this.state.handedChecked !== true});
        if (this.state.handed.count === 0) {
            this.handleHandedSubmit(this.state.startDateHanded, this.state.endDateHanded, this.state.velocityCountHanded)
        }
    };

    handleGenderChange = () => {
        this.setState({genderChecked: this.state.genderChecked !== true});
        if (this.state.genders.count === 0) {
            this.handleGenderSubmit(this.state.startDateGender, this.state.endDateGender, this.state.velocityCountGender)
        }
    };

    handleDeviceChange = () => {
        this.setState({deviceChecked: this.state.deviceChecked !== true});
        if (this.state.devices.count === 0) {
            this.handleDeviceSubmit(this.state.startDateDevices, this.state.endDateDevices, this.state.velocityCountDevices)
        }
    };

    handleAppleDeviceTypeChange = () => {
        this.setState({appleDeviceTypeChecked: this.state.appleDeviceTypeChecked !== true});
        if (this.state.appleDeviceType.count === 0) {
            this.handleAppleDeviceTypeSubmit(this.state.startDateAppleDevice, this.state.endDateAppleDevice, this.state.velocityCountAppleDevice)
        }
    };

    handleNewAccountsByDOWChange = () => {
        this.setState({newAccountsByDOWChecked: this.state.newAccountsByDOWChecked !== true});
        if (!this.state.newAccountsByDOW) {
            this.handleAccountsByDOW();
        }
    };

    handleVelocitiesByDOWChange = () => {
        this.setState({velocitiesByDOWChecked: this.state.velocitiesByDOWChecked !== true});
        if (!this.state.velocitiesByDOW) {
            this.handleVelocitiesByDOW();
        }
    };

    handleVideosByDOWChange = () => {
        this.setState({videosByDOWChecked: this.state.videosByDOWChecked !== true});
        if (!this.state.videosByDOW) {
            this.handleVideosByDOW();
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <ResponsiveContainer>
                <Paper className={classes.root} >
                    <br />
                    <div id="back-to-top-anchor" style={{ width:'auto', paddingLeft:'15px'}}>
                        <h4>Date Start: The earliest date an account was created on to be considered.</h4>
                        <h4>Date End: The latest date an account was created on to be considered.</h4>
                        <h4>Number of Velocities: if you choose 0 then it will tally all users whether they have a velocity or not.</h4>
                        <h4>Confirmed: if it is checked it will only consider accounts that are confirmed. If unchecked it will count confirmed and non-confirmed. </h4>
                    </div>

                    <div align={"center"}>
                        <a href="https://analytics.itunes.apple.com" target="_blank" rel="noopener noreferrer" title="iOS Console">iOS Console</a>
                        <br />
                        <a href="https://developer.android.com/distribute/console" target="_blank" rel="noopener noreferrer" title="Android Console">Android Console</a>
                    </div>

                    <form>
                        <BootstrapButton
                            onClick={() => {
                                this.closeAll();
                            }}
                            variant="contained"
                            color="primary"
                            disableRipple
                            className={classes.submit}>
                            Close All
                        </BootstrapButton>
                    </form>

                    <br /><br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.generalTotalsChecked} onChange={this.handleGeneralTotalsChange} color="primary" />}
                            label="General Totals"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.generalTotalsChecked}>
                                <div>
                                    { (this.state.errorGeneralTotals) && (this.state.generalTotalsChecked === true) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorGeneralTotals.error}</p>
                                    </div>
                                    }
                                    { (this.state.errorGeneralTotalsSumbit) && (this.state.generalTotalsChecked === true) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was an error with the query. Please email Marguerite the message below along with the paramaters for your serach</p>
                                        <p>Function: {(this.state.errorGeneralTotalsSumbit.function).toString()}</p>
                                        <p>Error: {(this.state.errorGeneralTotalsSumbit.error).toString()}</p>
                                    </div>
                                    }
                                    { (this.state.generalTotalsChecked === true) &&
                                    <div>
                                        <div>
                                            <h4>Some of the queries won't change based on velocity count, but others will.</h4>
                                            <DateAndVelocitySearch
                                                handleSubmit={this.getGeneralTotals}
                                                selectedStartDate={this.state.startDateGeneralTotals}
                                                selectedEndDate={this.state.endDateGeneralTotals}
                                                velocityCount={this.state.velocityCountGeneralTotals}
                                            />
                                        </div>

                                        <ResponsiveContainer width="99%" height={400}>
                                            <BarChart
                                                data={this.state.generalTotals}
                                                margin={{
                                                    top: 15, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <YAxis dataKey="value" />
                                                <XAxis dataKey="shortTitle" padding={{ left: 10, right: 10 }} />
                                                <Tooltip content={<CustomTooltip />} />
                                                <Bar
                                                    dataKey="value"
                                                >
                                                <LabelList dataKey="displayValue" position="top" />
                                                    {this.state.generalTotals.map((item) => (
                                                        <Cell fill={item.color} key={item.name} />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>

                                        <SingleUserTable
                                            headerNames={["Avg. Velocitites Per User", "Number of People who Entered Their Phone Number", "Avg. Account Age (days)"]}
                                            rowIndexes={["avgerageVelocitiesPerUser", "numberOfPhoneNumbers", "averageAge"]}
                                            rows={this.state.singleDataPoints}
                                            title={"Misc. Single Data Points"}
                                        />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.velocitiesAndVideosPerPeriodChecked} onChange={this.handleVelocitiesAndVideosPerPeriodChange} color="primary" />}
                            label="Velocities and Videos Per Period"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.velocitiesAndVideosPerPeriodChecked === true}>
                                <div>
                                    { (this.state.errorVelocitiesAndVideosPerPeriod) && (this.state.velocitiesAndVideosPerPeriodChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorVelocitiesAndVideosPerPeriod.message}</p>
                                    </div>
                                    }
                                    { (this.state.velocitiesAndVideosPerPeriod.count > 0) && (this.state.velocitiesAndVideosPerPeriodChecked === true) &&
                                    <div>
                                        <div>
                                            <PeriodSearch
                                                handleSubmit={this.handleVelocitiesAndVideosSubmit}
                                                period={this.state.periodVelocitiesAndVideosPerPeriod}
                                            />
                                        </div>

                                        <ResponsiveContainer
                                            width="100%" height={400}>
                                            <BarChart
                                                data={this.state.velocitiesAndVideosPerPeriod.result}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                                <Bar dataKey="totalVelocities" fill="#8884d8" />
                                                <Bar dataKey="totalVideos" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>

                                        <h4>Cumulative</h4>
                                        <ResponsiveContainer
                                            width="100%" height={400}>
                                            <BarChart
                                                data={this.state.cumulativeVelocityAndVideoTotals}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                                <Bar dataKey="totalVelocities" fill="#8884d8" />
                                                <Bar dataKey="totalVideos" fill="#82ca9d" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    }
                                    { (this.state.velocitiesAndVideosPerPeriod.count === 0) && (this.state.velocitiesAndVideosPerPeriodChecked) && (!this.state.errorVelocitiesAndVideosPerPeriod) &&
                                    <div className={classes.barLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.newAccountsPerPeriodChecked} onChange={this.handleNewAccountsPerPeriodChange} color="primary" />}
                            label="New Accounts Created Per Period"
                        />
                        <div className={classes.barContainer}>
                            <Fade in={this.state.newAccountsPerPeriodChecked}>
                                <div>
                                    { (this.state.errorNewAccountsPerPeriod) && (this.state.newAccountsPerPeriodChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorNewAccountsPerPeriod.message}</p>
                                    </div>
                                    }
                                    { (this.state.newAccountsPerPeriod.count > 0) && (this.state.newAccountsPerPeriodChecked === true) &&
                                    <div>
                                        <div>
                                            <PeriodConfirmedSearch
                                                handleSubmit={this.handleNewAccountsPerPeriodSubmit}
                                                period={this.state.periodNewAccountsPerPeriod}
                                                isConfirmed={this.state.isConfirmedNewAccountsPerPeriod}
                                            />
                                        </div>

                                        <ResponsiveContainer
                                            width="100%" height={400}>
                                            <BarChart
                                                data={this.state.newAccountsPerPeriod.result}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                                <Bar dataKey="newUsers" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>

                                        <h4>Cumulative</h4>
                                        <ResponsiveContainer
                                            width="100%" height={400}>
                                            <BarChart
                                                data={this.state.cumulativeNewAccountTotals}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                                <Bar dataKey="newUsers" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    }
                                    { (this.state.newAccountsPerPeriod.count === 0) && (this.state.newAccountsPerPeriodChecked) && (!this.state.errorNewAccountsPerPeriod) &&
                                    <div className={classes.barLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.accountUpdatesPerPeriodChecked} onChange={this.handleAccountUpdatesPerPeriodChange} color="primary" />}
                            label="Account Updates Per Period"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.accountUpdatesPerPeriodChecked}>
                                <div>
                                    { (this.state.errorAccountUpdatesPerPeriod) && (this.state.accountUpdatesPerPeriodChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorAccountUpdatesPerPeriod.message}</p>
                                    </div>
                                    }
                                    { (this.state.accountUpdatesPerPeriod.count > 0) && (this.state.accountUpdatesPerPeriodChecked === true) &&
                                    <div>
                                        <div>
                                            <PeriodSearch
                                                handleSubmit={this.handleAccountUpdatesPerPeriodSubmit}
                                                period={this.state.periodAccountUpdatesPerPeriod}
                                            />
                                        </div>

                                        <ResponsiveContainer
                                            width="100%" height={400}>
                                            <BarChart
                                                data={this.state.accountUpdatesPerPeriod.result}
                                                margin={{
                                                    top: 5, right: 30, left: 20, bottom: 5,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                                                <ReferenceLine y={0} stroke="#000" />
                                                <Brush dataKey="date" height={30} stroke="#8884d8" />
                                                <Bar dataKey="lastAccountUpdate" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    }
                                    { (this.state.accountUpdatesPerPeriod.count === 0) && (this.state.accountUpdatesPerPeriodChecked) && (!this.state.errorAccountUpdatesPerPeriod) &&
                                    <div className={classes.barLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.usersByHSGradYearsChecked} onChange={this.handleUsersByHSGradYearsChange} color="primary" />}
                            label="High School Graduation Year"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.usersByHSGradYearsChecked}>
                                <div>
                                    { (this.state.usersByHSGradYears.count > 0) && (this.state.usersByHSGradYearsChecked === true) &&
                                    <div style={{ width: '100%', height: 300 }}>
                                        <div>
                                            <DateVelConfirmedSearch
                                                handleSubmit={this.handleUsersByHSGradYearsSubmit}
                                                selectedStartDate={this.state.startDateHSGradYears}
                                                selectedEndDate={this.state.endDateHSGradYears}
                                                isConfirmed={this.state.isConfirmedCountHSGradYears}
                                                velocityCount={this.state.velocityCountHSGradYears}
                                            />
                                        </div>

                                        <ResponsiveContainer>
                                            <AreaChart
                                                data={this.state.usersByHSGradYears.result}
                                                margin={{
                                                    top: 10, right: 30, left: 0, bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="hsGradYear" />
                                                <YAxis />
                                                <Tooltip />
                                                <Area
                                                    type="monotone"
                                                    dataKey="value"
                                                    stroke="#8884d8"
                                                    fill="#8884d8" />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                    }
                                    { (this.state.usersByHSGradYears.count === 0) && (this.state.usersByHSGradYearsChecked) && (!this.state.errorUsersByHSGradYears) &&
                                    <div className={classes.barLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    { (this.state.errorUsersByHSGradYears) && (this.state.usersByHSGradYearsChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorUsersByHSGradYears.message}</p>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.barClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.usersByAgeGroupsChecked} onChange={this.handleUsersByAgeGroupsChange} color="primary" />}
                            label="Age of Users"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.usersByAgeGroupsChecked}>
                                <div>
                                    { (this.state.errorUsersByAgeGroups) && (this.state.usersByAgeGroupsChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorUsersByAgeGroups.message}</p>
                                    </div>
                                    }
                                    { (this.state.usersByAgeGroups.count > 0) && (this.state.usersByAgeGroupsChecked === true) &&
                                    <div style={{ width: '100%', height: 300 }}>
                                        <div>
                                            <DateAndVelocitySearch
                                                handleSubmit={this.handleUsersByAgeGroupsSubmit}
                                                selectedStartDate={this.state.startDateUsersByAgeGroups}
                                                selectedEndDate={this.state.endDateUsersByAgeGroups}
                                                velocityCount={this.state.velocityCountUsersByAgeGroups}
                                            />
                                        </div>

                                        <ResponsiveContainer>
                                            <AreaChart
                                                data={this.state.usersByAgeGroups.result}
                                                margin={{
                                                    top: 10, right: 30, left: 0, bottom: 0,
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip />
                                                <Area
                                                    type="monotone"
                                                    dataKey="value"
                                                    stroke="#8884d8"
                                                    fill="#8884d8" />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                        <h4>Confirmed users self-reported age ~{ parseInt(this.state.totalAgeAccounts) / parseInt(this.state.totalAccountsByAgeSearch)} of population filled this out</h4>

                                    </div>
                                    }
                                    { (this.state.usersByAgeGroups.count === 0) && (this.state.usersByAgeGroupsChecked) && (!this.state.errorUsersByAgeGroups) &&
                                    <div className={classes.barLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.levelsChecked} onChange={this.handleLevelChange} color="primary" />}
                            label="Level"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.levelsChecked}>
                                <div>
                                    {(this.state.levelsChecked === true) &&
                                    <div>
                                        <DateVelConfirmedSearch
                                            handleSubmit={this.handleLevelSubmit}
                                            selectedStartDate={this.state.startDateLevel}
                                            selectedEndDate={this.state.endDateLevel}
                                            isConfirmed={this.state.isConfirmedLevel}
                                            velocityCount={this.state.velocityCountLevel}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorLevels) && (this.state.levelsChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorLevels.message}</p>
                                    </div>
                                    }
                                    { (this.state.levels.count === 0) && (this.state.levelsChecked) && (!this.state.errorLevels) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    { (this.state.levels.count > 0) && (this.state.levelsChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.levels.result}
                                            colors={shadesOfBlueAndGreen}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            {this.state.originalLevels.map(data => <p key={data.name}>{data.name} : {data.value}</p>)}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.rolesChecked} onChange={this.handleRoleChange} color="primary" />}
                            label="Role"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.rolesChecked}>
                                <div>
                                    {(this.state.rolesChecked === true) &&
                                    <div>
                                        <DateVelConfirmedSearch
                                            handleSubmit={this.handleRoleSubmit}
                                            selectedStartDate={this.state.startDateRoles}
                                            selectedEndDate={this.state.endDateRoles}
                                            isConfirmed={this.state.isConfirmedRoles}
                                            velocityCount ={this.state.velocityCountRoles}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorRoles) && (this.state.rolesChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorRoles.message}</p>
                                    </div>
                                    }
                                    { (this.state.roles.count > 0) && (this.state.rolesChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.roles.result}
                                            colors={theySayItsGreen}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalRoles.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                    { (this.state.roles.count === 0) && (this.state.rolesChecked) && (!this.state.errorRoles) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.sportsChecked} onChange={this.handleSportChange} color="primary" />}
                            label="Sports"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.sportsChecked}>
                                <div>
                                    {(this.state.sportsChecked === true) &&
                                    <div>
                                        <DateVelConfirmedSearch
                                            handleSubmit={this.handleSportSubmit}
                                            selectedStartDate={this.state.startDateSports}
                                            selectedEndDate={this.state.endDateSports}
                                            isConfirmed={this.state.isConfirmedSports}
                                            velocityCount={this.state.velocityCountSports}
                                        />
                                    </div>
                                    }
                                    { (this.state.sports.count > 0) && (this.state.sportsChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.sports.result}
                                            colors={sportColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalSports.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                    { (this.state.sports.count === 0) && (this.state.sportsChecked) && (!this.state.errorSports) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    { (this.state.errorSports) && (this.state.sportsChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorSports.message}</p>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.sportsTagChecked} onChange={this.handleSportTagChange} color="primary" />}
                            label="Sport Tags Associated With Velocities"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.sportsTagChecked}>
                                <div>
                                    {(this.state.sportsTagChecked === true) &&
                                    <div>
                                        <h4>Total velocities associated with a default sport tag</h4>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleSportTagsSubmit}
                                            selectedStartDate={this.state.startDateSportTag}
                                            selectedEndDate={this.state.endDateSportTag}
                                            velocityCount={this.state.velocityCountSportTag}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorSportTags) && (this.state.sportsTagChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorSportTags.message}</p>
                                    </div>
                                    }
                                    { (this.state.sportTags.count === 0) && (this.state.sportsTagChecked) && (!this.state.errorSportTags) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    {(this.state.sportTags.count > 0) && (this.state.sportsTagChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.sportTags.result}
                                            colors={sportTagColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalSportTags.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.sportsTagUsersChecked} onChange={this.handleSportTagUsersChange} color="primary" />}
                            label="Count Of Users With Sport Tags"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.sportsTagUsersChecked}>
                                <div>
                                    {(this.state.sportsTagUsersChecked === true) &&
                                    <div>
                                        <h4>Number of users who have at least one velocity with these default sport tags</h4>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleSportTagUsersSubmit}
                                            selectedStartDate={this.state.startDateSportTagUsers}
                                            selectedEndDate={this.state.endDateSportTagUsers}
                                            velocityCount={this.state.velocityCountSportTagUsers}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorSportTagUsers) && (this.state.sportsTagUsersChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorSportTagUsers.message}</p>
                                    </div>
                                    }
                                    { (this.state.sportTagUsers.count === 0) && (this.state.sportsTagUsersChecked) && (!this.state.errorSportTagUsers) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    { (this.state.sportTagUsers.count > 0) && (this.state.sportsTagUsersChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.sportTagUsers.result}
                                            colors={sportTagUserColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalSportTagUsers.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.genderChecked} onChange={this.handleGenderChange} color="primary" />}
                            label="Genders"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.genderChecked}>
                                <div>
                                    {(this.state.genderChecked === true) &&
                                    <div>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleGenderSubmit}
                                            selectedStartDate={this.state.startDateGender}
                                            selectedEndDate={this.state.endDateGender}
                                            velocityCount={this.state.velocityCountGender}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorGenders) && (this.state.genderChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorGenders.message}</p>
                                    </div>
                                    }
                                    { (this.state.genders.count > 0) && (this.state.genderChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.genders.result}
                                            colors={genderColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalGenders.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                    { (this.state.genders.count === 0) && (this.state.genderChecked === true) && (!this.state.errorGenders) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.handedChecked} onChange={this.handleHandedChange} color="primary" />}
                            label="Handed"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.handedChecked}>
                                <div>
                                    { (this.state.handedChecked === true) &&
                                    <div>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleHandedSubmit}
                                            selectedStartDate={this.state.startDateHanded}
                                            selectedEndDate={this.state.endDateHanded}
                                            velocityCount={this.state.velocityCountHanded}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorHanded) && (this.state.handedChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorHanded.message}</p>
                                    </div>
                                    }
                                    { (this.state.handed.count === 0) && (this.state.handedChecked) && (!this.state.errorHanded) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                    { (this.state.handed.count > 0) && (this.state.handedChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.handed.result}
                                            colors={handColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.originalHanded.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>


                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.deviceChecked} onChange={this.handleDeviceChange} color="primary" />}
                            label="Cellular Devices"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.deviceChecked}>
                                <div>
                                    {(this.state.deviceChecked === true) &&
                                    <div>
                                        <h4>We only get the cellular device information after they confirm the code. Right now, isConfirmed does nothing</h4>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleDeviceSubmit}
                                            selectedStartDate={this.state.startDateDevices}
                                            selectedEndDate={this.state.endDateDevices}
                                            velocityCount={this.state.velocityCountDevices}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorDevices) && (this.state.deviceChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorDevices.message}</p>
                                    </div>
                                    }
                                    { (this.state.devices.count > 0) && (this.state.deviceChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.devices.result}
                                            colors={phoneColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.devices.result.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                    { (this.state.devices.count === 0) && (this.state.deviceChecked === true) && (!this.state.errorDevices) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.appleDeviceTypeChecked} onChange={this.handleAppleDeviceTypeChange} color="primary" />}
                            label="iOS Device Type"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.appleDeviceTypeChecked}>
                                <div>
                                    {(this.state.appleDeviceTypeChecked === true) &&
                                    <div>
                                        <h4>We only get the cellular device information after they confirm the code.</h4>
                                        <DateAndVelocitySearch
                                            handleSubmit={this.handleAppleDeviceTypeSubmit}
                                            selectedStartDate={this.state.startDateAppleDevice}
                                            selectedEndDate={this.state.endDateAppleDevice}
                                            velocityCount={this.state.velocityCountAppleDevice}
                                        />
                                    </div>
                                    }
                                    { (this.state.errorAppleDeviceType) && (this.state.appleDeviceTypeChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorAppleDeviceType.message}</p>
                                    </div>
                                    }
                                    { (this.state.appleDeviceType.count > 0) && (this.state.appleDeviceTypeChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.appleDeviceType.result}
                                            colors={appleDeviceTypeColor}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            { this.state.appleDeviceType.result.map(data => <p key={data.name}>{data.name} : {data.value}</p>) }
                                        </div>
                                    </div>
                                    }
                                    { (this.state.appleDeviceType.count === 0) && (this.state.appleDeviceTypeChecked) && (!this.state.errorAppleDeviceType) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.newAccountsByDOWChecked} onChange={this.handleNewAccountsByDOWChange} color="primary" />}
                            label="New Accounts Created By Day Of The Week"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.newAccountsByDOWChecked}>
                                <div>
                                    { (this.state.errorNewAccountDOW) && (this.state.newAccountsByDOWChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorNewAccountDOW.message}</p>
                                    </div>
                                    }
                                    { (this.state.newAccountsByDOW) && (this.state.newAccountsByDOWChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.newAccountsByDOW}
                                            colors={newAccountColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            {this.state.newAccountsByDOW.map(data => <p key={data.name}>{data.name - 1} / {data.day} : {data.value}</p>)}
                                        </div>
                                    </div>
                                    }
                                    { (!this.state.newAccountsByDOW) && (this.state.newAccountsByDOWChecked) && (!this.state.errorNewAccountDOW) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.velocitiesByDOWChecked} onChange={this.handleVelocitiesByDOWChange} color="primary" />}
                            label="Velocities Created By Day Of The Week"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.velocitiesByDOWChecked}>
                                <div>
                                    { (this.state.errorVelocitiesDOW) && (this.state.velocitiesByDOWChecked) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorVelocitiesDOW.message}</p>
                                    </div>
                                    }
                                    { (this.state.velocitiesByDOW) && (this.state.velocitiesByDOWChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.velocitiesByDOW}
                                            colors={velocityColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            {this.state.velocitiesByDOW.map(data => <p key={data.name}>{data.name - 1} / {data.day} : {data.value}</p>)}
                                        </div>
                                    </div>
                                    }
                                    { (!this.state.velocitiesByDOW) && (this.state.velocitiesByDOWChecked === true) && (!this.state.errorVelocitiesDOW) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br />
                    <div className={classes.pieClosedContainer}>
                        <FormControlLabel
                            control={<Switch checked={this.state.videosByDOWChecked} onChange={this.handleVideosByDOWChange} color="primary" />}
                            label="Videos Created By Day Of The Week"
                        />
                        <div className={classes.container}>
                            <Fade in={this.state.videosByDOWChecked}>
                                <div>
                                    { (this.state.errorVideosDOW) && (this.state.videosByDOWChecked === true) &&
                                    <div className={classes.errorOutline}>
                                        <p>There was a problem — {this.state.errorVideosDOW.message}</p>
                                    </div>
                                    }
                                    { (this.state.videosByDOW) && (this.state.videosByDOWChecked === true) &&
                                    <div className={'flex-container'}>
                                        <MyPieChart
                                            data={this.state.videosByDOW}
                                            colors={videoColors}
                                        />
                                        <div style={{ width:200, margin:40}}>
                                            {this.state.videosByDOW.map(data => <p key={data.name}>{data.name - 1} / {data.day} : {data.value}</p>)}
                                        </div>
                                    </div>
                                    }
                                    { (!this.state.videosByDOW) && (this.state.videosByDOWChecked === true) && (!this.state.errorVideosDOW) &&
                                    <div className={classes.pieLoading}>
                                        <CircularDeterminate />
                                    </div>
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <br /><br /><br /><br />
                    <ScrollTop>
                        <Fab color="primary" size="small" aria-label="scroll back to top">
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </Paper>
            </ResponsiveContainer>
        )
    }
}

export default (withStyles(MetricStyles)(Metrics));
