
export function handleStartDateChange(date) {
    this.setState({
        selectedStartDate: date,
    });
}

export function handleEndDateChange(date) {
    this.setState({
        selectedEndDate: date,
    });
}

export function handlePeriodChange(event) {
    this.setState({
        period: event.target.value,
    });
}

export function changeNumberVelocities(event) {
    let newNumber = event.target.value;
    if (this.state.minVel === 0) {
        if (newNumber < 0 || newNumber === " " || newNumber === "") {
            this.setState({
                velocityCount: newNumber,
                helperText: 'Must be >= 0',
                error: true
            });
        } else {
            this.setState({
                velocityCount: newNumber,
                helperText: 'Select the # Velocities',
                error: false,
            });
        }
    } else {
        if (newNumber < 1) {
            this.setState({
                velocityCount: newNumber,
                helperText: 'Must be >= 1',
                error: true
            });
        } else {
            this.setState({
                velocityCount: newNumber,
                helperText: 'Select the # Velocities',
                error: false,
            });
        }
    }
}

export function changeConfirmedBox() {
    this.setState({
        confirmedChecked: this.state.confirmedChecked !== true,
    });
}

export function handleSportChange(event) {
    this.setState({
        sport: (event.target.value),
    });
}

export function handleRoleChange(event) {
    this.setState({
        role: (event.target.value),
    });
}

export function handleLevelChange(event) {
    this.setState({
        level: (event.target.value),
    });
}

export const periods = [
    {
        value: 'day',
        label: 'Daily',
    },
    {
        value: 'week',
        label: 'Weekly',
    },
    {
        value: 'month',
        label: 'Monthly',
    },
    {
        value: 'year',
        label: 'Yearly',
    },
];
