import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {withRouter} from "react-router-dom";
import {styles} from "./styles/TableStyles";
import TablePagination from "@material-ui/core/TablePagination";
import {TablePaginationActionsWrapped} from "./TablePaginationActions";

function Row(props) {
    const { row, classes } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow className={classes.tabbedContent}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            props.getMeasurements(row.sessionId);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.title}
                </TableCell>
                <TableCell align="right">{row.sport}</TableCell>
                <TableCell align="right">{row.activity}</TableCell>
                <TableCell align="right">{row.startDate}</TableCell>
                <TableCell align="right">{row.updatedAt}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse
                        in={open} timeout="auto"
                        unmountOnExit
                    >
                        { row.notes &&
                        <Table size="small" aria-label="session notes">
                            <TableBody>
                                <TableRow>
                                    <TableCell>Notes: {row.notes}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        }
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                component="h6"
                                className={classes.rootHeader}>
                                Measurements
                            </Typography>
                            <Table size="small" aria-label="measurements">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right">Measurement</TableCell>
                                        <TableCell align="right">Activity</TableCell>
                                        <TableCell align="right">Player</TableCell>
                                        <TableCell align="right">Device Speed Time</TableCell>
                                        <TableCell align="right">Server Updated Time</TableCell>
                                        <TableCell align="right">Radar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.measurements.length ? row.measurements.map((measurement) => (
                                        <TableRow key={measurement.measurementId}>
                                            <TableCell align="right">{measurement.measurement}</TableCell>
                                            <TableCell align="right">{measurement.context}</TableCell>
                                            <TableCell align="right">{measurement.player}</TableCell>
                                            <TableCell align="right">{measurement.deviceCreatedAt}</TableCell>
                                            <TableCell align="right">{measurement.updatedAt}</TableCell>
                                            <TableCell align="right">{measurement.radarAddress}</TableCell>
                                        </TableRow>
                                    )) :
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell component="th" scope="row">
                                                No measurements
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        sessionId: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        sport: PropTypes.string.isRequired,
        activity: PropTypes.string.isRequired,
        notes: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        measurements: PropTypes.arrayOf(
            PropTypes.shape({
                measurementId: PropTypes.string.isRequired,
                measurement: PropTypes.string.isRequired,
                playerId: PropTypes.string.isRequired,
                radarAddress: PropTypes.string.isRequired,
                deviceCreatedAt: PropTypes.string.isRequired,
                createdAt: PropTypes.string.isRequired,
                updatedAt: PropTypes.string.isRequired,
                deletedAt: PropTypes.string,
            }),
        ).isRequired,
    }).isRequired,
};

class SessionTable extends React.Component {
    static propTypes = {
        paginationDetails: PropTypes.object.isRequired,
        handleChangePage: PropTypes.func.isRequired,
        handleChangeRowsPerPage: PropTypes.func.isRequired,
    };

    render() {
        const { rows, classes, handleChangePage, handleChangeRowsPerPage, paginationDetails } = this.props;

        return (
            <div>
                <Typography
                    variant="h6"
                    component="h6"
                    className={classes.rootHeader}>
                    Sessions
                </Typography>
                <Paper className={classes.tabbedContent}>
                    <div>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>Title</TableCell>
                                    <TableCell align="right">Sport</TableCell>
                                    <TableCell align="right">Activity</TableCell>
                                    <TableCell align="right">Session Start Time</TableCell>
                                    <TableCell align="right">Server Updated Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { rows.length ?
                                    rows.map((row) => (
                                        <Row
                                            key={row.sessionId}
                                            row={row}
                                            classes={classes}
                                            getMeasurements={this.props.getMeasurements}
                                        />
                                    )) :
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell component="th" scope="row">
                                            No sessions
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow className={classes.row}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={6}
                                        count={paginationDetails.count}
                                        rowsPerPage={paginationDetails.rowsPerPage}
                                        page={paginationDetails.page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(SessionTable));
