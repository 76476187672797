import React, { Component } from 'react';
import Calendar from 'react-calendar';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '80vh',
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        width: '90%',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: 'auto',

    },
    cal: {
        marginRight: 'auto',
        marginLeft: 'auto',
    },
});


class Base extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
        }
    }

    onChange = date => this.setState({ date });

    render() {

        const { classes } = this.props;

        return (
            <Paper className={classes.root} >
                <Typography variant="h5" component="h3">
                    Hello
                    {/*{this.props.userInfo.user.firstName} {this.props.userInfo.user.lastName}*/}
                </Typography>

                <div>
                    <Calendar
                        className={classes.cal}
                        onChange={this.onChange}
                        value={this.state.date}
                    />
                </div>
            </Paper>
        )
    }
}

export default (withStyles(styles)(Base));
