import 'date-fns';
import React from 'react';
import {withStyles,} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {BootstrapButton} from "../SubmitButton";
import {changeConfirmedBox, handlePeriodChange, periods,} from "../SearchResources";
import TextField from "@material-ui/core/TextField";
import {useStyles} from "../SearchStyles";

class PeriodConfirmedSearch extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        period: PropTypes.string.isRequired,
        isConfirmed: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.changeConfirmedBox = changeConfirmedBox.bind(this);
        this.handlePeriodChange = handlePeriodChange.bind(this);

        this.state = {
            period: props.period,
            confirmedChecked: props.isConfirmed,
        };
    }

    render() {
        let { classes } = this.props;
        return (
            <main className={classes.main}>
                <form className={classes.container} noValidate autoComplete="off">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.confirmedChecked}
                                onChange={this.changeConfirmedBox}
                                name="confirmedCheckBox"
                                color="primary"
                            />
                        }
                        label="Confirmed"
                    />

                    <TextField
                        id="outlined-select-period"
                        select
                        label="Period"
                        value={this.state.period}
                        onChange={this.handlePeriodChange}
                        helperText="Please select a period"
                        variant="outlined"
                    >
                        {periods.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <BootstrapButton
                        onClick={() => {
                            this.props.handleSubmit(
                                this.state.period,
                                this.state.confirmedChecked,
                            );
                        }}
                        variant="contained"
                        color="primary"
                        disableRipple
                        className={classes.submit}>
                        Submit
                    </BootstrapButton>
                </form>
            </main>
        );
    }
}

export default withStyles(useStyles)(PeriodConfirmedSearch);
