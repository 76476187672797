export const styles = theme => ({
    root: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    rootHeader: {
        width: '95%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(4),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    row: {
        height: theme.spacing(5)
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
});
