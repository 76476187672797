import React, {Component} from 'react';
import API from './ApiInterface';
import SingleUserTable from './SingleUserTable';
import VelocityPaginationTable from "./VelocityPaginationTable";
import {SingleUserStyles} from "./styles/SingleUserStyles";
import {styled, Tab, Tabs, Typography, withStyles} from '@material-ui/core';
import {_formatDate, _formatDateToSecond} from "./common/DateResources";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {DeleteButton} from "./DeleteButton";
import Alert from '@material-ui/lab/Alert';
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import { Switch } from '@material-ui/core';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PlayerTable from "./playersTable";
import SessionTable from "./SessionsTable";
import {_getDefaultSessionsData, _getDefaultMeasurementData, _getDefaultPlayerData, _getDefaultHistoryData, _getDefaultDevicesData} from './SingleUserDefaults';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";

const defaultHeaderNames = ["Recorded Speed", "Sport", "Activity", "Video", "Device Speed Time", "Server Updated Time", "Radar"];
const deletedHeaderNames = defaultHeaderNames.slice().concat("Deleted?");
const defaultRowIndexes = ["velocity", "sport", "activity", "videoExists", "deviceCreatedAt", "updatedAt", "macAddr"];
const deletedRowIndexes = defaultRowIndexes.slice().concat("deletedAt");

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontsize: 20,
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        fontWeight: '500',
        color: '#000000',
        '&.Mui-selected': {
            color: '#3F51B5',
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant="body1"
                                component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

class SingleUser extends Component {
    constructor(props) {
        super(props);

        this.getMeasurements = this.getMeasurements.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            user: this.props.user,
            apiUser: "",
            userSettings: null,
            userProfile: null,
            userTotals: null,
            activities: null,
            phoneData: null,
            activeVelocities: null,
            searchParams: this.props.location.state.searchParams,
            userInfo: this.props.location.state.userInfo,
            searchUserId: this.props.location.state.searchUserId,
            toastSuccess: false,
            toastFailure: false,
            toastMessage: null,
            failureToastOpen: false,
            successToastOpen: false,
            showDeletedRecords: false,
            setOpen: false,
            setUserJSONDialogOpen: false,
            userTotalsError: null,
            errorUserProfile: null,
            errorActiveVelocities: null,
            errorPlayers: false,
            errorSessions: false,
            errorSessionMeasurements: false,
            errorActivities: false,
            loading: false,
            paginationDetails: {
            page: 0,
                rowsPerPage: 5,
                count: 0,
            },
            sessionPaginationDetails: {
                page: 0,
                rowsPerPage: 5,
                count: 0,
            },
            tabNumber: 0,
        };
    }

    componentDidMount () {
        this.getUserTotals();
        this.getUserProfile();
        this.getActivities();
        this.getSports();
        this.getDevices();
    }

    getUserTotals() {
        API.getUserTotals(this.state.userInfo.token, parseInt(this.props.match.params.userID)).then(userTotals => {
            let velocities = userTotals.result.totalVelocities;
            let videos = userTotals.result.totalVideos;
            let skdVel = userTotals.result.totalSDKVelocities;
            let newVel = velocities ? velocities : 0;
            let newVid = videos ? videos : 0;
            let newSDK = skdVel ? skdVel : 0;

            this.setState({
                userTotals: [{
                    totalVelocities: newVel,
                    totalVideos: newVid,
                    activeVelocities: userTotals.result.totalActiveVelocities,
                    activeVideos: userTotals.result.totalActiveVideos,
                    deletedVelocities: userTotals.result.totalDeletedVelocities,
                    deletedVideos: userTotals.result.totalDeletedVideos,
                    SDKVelocities: newSDK,
                }],
            });
        }).catch((error) => {
            this.setState({
                userTotalsError: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getUserProfile() {
        API.getUserProfile(this.state.userInfo.token, parseInt(this.props.match.params.userID)).then(user => {
            this.setState({
                apiUser: user,
                userProfile: [{
                    name: user.firstName + " " + user.lastName,
                    email: user.email,
                    isConfirmed: user.isConfirmed === true ? "True" : "False",
                    createdAt: _formatDate(user.createdAt),
                    sport: user.sport.value,
                    activity: user.activity.value,
                    code: user.code ? user.code.value : "",
                    codeExpiration: user.code ? _formatDate(user.code.expirationDate) : "",
                }],
                userSettings: [{
                    auto_edit_mode: user.settings.app.auto_edit_mode === true ? "On" : "Off",
                    dual_mode: user.settings.app.dual_mode === true ? "Visible" : "Hidden",
                    recording_mode: user.settings.app.recording_mode ? "Continuous" : "Auto Stop",
                    speed_announcement: user.settings.app.speed_announcement === true ? "On" : "Off",
                    speed_preview: user.settings.app.speed_preview === true ? "Visible" : "Hidden",
                    cellData: user.settings.app.isCellularData === true ? "Enabled" : "Disabled",
                    speedRange: user.settings.radar.speed_range.default_speed.min + "-" + user.settings.radar.speed_range.default_speed.max,
                    unitsValue: user.settings.radar.unitsValue,
                    updatedAt: _formatDate(user.updatedAt),
                }],
                phoneData: user.devices,
            });
        }).catch((error) => {
            this.setState({
                errorUserProfile: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getHistory() {
        const activities = this.state.activities;
        API.getHistoryForUser(this.state.userInfo.token, parseInt(this.props.match.params.userID)).then(result => {
            if (result.result.length > 0) {
                for (let i = 0; i < result.result.length; i++) {
                    const activity = activities.find(a => a.activityId === result.result[i].activityId);

                    result.result[i].sport = activity ? activity.sportName : '???';
                    result.result[i].activity = activity ? activity.name : '???';

                    if (result.result[i].videoPath === null || result.result[i].videoPath === "") {
                        result.result[i].videoExists = "No";
                    } else {
                        result.result[i].videoExists = "Yes";
                    }
                    result.result[i].updatedAt = _formatDate(result.result[i].updatedAt);
                    result.result[i].deviceCreatedAt = _formatDateToSecond(result.result[i].deviceCreatedAt);
                    result.result[i].deletedAt = result.result[i].deletedAt ? _formatDate(result.result[i].deletedAt) : "";
                }

                this.setState({
                    measurements: result.result,
                    errorActiveVelocities: false,
                });
            } else {
                this.setState({
                    measurements: [],
                });
            }
        }).catch((error) => {
            this.setState({
                errorActiveVelocities: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getPlayers() {
         API.getPlayersForUser(this.state.userInfo.token, this.state.searchUserId, this.state.paginationDetails.rowsPerPage, (this.state.paginationDetails.page * this.state.paginationDetails.rowsPerPage)).then(result => {
            if (result.players.length > 0) {
                const players = result.players.map(item => {
                    const player = {};
                    player.playerId = item.playerId;
                    player.name = item.firstName + " " + item.lastName;
                    player.level = item.level;
                    player.hsGradYear = item.hsGradYear;
                    player.orgName = item.orgName ? item.orgName : "Unknown";
                    player.teamName = item.teamName ? item.teamName : "Unknown";
                    player.createdAt = _formatDate(item.createdAt);
                    const filtered = this.state.sports.filter(sport => sport.sportId === item.sportId);
                    player.sport = filtered[0].name;

                    player.phoneNumber = item.phoneNumber ? item.phoneNumber : "Unknown";
                    player.state = item.state ? item.state : "Unknown";
                    player.country = item.country ? item.country : "Unknown";
                    player.zipCode = item.zipCode ? item.zipCode : "Unknown";

                    const height = item.height ? item.height.value : "Unknown";
                    const heightUnits = item.height ? item.height.units : "";
                    const weight = item.weight ? item.weight.value : "Unknown";
                    const weightUnits = item.weight ? item.weight.units : "";

                    player.additional = [{
                        positions: item.positions.length > 0 ? item.positions : ["No Data"],
                        seasons: item.seasons.length > 0 ? item.seasons : ["No Data"],
                        biometrics: "Height: " + height + heightUnits + " Weight: " + weight + weightUnits,
                        sportMetaData: item.sportMetadata ? item.sportMetadata : {unknown: "No Data"},
                        handed: item.handed ? item.handed : "Unknown",
                        gender: item.gender ? item.gender : "Unknown",
                        birthDate: item.birthDate ? _formatDate(item.birthDate) : "Unknown",
                    }]

                    return player;
                });

                this.setState({
                    players: players,
                    errorPlayers: false,
                    paginationDetails: {
                        ...this.state.paginationDetails,
                        count: result.count,
                    }
                })
            } else {
                this.setState({
                    players: [],
                });
            }
        }).catch((error) => {
            this.setState({
                errorPlayers: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getSessions() {
        API.getSessionsForUser(this.state.userInfo.token, this.state.searchUserId, this.state.sessionPaginationDetails.rowsPerPage, (this.state.sessionPaginationDetails.page * this.state.sessionPaginationDetails.rowsPerPage)).then(result => {
            if (result.sessions.length > 0) {
                const sessions = result.sessions.map(session => {
                    session.createdAt = session.createdAt ? _formatDate(session.createdAt) : "";
                    session.updatedAt = session.updatedAt ? _formatDate(session.updatedAt) : "";
                    session.startDate = session.startDate ? _formatDate(session.startDate) : "";
                    session.measurements = []

                    return session;
                });

                this.setState({
                    sessions: sessions,
                    sessionPaginationDetails: {
                        ...this.state.sessionPaginationDetails,
                        count: result.count,
                    },
                    errorSessions: false,
                })
            } else {
                this.setState({
                    sessions: [],
                    errorSessions: false,
                });
            }
        }).catch((error) => {
            this.setState({
                errorSessions: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getActivities() {
        API.getActivitiesForUser(this.state.userInfo.token, this.state.searchUserId).then(result => {
            if (result.activities.length > 0) {
                this.setState({
                    activities: result.activities,
                })
            }
        }).then(() => {
            this.getHistory();
            this.getSessions();
        }).catch((error) => {
            this.setState({
                errorActivities: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getSports() {
        API.getSportsForUser(this.state.userInfo.token, this.state.searchUserId).then(result => {
            this.setState({
                sports: result.sports,
            })
        }).then(() => {
            this.getPlayers();
        }).catch((error) => {
            this.setState({
                errorActivities: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    getMeasurements(sessionId) {
        // check to see if we need to get the measurments
        // First find the session
        const session = this.state.sessions.find(s => s.sessionId === sessionId);

        if (!session.measurements.length) {
            // If we don't have data, go get it
            const activities = this.state.activities;

            API.getMeasurementsForSession(this.state.userInfo.token, sessionId).then(result => {
                if (result.measurements.length > 0) {
                    const measurments = result.measurements.map(measurement => {
                        measurement.deviceCreatedAt = measurement.deviceCreatedAt ? _formatDateToSecond(measurement.deviceCreatedAt) : "";
                        measurement.updatedAt = measurement.updatedAt ? _formatDate(measurement.updatedAt) : "";
                        measurement.measurement = measurement.measurement.value + " " + measurement.measurement.units;

                        const activity = activities.find(a => a.activityId === measurement.activityId);

                        measurement.context = activity ? (activity.sportName + " / " + activity.name) : '???';
                        return measurement;
                    });

                    const session = this.state.sessions.find(s => s.sessionId === sessionId);
                    if (session) {
                        session.measurements = measurments.reverse();   // order by deviceCreatedAt descending
                    }

                    this.setState({
                        sessions: this.state.sessions,
                        errorSessionMeasurements: false,
                    })
                }
            }).catch((error) => {
                this.setState({
                    errorSessionMeasurements: {
                        error: true,
                        message: error.message,
                    },
                });
            });
        }
    }

    getDevices() {

        API.getDevicesForUser(this.state.userInfo.token, this.state.searchUserId, this.state.sessionPaginationDetails.rowsPerPage, (this.state.sessionPaginationDetails.page * this.state.sessionPaginationDetails.rowsPerPage)).then(result => {
            if (result.devices.length > 0) {

                const devices = result.devices.map(item => {

                    const device = JSON.parse(JSON.stringify(item));

                    device.createdAt = device.createdAt ? _formatDate(device.createdAt) : "";
                    device.updatedAt = device.updatedAt ? _formatDate(device.updatedAt) : "";
                    device.model = device.modelName ? device.modelName + " (" + device.model + ")" : device.model
                    device.osAndVersion = device.os + " - " + device.osVersion;

                    return device;
                });

                this.setState({
                    devices: devices,
                })
            } else {
                this.setState({
                    devices: [_getDefaultDevicesData()]
                });
            }
        }).catch((error) => {
            this.setState({
                errorDevices: {
                    error: true,
                    message: error.message,
                },
            });
        });
    }

    softDeleteUser() {
        console.log(this.state);
        API.softDelete(this.state.userInfo.token, this.state.userProfile[0].email, this.props.match.params.userID).then(result => {
            if (result.error === false) {
                this.setState({
                    toastSuccess: true,
                    toastMessage: result.message,
                });
                this.getUserProfile();
            } else {
                this.setState({
                    toastFailure: true,
                    toastMessage: result.message,
                });
            }
        }).catch((error) => {
            this.setState({
                toastFailure: true,
                toastMessage: error.message,
            });
        });
    }

    handleBackClick() {
        this.props.history.push({
            pathname: '/users',
            state: {
                searchParams: this.state.searchParams,
                userInfo: this.state.userInfo,
            }
        })
    };

    handleChangePage = (event, page) => {
        this.setState({
            paginationDetails: {
                loading: true,
                ...this.state.paginationDetails,
                page: page,
                skip: page * this.state.paginationDetails.rowsPerPage,
            }
        }, () => this.getPlayers())
    };

    handleChangeRowsPerPage = event => {
        this.setState({
            paginationDetails: {
                skip: 0,
                rowsPerPage: Number(event.target.value),
                page: 0
            }
        }, () => this.getPlayers())
    };

    filterDeletedMeasurements() {
        return this.state.measurements.filter(measurement => {
            return measurement.deletedAt === "";
        });
    }

    handleSessionsChangePage = (event, page) => {
        this.setState({
            sessionPaginationDetails: {
                loading: true,
                ...this.state.sessionPaginationDetails,
                page: page,
                skip: page * this.state.sessionPaginationDetails.rowsPerPage,
            }
        }, () => this.getSessions())
    };

    handleSessionsChangeRowsPerPage = event => {
        this.setState({
            sessionPaginationDetails: {
                skip: 0,
                rowsPerPage: Number(event.target.value),
                page: 0
            }
        }, () => this.getSessions())
    };

    handleDeletedSwitchChange = () => {
        this.setState({showDeletedRecords: this.state.showDeletedRecords !== true});
    };

    handleClickOpen = () => {
        console.log("Box opening: " + this.state);
        this.setState({ setUserJSONDialogOpen: true });
    };

    handleClose = () => {
        this.setState({ setUserJSONDialogOpen: false });
    };

    handleChange = (event, newTabNumber) => {
        this.setState({ tabNumber: newTabNumber});
    };

    render() {
        let { classes } = this.props;

        return (
            <div className={classes.root}>
                { (this.state.toastSuccess === true) &&
                    <Alert className={classes.root}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setState({
                                        successToastOpen: false,
                                        toastSuccess: false,
                                    });
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity="success">{this.state.toastMessage}</Alert>
                }
                <br/>
                { (this.state.toastFailure === true) &&
                    <Alert className={classes.root}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    this.setState({
                                        failureToastOpen: false,
                                        toastFailure: false,
                                    });
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        severity="error">{this.state.toastMessage}
                    </Alert>
                }

                <Grid container spacing={2} style={{width:'95%', marginLeft: 'auto',
                    marginRight: 'auto'}}>
                    <Grid item xs={12} sm={6}>
                        <IconButton color="inherit" onClick={this.handleBackClick}>
                            <ArrowBackIosIcon/>
                            Back
                        </IconButton>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{textAlign:"right", paddingRight: "10px"}}>
                        <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
                            User JSON
                        </Button>
                        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.setUserJSONDialogOpen}>
                            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                                Full User JSON
                            </DialogTitle>
                                <Typography gutterBottom>
                                    <div><pre>{JSON.stringify(this.state.apiUser, null, 2) }</pre></div>
                                    <div><pre>{JSON.stringify(this.state.userTotals, null, 2) }</pre></div>
                                    <div><pre>{JSON.stringify(this.state.measurements, null, 2) }</pre></div>
                                </Typography>
                            <DialogActions>
                                <Button autoFocus onClick={this.handleClose} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <DeleteButton
                            onClick={() => {
                                this.softDeleteUser();
                            }}
                            variant="contained"
                            color="primary"
                            startIcon={<DeleteIcon />}
                        >
                            Soft Delete
                        </DeleteButton>
                    </Grid>
                </Grid>

                 {/*PROFILE*/}
                { (this.state.errorUserProfile) &&
                    <div className={classes.errorOutline}>
                        <p>There was an error fetching profile. {this.state.errorUserProfile.message}</p>
                    </div>
                }
                { (this.state.userProfile) && (this.state.userSettings) && (this.state.phoneData) &&
                    <div>
                        <SingleUserTable
                            headerNames={["Name", "Email", "Confirmed", "Code", "Code Expiration", "Sport", "Activity", ]}
                            rowIndexes={["name", "email", "isConfirmed", "code", "codeExpiration", "sport", "activity",]}
                            rows={this.state.userProfile}
                            title={"User Information -- Account Created: " + this.state.userProfile[0].createdAt + " -- Last Updated:" + this.state.userSettings[0].updatedAt}
                        />

                        <SingleUserTable
                            headerNames={["Recording Mode", "Speed Ann.", "Auto Edit Mode", "Speed Range",
                            "Speed Preview", "Dual Mode", "Cell Data", "Units"]}
                            rowIndexes={["recording_mode", "speed_announcement", "auto_edit_mode", "speedRange",
                            "speed_preview", "dual_mode", "cellData", "unitsValue"]}
                            rows={this.state.userSettings}
                            title={"Settings"}
                        />
                    </div>
                }
                { (!this.state.userProfile) && (!this.state.userSettings) && (!this.state.phoneData) && (!this.state.errorUserProfile) &&
                    <div>
                        <p>* Loading Profile *</p>
                    </div>
                }

                {/* Devices */}
                { (this.state.errorDevices) &&
                <div className={classes.errorOutline}>
                    <p>There was an error fetching devices: {this.state.errorDevices.message}</p>
                </div>
                }
                {(this.state.devices) &&
                <div>
                    <SingleUserTable
                        headerNames={["Model", "OS & Version", "App Version", "Created At", "Updated At"]}
                        rowIndexes={["model", "osAndVersion", "appVersion", "createdAt", "updatedAt"]}
                        rows={this.state.devices}
                        title={"Devices"}
                    />
                </div>
                }

                {/* TOTALS */}
                { (this.state.userTotalsError) &&
                    <div className={classes.errorOutline}>
                        <p>There was an error fetching user totals: {this.state.userTotalsError.message}</p>
                    </div>
                }
                {(this.state.userTotals) &&
                    <SingleUserTable
                        headerNames={["Active Velocities", "Active Videos", "Deleted Velocities",
                            "Deleted Videos", "Total Velocities", "Total Videos", "SDK Velocities"]}
                        rowIndexes={["activeVelocities", "activeVideos", "deletedVelocities", "deletedVideos",
                            "totalVelocities", "totalVideos", "SDKVelocities"]}
                        rows={this.state.userTotals}
                        title={"Totals"}
                    />
                }
                { (!this.state.userTotals) && (!this.state.userTotalsError) &&
                    <div>
                        <p>* Loading Totals *</p>
                    </div>
                }

                <Paper className={classes.tabbedArea} >
                    <Box sx={{ width: '100%' }} className={classes.root}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={this.state.tabNumber}
                                onChange={this.handleChange}
                                aria-label="tab"
                            >
                                <StyledTab
                                    label="Sports App"
                                    {...a11yProps(0)}
                                />
                                <StyledTab
                                    label="Legacy"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>

                        {/* SPORTS APP */}
                        <TabPanel value={this.state.tabNumber} index={0}>
                            <div>

                                {/* Sessions */}
                                { (this.state.errorSessions) &&
                                <div className={classes.errorOutline}>
                                    <p>There was an error fetching sessions: {this.state.errorSessions.message}</p>
                                </div>
                                }
                                { (this.state.errorSessionMeasurements) &&
                                <div className={classes.errorOutline}>
                                    <p>There was an error fetching session measurements: {this.state.errorSessionMeasurements.message}</p>
                                </div>
                                }
                                {(this.state.sessions) &&
                                <SessionTable
                                    rows={this.state.sessions}
                                    paginationDetails={this.state.sessionPaginationDetails}
                                    handleChangePage={this.handleSessionsChangePage}
                                    handleChangeRowsPerPage={this.handleSessionsChangeRowsPerPage}
                                    getMeasurements={this.getMeasurements}
                                />
                                }
                                { (!this.state.sessions) && (!this.state.errorSessions) && (!this.state.errorSessionMeasurements) && <div>
                                    <p>* Loading Sessions *</p>
                                </div>
                                }

                                {/*PLAYERS */}
                                { (this.state.errorPlayers) &&
                                <div className={classes.errorOutline}>
                                    <p>There was an error fetching players: {this.state.errorPlayers.message}</p>
                                </div>
                                }
                                {(this.state.players) &&
                                <PlayerTable
                                    rows={this.state.players}
                                    paginationDetails={this.state.paginationDetails}
                                    handleChangePage={this.handleChangePage}
                                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                                }
                                { (!this.state.players) && (!this.state.errorPlayers) && <div>
                                    <p>* Loading Players *</p>
                                </div>
                                }
                            </div>
                        </TabPanel>

                        {/* LEGACY APP */}
                        <TabPanel value={this.state.tabNumber} index={1}>
                            <div>

                                {/* HISTORY */}
                                { (this.state.errorActiveVelocities) &&
                                <div className={classes.errorOutline}>
                                    <p>There was an error fetching history: {this.state.errorActiveVelocities.message}</p>
                                </div>
                                }
                                {(this.state.measurements) &&
                                <div>
                                    <VelocityPaginationTable
                                        headerNames={this.state.showDeletedRecords ? deletedHeaderNames : defaultHeaderNames}
                                        rowIndexes={this.state.showDeletedRecords ? deletedRowIndexes : defaultRowIndexes}
                                        rows={this.state.showDeletedRecords ? this.state.measurements : this.filterDeletedMeasurements()}
                                        title={"Measurements"}
                                    />
                                    <div style={{ float: 'right'}} >
                                        <FormControlLabel
                                            control={<Switch checked={this.state.showDeletedRecords} onChange={this.handleDeletedSwitchChange} style={{display: 'flex-end'}} color="primary" />}
                                            label="Show Deleted Velocities?"
                                        />
                                    </div>
                                </div>
                                }
                                { (!this.state.measurements) && (!this.state.errorActiveVelocities) &&
                                <div>
                                    <p>* Loading Measurements *</p>
                                </div>
                                }
                            </div>
                        </TabPanel>
                    </Box>
                    <br /><br />
                </Paper>

                <br /><br /><br />
            </div>
        );
    }
}

export default withStyles(SingleUserStyles)(SingleUser);
