/*
* CSS for sign in, sign up, enter email, enter code snippets
*/

export const AdminRoleCardStyles = theme => ({
    root: {
        minWidth: 500,
        maxWidth: 500,
        backgroundColor: "#F5F5F5",
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    gridWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeaat(3, 1fr)',
        gridAutoRows: 'minmax(100px, auto)',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
    },
    addButton: {
        marginRight: '5px'
    },
});
