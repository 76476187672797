import React from 'react';
import Button from '@material-ui/core/Button';
import * as PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core";

const styles = () => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    newRoleText: {
        width: '100%',
        paddingBottom: '10px',
    }
});

class NewRoleDialog extends React.Component {

    static propTypes = {
        handleCreateNewRoleClick: PropTypes.func.isRequired,
        handleCreateNewRoleClickClose: PropTypes.func.isRequired,
        handleRoleDescriptionChange: PropTypes.func.isRequired,
        handleRoleNameChange: PropTypes.func.isRequired,
        newRoleDescription: PropTypes.string.isRequired,
        newRoleName: PropTypes.string.isRequired,
        open: PropTypes.bool.isRequired,
    };

    render() {
        const {
            classes,
            handleCreateNewRoleClick,
            handleCreateNewRoleClickClose,
            handleRoleDescriptionChange,
            handleRoleNameChange,
        } = this.props;

        return (
            <Dialog open={this.props.open} onClose={this.handleCreateNewRoleClickClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Create New Role</DialogTitle>
                <DialogContent>
                    <form className={classes.container}>

                        <TextField
                            id="name"
                            label="Name"
                            value={this.props.newRoleName}
                            onChange={handleRoleNameChange}
                            variant="filled"
                            className={classes.newRoleText}
                        />

                        <TextField
                            id="description"
                            label="Description"
                            value={this.props.newRoleDescription}
                            onChange={handleRoleDescriptionChange}
                            variant="filled"
                            className={classes.newRoleText}
                        />

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateNewRoleClickClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => handleCreateNewRoleClick()}
                        color="primary"
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default (withStyles(styles)(NewRoleDialog));
