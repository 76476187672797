import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import {AdminRoleCardStyles} from "./styles/AdminRoleCardStyles";
import * as PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import clsx from 'clsx';
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class OutlinedCard extends React.Component {

    static propTypes = {
        handleRemoveUserFromRoleClick: PropTypes.func.isRequired,
        handleDeleteRoleClick: PropTypes.func.isRequired,
        handleRemovePermissionFromRoleClick: PropTypes.func.isRequired,
        handleAddPermissionToRoleClick: PropTypes.func.isRequired,
        handleAddUserToRoleClick: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        users: PropTypes.array.isRequired,
        permissions: PropTypes.array.isRequired,
        employees: PropTypes.array.isRequired,
        allPermissions: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleAddUserChange = this.handleAddUserChange.bind(this);
        this.handleAddPermissionChange = this.handleAddPermissionChange.bind(this);

        this.state = {
            expanded: false,
            userToAdd: "",
            addUserDialogOpen: false,
            permissionIdToAdd: "",
        };
    }

    handleExpandClick = () => {
        this.setState({
            expanded: this.state.expanded !== true
        });
    };

    handleAddUserDialogClickOpen = () => {
        this.setState({
            addUserDialogOpen: true,
        });
    };

    handleAddUserDialogClose = () => {
        this.setState({
            addUserDialogOpen: false,
            userToAdd: "",
        });
    };

    handleAddUserChange = (event) => {
        this.setState({
            userToAdd: event.target.value
        });
    };

    handleAddPermissionDialogClickOpen = () => {
        this.setState({
            addPermissionDialogOpen: true,
        });
    };

    handleAddPermissionDialogClose = () => {
        this.setState({
            addPermissionDialogOpen: false,
            permissionIdToAdd: "",
        });
    };

    handleAddPermissionChange = (event) => {
        this.setState({
            permissionIdToAdd: event.target.value
        });
    };

    render() {
        const {
            classes,
            handleRemoveUserFromRoleClick,
            handleDeleteRoleClick,
            handleRemovePermissionFromRoleClick,
            handleAddUserToRoleClick,
            handleAddPermissionToRoleClick,
        } = this.props;

        return (
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {this.props.title}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p">
                        {this.props.description}
                    </Typography>
                </CardContent>
                <CardActions className="card-action right-align">

                    <IconButton
                        onClick={() => handleDeleteRoleClick(this.props.roleId)}
                        aria-label="delete"
                    >
                        <DeleteIcon />
                    </IconButton>

                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>

                        <Grid container spacing={2}>
                            <Grid item sm={3}>
                                <Typography paragraph>Users:</Typography>
                            </Grid>
                            <Grid item xm={3}>
                                <Button
                                    size="large"
                                    className={classes.button}
                                    onClick={this.handleAddUserDialogClickOpen}
                                >
                                    <AddCircleIcon/>
                                </Button>
                            </Grid>
                        </Grid>

                        <Typography paragraph>
                            <Table className={classes.table}>
                                <TableBody>
                                        {
                                            this.props.users.map((user) => (
                                                <TableRow className={classes.row} key={"removeUser " + user.id}>
                                                    <TableCell>
                                                        {user.firstName} {user.lastName}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size="large"
                                                            className={classes.button}
                                                            onClick={() => handleRemoveUserFromRoleClick(this.props.roleId, user.id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                </TableBody>
                            </Table>
                        </Typography>


                        <Grid container spacing={2}>
                            <Grid item sm={3}>
                                <Typography paragraph>Permissions:</Typography>
                            </Grid>
                            <Grid item xm={3}>
                                <Button
                                    size="large"
                                    className={classes.button}
                                    onClick={this.handleAddPermissionDialogClickOpen}
                                >
                                    <AddCircleIcon/>
                                </Button>
                            </Grid>
                        </Grid>

                        <Typography paragraph>
                            <Table className={classes.table}>
                                <TableBody>
                                    {
                                        this.props.permissions.map((permission) => (
                                            <TableRow className={classes.row} key={"removePermission" + permission}>
                                                <TableCell>
                                                    {permission}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        size="large"
                                                        className={classes.button}
                                                        onClick={() => handleRemovePermissionFromRoleClick(this.props.roleId, permission)}
                                                    >
                                                        <DeleteIcon/>
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Typography>
                    </CardContent>
                </Collapse>


                <Dialog open={this.state.addUserDialogOpen} onClose={this.handleAddUserDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add User</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-dialog-select-label">Employees</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={this.state.userToAdd}
                                    onChange={this.handleAddUserChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    {
                                        this.props.employees.map((user) =>
                                            <MenuItem key={"addUser" + user.id} value={user.id}>
                                                {user.firstName} {user.lastName} - {user.email}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAddUserDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleAddUserToRoleClick(this.props.roleId, this.state.userToAdd)}
                            color="primary"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog open={this.state.addPermissionDialogOpen} onClose={this.handleAddPermissionDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Add Permission</DialogTitle>
                    <DialogContent>
                        <form className={classes.container}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-dialog-select-label">Permissions</InputLabel>
                                <Select
                                    labelId="demo-dialog-select-label"
                                    id="demo-dialog-select"
                                    value={this.state.permissionIdToAdd}
                                    onChange={this.handleAddPermissionChange}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>

                                    {
                                        this.props.allPermissions.map((permission) =>
                                            <MenuItem key={"addPermission " + permission._id} value={permission._id}>
                                                {permission.name} - {permission.description}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAddPermissionDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleAddPermissionToRoleClick(this.props.roleId, this.state.permissionIdToAdd)}
                            color="primary"
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

            </Card>
        );
    }
}

export default withStyles(AdminRoleCardStyles)(OutlinedCard);
