import React from 'react';
import * as PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableHead from "@material-ui/core/TableHead";
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router-dom';
import {styles, TablePaginationActionsWrapped} from './TablePaginationActions'

class UserPaginationTable extends React.Component {

    static propTypes = {
        searchParams: PropTypes.object.isRequired,
        paginationDetails: PropTypes.object.isRequired,
        handleChangePage: PropTypes.func.isRequired,
        handleChangeRowsPerPage: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            userToView: null,
            paginationDetails: this.props.paginationDetails,
        };
    }

    handleViewUserClick = user => {
        this.props.history.push({
            pathname: '/user/' + user.legacyUserId.toString(),
            state: {
                searchParams: this.props.searchParams,
                userInfo: this.props.userInfo,
                searchUserId: user.userId,
            },
        })
    };

    render() {
        const { rows, classes, headerNames, rowIndexes, handleChangePage, handleChangeRowsPerPage } = this.props;
        const rowsPerPage = this.state.paginationDetails.rowsPerPage;
        const page = this.state.paginationDetails.page;

        return (
            <Paper className={classes.root}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.row}>
                                {headerNames.map((value, idx) => (
                                    idx === 0 ?
                                        <TableCell key={idx}>{value}</TableCell> :
                                        <TableCell key={idx}>{value}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow className={classes.row} key={row.legacyUserId}>
                                    {rowIndexes.map((v, idx2) => (
                                        idx2 === 0 ?
                                            <TableCell key={idx2} component="th" scope="row">{row[v]}</TableCell> :
                                            <TableCell key={idx2}>{row[v].toString()}</TableCell>
                                    ))}
                                    <TableCell>
                                        <Button
                                            size="large"
                                            className={classes.button}
                                            onClick={() => this.handleViewUserClick(row)}>
                                            +
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow className={classes.row}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={3}
                                    count={this.state.paginationDetails.count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActionsWrapped}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        );
    }
}

UserPaginationTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UserPaginationTable));
