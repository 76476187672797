import { createTheme } from '@material-ui/core/styles';

const Theme = createTheme({
    palette: {
        primary: {
            main: '#1c7fac',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

export default Theme;