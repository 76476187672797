import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from "@material-ui/core/styles/withStyles";
import {AdminRoleCardStyles} from "./styles/AdminRoleCardStyles";
import * as PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import clsx from 'clsx';
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableHead from "@material-ui/core/TableHead";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

class PermissionsCard extends React.Component {

    static propTypes = {
        handleDeletePermissionClick: PropTypes.func.isRequired,
        handleCreatePermissionClick: PropTypes.func.isRequired,
        permissions: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.changeName = this.changeName.bind(this);
        this.changeDescription = this.changeDescription.bind(this);

        this.state = {
            expanded: false,
            newPermissionDialogOpen: false,
            nameError: false,
            nameHelperText: "",
            permissionName: "",
            permissionDescription: "",
            isCreateDisabled: true,
        };
    }

    handleExpandClick = () => {
        this.setState({
            expanded: this.state.expanded !== true
        });
    };

    handleNewPermissionDialogClickOpen = () => {
        this.setState({
            newPermissionDialogOpen: true,
        });
    };

    handleNewPermissionDialogClose = () => {
        this.setState({
            newPermissionDialogOpen: false,
            permissionName: "",
            permissionDescription: "",
        });
    };

    changeName(event) {
        if (event.target.value.length > 10) {
            this.setState({
                permissionName: event.target.value,
                nameHelperText: "Name must be 10 or fewer characters",
                nameError: true,
                isCreateDisabled: true,
            });
        } else {
            this.setState({
                permissionName: event.target.value,
                nameHelperText: "",
                nameError: false,
                isCreateDisabled: false,
            });
        }
    }

    changeDescription(event) {
        this.setState({permissionDescription: event.target.value});
    }

    render() {
        const {
            classes,
            handleCreatePermissionClick,
            handleDeletePermissionClick,
        } = this.props;

        return (
            <div>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Permissions
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            {this.state.description}
                        </Typography>
                    </CardContent>
                    <CardActions className="card-action right-align">

                        <IconButton
                            onClick={this.handleNewPermissionDialogClickOpen}
                            aria-label="delete"
                        >
                            <AddCircleIcon />
                        </IconButton>

                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>

                    </CardActions>

                    <Dialog
                        open={this.state.newPermissionDialogOpen}
                        onClose={this.handleNewPermissionDialogClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">Create New Permission</DialogTitle>
                        <DialogContent>
                            <TextField
                                value={this.state.permissionName}
                                onChange={this.changeName}
                                autoFocus
                                error={this.state.nameError}
                                margin="dense"
                                id="name"
                                label="Permission Name"
                                helperText={this.state.nameHelperText}
                                type="string"
                                fullWidth
                            />
                            <TextField
                                value={this.state.permissionDescription}
                                onChange={this.changeDescription}
                                margin="dense"
                                id="description"
                                label="Permission Description"
                                type="string"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNewPermissionDialogClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleCreatePermissionClick(this.state.permissionName, this.state.permissionDescription)}
                                color="primary"
                                disabled={this.state.isCreateDisabled || this.state.permissionDescription === "" || this.state.permissionName === ""}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        <CardContent>

                            <Typography paragraph>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Permission Name
                                            </TableCell>
                                            <TableCell>
                                                Created
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            this.props.permissions.map((permission) => (
                                                <TableRow className={classes.row} key={"permissionId" + permission._id}>
                                                    <TableCell>
                                                        {permission.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {permission.description}
                                                    </TableCell>
                                                    <TableCell>
                                                        {permission.addedAt}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size="large"
                                                            className={classes.button}
                                                            onClick={() => handleDeletePermissionClick(permission._id)}
                                                        >
                                                            <DeleteIcon/>
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Typography>
                        </CardContent>
                    </Collapse>
                </Card>

            </div>
        );
    }
}

export default withStyles(AdminRoleCardStyles)(PermissionsCard);
