import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from "@material-ui/core/TableHead";
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { styles, TablePaginationActionsWrapped } from './TablePaginationActions';
import Theme from "./styles/UpdateEmailTheme";
import { ThemeProvider } from '@material-ui/core/styles';


class UpdateEmailTable extends React.Component {
    static propTypes = {
        searchParams: PropTypes.object.isRequired,
        paginationDetails: PropTypes.object.isRequired,
        handleChangePage: PropTypes.func.isRequired,
        handleChangeRowsPerPage: PropTypes.func.isRequired,
        rows: PropTypes.array.isRequired,
        classes: PropTypes.object.isRequired,
        headerNames: PropTypes.array.isRequired,
        rowIndexes: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            paginationDetails: this.props.paginationDetails,
        };
    }

    handleUpdateEmailButtonClick = user => {
        this.props.history.push({
            pathname: '/update-email/'+ user.legacyUserId.toString(),
            state: { user, userInfo: this.props.userInfo }
        });
    };

    render() {
        const { rows, classes, headerNames, rowIndexes, handleChangePage, handleChangeRowsPerPage } = this.props;
        const { paginationDetails } = this.state;
        const rowsPerPage = paginationDetails.rowsPerPage;
        const page = paginationDetails.page;

        return (
            <ThemeProvider theme={Theme}>
                <Paper className={classes.root}>
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow className={classes.row}>
                                    {headerNames.map(header => (
                                        <TableCell key={header}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.legacyUserId} className={classes.row}>
                                        {rowIndexes.map(column => (
                                            <TableCell key={`${row.legacyUserId}-${column}`}>
                                                {row[column].toString()}
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="secondary"
                                                onClick={() => this.handleUpdateEmailButtonClick(row)}
                                            >
                                                Change Email
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow className={classes.row}>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        colSpan={3}
                                        count={paginationDetails.count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{ native: true }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActionsWrapped}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </div>
                </Paper>
            </ThemeProvider>
        );
    }
}

export default withRouter(withStyles(styles)(UpdateEmailTable));
