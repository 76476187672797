import 'date-fns';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {withStyles} from '@material-ui/core';
import * as PropTypes from 'prop-types';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {BootstrapButton} from "../SubmitButton";
import {
    changeConfirmedBox,
    changeNumberVelocities,
    handleEndDateChange,
    handleStartDateChange,
} from "../SearchResources";
import {useStyles} from "../SearchStyles";

class DateVelConfirmedSearch extends React.Component {

    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        selectedStartDate: PropTypes.string.isRequired,
        selectedEndDate: PropTypes.string.isRequired,
        isConfirmed: PropTypes.bool.isRequired,
        velocityCount: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.changeNumberVelocities = changeNumberVelocities.bind(this);
        this.handleStartDateChange = handleStartDateChange.bind(this);
        this.handleEndDateChange = handleEndDateChange.bind(this);
        this.changeConfirmedBox = changeConfirmedBox.bind(this);

        this.state = {
            selectedStartDate: props.selectedStartDate,
            selectedEndDate: props.selectedEndDate,
            confirmedChecked: props.isConfirmed,
            velocityCount: props.velocityCount,
            minVel: props.velocityCount,
        };
    }

    render() {
        let { classes } = this.props;
        return (
            <main className={classes.main}>
                <form className={classes.container} noValidate autoComplete="off">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="start-date-picker-dialog"
                            label="Select A Start Date"
                            format="MM/dd/yyyy"
                            helperText="mm/dd/yyyy"
                            value={this.state.selectedStartDate}
                            onChange={this.handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="end-date-picker-dialog"
                            label="Select An End Date"
                            format="MM/dd/yyyy"
                            helperText="mm/dd/yyyy"
                            value={this.state.selectedEndDate}
                            onChange={this.handleEndDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.confirmedChecked}
                                onChange={this.changeConfirmedBox}
                                name="confirmedCheckBox"
                                color="primary"
                            />
                        }
                        label="Confirmed"
                    />

                    <TextField
                        id="velocities"
                        className={classes.textField}
                        label="Select the # Velocities"
                        type="number"
                        variant="outlined"
                        helperText={this.state.helperText}
                        error={this.state.error}
                        value={this.state.velocityCount}
                        onChange={this.changeNumberVelocities}
                    />

                    <BootstrapButton
                        onClick={() => {
                            this.props.handleSubmit(
                                parseInt(this.state.velocityCount),
                                this.state.selectedStartDate,
                                this.state.selectedEndDate,
                                this.state.confirmedChecked,
                            );
                        }}
                        variant="contained"
                        color="primary"
                        disableRipple
                        className={classes.submit}>
                        Submit
                    </BootstrapButton>
                </form>
            </main>
        );
    }
}

export default withStyles(useStyles)(DateVelConfirmedSearch);
